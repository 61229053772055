import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import IntlMessages from "../../../utils/IntlMessages";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import ContentLoader from "../../ContentLoader";
import { alpha, makeStyles } from "@material-ui/core/styles";
import CmtImage from "../../../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import { NavLink, useHistory } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import { useAuth } from "../../../../context/AuthContext";
import AlertActions from "../../../../redux/sagas/actions/AlertActions";
import { useIntl } from "react-intl";
import { APPS_URL } from "../../../../consts/UrlContants";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  }
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = "default", wrapperVariant = "default" }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const { signIn } = useAuth();
  const hist = useHistory();
  const intl = useIntl();

  const onSubmit = () => {
    setLoading(true);
    signIn(email, password).then(r => {
      window.location.href = APPS_URL;
      // hist.push('/apps');
    }).catch(err => {
      AlertActions.showDialogWarning(intl.formatMessage({ id: "Login Error" }), err.message || err);
    }).finally(() => {
      setLoading(false);
    });
    // dispatch(AuhMethods[method].onLogin({ email, password }));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CmtImage src={"/images/auth/login-img.png"} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={"/images/logo.png"} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="Login" />
        </Typography>
        <form>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            {/*<FormControlLabel*/}
            {/*  className={classes.formcontrolLabelRoot}*/}
            {/*  control={<Checkbox name="checkedA" />}*/}
            {/*  label="Remember me"*/}
            {/*/>*/}
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/forgot-password">
                <IntlMessages id="appModule.forgotPassword" />
              </NavLink>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary" disabled={loading}>
              <IntlMessages id="appModule.signIn" />
            </Button>

            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/signup">
                <IntlMessages id="Register" />
              </NavLink>
            </Box>
          </Box>
        </form>

        {/*{dispatch(AuhMethods[method].getSocialMediaIcons())}*/}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;

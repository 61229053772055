import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { Grid, TextField } from "@material-ui/core";
import FieldContainer from "../form-component/container/FieldContainer";

const FormikTextField = (props) => {
  const pr = props.formik;
  return(
    <FieldContainer {...props} >
      <Field
        {...props}
        component={TextField}
        variant={"outlined"}
        fullWidth
        size="small"
        onChange={pr.handleChange(props.fieldName)}
        error={Boolean(pr.errors[props.fieldName])}
        value={pr.values && pr.values[props.fieldName]}
        // pr.touched[props.fieldName] &&
        helperText={pr.errors[props.fieldName]}
      />
    </FieldContainer>
  )

}

FormikTextField.defaultProps ={
  isSetName: true,
}
FormikTextField.propTypes ={
  fieldName: PropTypes.string,
  formik: PropTypes.any,
  gridSize: PropTypes.number,
}

export default FormikTextField;

import React, { useState } from "react";
import * as Yup from "yup";
import { CHECKBOX, TEXT, TEXT_DISABLED } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";
import FormikFileUploadField from "../../../components/formik/FormikFileUploadField";

const FormValidation = Yup.object().shape({
  fileName: Yup.string().required("Required"),
  fileNameOriginal: Yup.string().required("Required"),
  sizeBytes: Yup.number().required("Required"),
  slug: Yup.string().required("Required"),
});

const formObj = {
  name:'notification-form',
  validation: FormValidation,
  groups: [
    {
      title: 'Main',
      subTitle: '',
      fields: [
        { name: 'fileName', label: 'Name', type: TEXT, fieldProps: {} },
        { name: 'fileNameOriginal', label: 'Original Name', type: TEXT, fieldProps: {} },
        { name: 'sizeBytes', label: 'Size Bytes', type: TEXT, fieldProps: {} },
        { name: 'oauthUserId', label: 'OauthId', type: TEXT, fieldProps: {} },
        { name: 'uploadedBy', label: 'Uploaded By', type: TEXT, fieldProps: {} },
        { name: 'uploadType', label: 'Upload Type', type: TEXT, fieldProps: {} },
        { name: 'mimeType', label: 'Mime Type', type: TEXT, fieldProps: {} },
        { name: 'eTag', label: 'E-Tag', type: TEXT, fieldProps: {} },
        { name: 'bucketName', label: 'Bucket Name', type: TEXT, fieldProps: {} },
        { name: 'downloadCount', label: 'Download Count', type: TEXT, fieldProps: {} },
        { name: 'createdBy', label: 'Created By', type: TEXT, fieldProps: {} },
        { name: 'modifiedBy', label: 'Modified By', type: TEXT, fieldProps: {} },
        { name: 'slug', label: 'Slug', type: TEXT, fieldProps: {} },
        { name: 'tenantContext', label: 'Tenant Context', type: TEXT, fieldProps: {} },
        // { name: 'tenantContext', label: 'Tenant Context', type: COMPONENT, fieldProps: { component: TenantAutocomplete } },
        { name: 'tenantId', label: 'Tenant Id', type: TEXT, fieldProps: {}},
        { name: 'inactive', label: 'Inactive', type: CHECKBOX, fieldProps:{}},
        { name: 'public', label: 'Public', type: CHECKBOX, fieldProps: {} },
        { name: 'createdDate', label: 'Created', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'modifiedDate', label: 'Modified', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'deletedDate', label: 'Deleted', type: TEXT_DISABLED, fieldProps: {} }
      ]
    }
  ],
  record: null
}

const UploadedFileAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState( currentRecord);

  const handleExtraFields = (formik) => {
    return(
      <FormikFileUploadField formik={formik} fieldName={'slug'} isPreviewOnly={true} isPublic={false} label={'Slug'}/>
    )
  }

  return (
    <GenericAppFormBuilder  currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} onAddExtraFields={handleExtraFields}  />
  )
}

UploadedFileAppForm.propTypes = {
  onSubmit: PropTypes.func
}

export default UploadedFileAppForm;

import { takeLatest } from "redux-saga/effects";
import ProductTypeActions from "./actions/ProductTypeActions";
import GenericAppActions from "./actions/GenericAppActions";
import { PRODUCT_TYPE_PAGINATE_URL } from "../../routes/Apps/ProductTypeApp";
import { PRODUCT_TYPE_DESTROY, PRODUCT_TYPE_STORE, PRODUCT_TYPE_UPDATE } from "../action-types/ProductTypeActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(ProductTypeActions.store, action.payload, PRODUCT_TYPE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(ProductTypeActions.update, action.payload, PRODUCT_TYPE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(ProductTypeActions.destroy, action.payload, PRODUCT_TYPE_PAGINATE_URL);
}

export const ProductTypeSaga = [
  takeLatest(PRODUCT_TYPE_STORE, fetchStore),
  takeLatest(PRODUCT_TYPE_UPDATE, fetchUpdate),
  takeLatest(PRODUCT_TYPE_DESTROY, fetchDestroy),
];



import HttpClient, { HttpClientConfigFileUploader } from "../../../utils/HttpClient";

function uploadFile(aDomFile){
  // console.log(aDomFile);
  let formData = new FormData();
  formData.append('file', aDomFile);
  const cfg = {
    baseURL: HttpClientConfigFileUploader.baseURL,
    headers: {
    //   // 'Content-Type': 'application/json'
      'Content-Type': 'multipart/form-data'
    }
  };

  return HttpClient.HttpClientMain(cfg).post('/FileManager/UploadFile', formData);
}
function getDownloadFileUrl(aFileId){
  return `${HttpClientConfigFileUploader.baseURL}/FileManager/DownloadFileById?id=${aFileId}`;
}

function getDownloadFileSlugUrl(aSlug){
  return `${HttpClientConfigFileUploader.baseURL}/FileManager/DownloadImage?slug=${aSlug}`;
}

function getDownloadFileInfoUrl(aSlug){
  return `${HttpClientConfigFileUploader.baseURL}/FileManager/FileInfo?slug=${aSlug}`;
}



function getDownloadImageUrl(aSlug, aIsPublic){
  if(!aSlug){
    return false
  }

  if(aIsPublic){
    return `${process.env.REACT_APP_PUBLIC_S3_URL}/${aSlug}`;
  }

  return getDownloadFileSlugUrl(aSlug);
}



function downloadFile(aFileSlug){
  const cfg = {
    baseURL: HttpClientConfigFileUploader.baseURL,
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'multipart/form-data'
    }
  };

  return HttpClient.HttpClientMain(cfg).get(getDownloadFileSlugUrl(aFileSlug));
}

function getFileInfo(aFileSlug){
  const cfg = {
    baseURL: HttpClientConfigFileUploader.baseURL,
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'multipart/form-data'
    }
  };

  return HttpClient.HttpClientMain(cfg).get(getDownloadFileInfoUrl(aFileSlug));
}


export default {
  uploadFile,
  downloadFile,
  getDownloadFileUrl,
  getDownloadImageUrl,
  getDownloadFileSlugUrl,
  getFileInfo
}

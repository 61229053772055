import React, { lazy, useState } from "react";
import { Box, Button } from "@material-ui/core";
import useStyles from "./index.style";
import AppHeader from "./AppHeader";
import clsx from "clsx";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import GenericAppList from "./List";
import { GenericAppContext } from "./GenericAppContext";
import PropTypes from "prop-types";
import { GENERIC_APP_SET_CURRENT_RECORD, GENERIC_APP_SET_FORM_CONTAINER_OPEN } from "../../../redux/action-types/GenericAppActionTypes";
import FormContainerDrawer from "../../../components/form-container/FormContainerDrawer";
import FormContainerDialog from "../../../components/form-container/FormContainerDialog";
import AlertActions from "../../../redux/sagas/actions/AlertActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Route, Switch } from "react-router";

const myStyles = makeStyles(() => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const GenericApp = (props) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { isSideBarCollapsed } = useSelector(({ contactApp }) => contactApp);
  const { isLoading } = useSelector(({ common }) => common);
  const { currentDialog, isFormContainerOpen } = useSelector(({ genericAppReducer }) => genericAppReducer);
  // const isLargeScreen = useMediaQuery('(min-width:600px)');
  // console.log(isLargeScreen);
  const [viewMode, setViewMode] = useState('table'); // table, grid
  const [showContactDetail, setShowContactDetail] = useState(false);
  const dispatch = useDispatch();


  const onChangeViewMode = mode => {
    setViewMode(mode);
  };

  const setOpenCreateDialog = (aBool) => {
    dispatch({type: GENERIC_APP_SET_FORM_CONTAINER_OPEN, payload: aBool});
  }

  const onShowContactDetail = contact => {
    if(!props.appSettings.isUpdate){
      AlertActions.showDialogWarning( 'Not enough permissions', 'Please contact the administrator', 0);
      return;
    }

    if(props.isShowRecord){
      props.appSettings.events.onShowClick(contact);
      dispatch({type: GENERIC_APP_SET_CURRENT_RECORD, payload: contact});
      setOpenCreateDialog(true);
    }else{
      onClickEditContact(contact);
    }
  };

  const onHideContactDetail = () => {
    dispatch({type: GENERIC_APP_SET_CURRENT_RECORD, payload: null});
  };

  const onClickCreateContact = (e) => {
    props.appSettings.events.onCreateClick(e);
    dispatch({type: GENERIC_APP_SET_CURRENT_RECORD, payload: {}});
    setOpenCreateDialog(true);
  };

  const onClickEditContact = contact => {
    props.appSettings.events.onEditClick(contact);
    dispatch({type: GENERIC_APP_SET_CURRENT_RECORD, payload: contact});
    setOpenCreateDialog(true);
  };

  const onCloseComposeDialog = () => {
    dispatch({type: GENERIC_APP_SET_CURRENT_RECORD, payload: null});
    // dispatch(setCurrentContact(null));
    setOpenCreateDialog(false);
  };

  // const requestedUrl = match.url.replace(/\/$/, '');

  const GenericList = () =>{
    const ret = props.appSettings.isRead &&
      (
        <GenericAppList
          viewMode={viewMode}
          onShowContactDetail={onShowContactDetail}
          onClickEditContact={onClickEditContact}
        />
      );

    return ret;
  }


  return (
    <GenericAppContext.Provider value={props.appSettings}>
      {/*<Button onClick={() =>  dispatch({type: FETCH_START})}>Test</Button>*/}
      <Box className={classes.inBuildAppCard}>
      <AppHeader onChangeViewMode={onChangeViewMode} viewMode={viewMode} />
      <Box className={clsx(classes.inBuildAppContainer, isSideBarCollapsed ? 'collapsed' : '')}>
        <Sidebar onClickCreateContact={onClickCreateContact} />
        {/*<Switch>*/}
        {/*  <Route exact path={`/apps/uploaded-files/tenants`} component={lazy(() => <Button>My component</Button>)} />*/}
        {/*  <Route component={lazy(() => <GenericAppList/>)} />*/}
        {/*</Switch>*/}

        {props.appSettings.isRead &&
        <GenericAppList
          viewMode={viewMode}
          onShowContactDetail={onShowContactDetail}
          onClickEditContact={onClickEditContact}
        />}


      </Box>
      {/*{showContactDetail && <GenericAppDetail open={showContactDetail} handleDialog={onHideContactDetail} />}*/}
      {/*{openCreateDialog && <GenericAppCreate open={openCreateDialog} handleDialog={onCloseComposeDialog} />}*/}
      {
        (props.appSettings.isCreate || props.appSettings.isUpdate) ?
          (props.isUseDialog ?
            isFormContainerOpen && <FormContainerDialog title={props.appSettings.title} subTitle={props.appSettings.subTitle} isOpen={isFormContainerOpen} onClose={onCloseComposeDialog}>{props.form}</FormContainerDialog>:
            isFormContainerOpen && <FormContainerDrawer title={props.appSettings.title} subTitle={props.appSettings.subTitle} isOpen={isFormContainerOpen} onClose={onCloseComposeDialog}>{props.form}</FormContainerDrawer>
          ):''
      }
    </Box>
    </GenericAppContext.Provider>
  );
};

GenericApp.defaultProps = {
  isShowRecord: false,
  isUseDialog: false,
}

GenericApp.propTypes = {
  appSettings: PropTypes.any,
  isShowRecord: PropTypes.bool,
  isUseDialog: PropTypes.bool,
  form: PropTypes.any
}

export default GenericApp;

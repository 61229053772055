const moment = require('moment');

const optionsDateTime = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
};
const optionsDate = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

const formatDate = (aDate, aMomentLang = 'en-us', aFormat = 'L', aIsUtc = true) => {
  if (aMomentLang.toLowerCase()
    .indexOf('pt') !== -1) {
    return moment(aDate)
      .locale('pt-br')
      .format(aFormat);
  }
  return moment(aDate)
    .locale(aMomentLang)
    .format(aFormat);
};

const formatDateLong = (aDate, aMomentLang = 'en-us', aFormat = 'ddd, MMM Do, YYYY') => {
  return formatDate(aDate, aMomentLang, aFormat);
};

const formatDateTimeLong = (aDate, aMomentLang = 'en-us', aFormat = 'llll') => {
  return formatDate(aDate, aMomentLang, aFormat);
};


const formatDateTime = (aDate, aMomentLang = 'en-us', aFormat = 'LLL') => {
  return formatDate(aDate, aMomentLang, aFormat);
};

function getCurrencyFormatter(aLocale = 'en-US', aCurrency = 'USD', aStyle = 'currency', minimumFractionDigits = 2, maximumFractionDigits = 2) {
  return new Intl.NumberFormat(aLocale, {
    style: aStyle,
    currency: aCurrency,
    minimumFractionDigits: minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
  });
}

function formatCurrency(aValue, aLocale = 'en-US', minimumFractionDigits = 2, maximumFractionDigits = 2) {

  if (aLocale.toLowerCase()
    .indexOf('pt') !== -1) {
    return getCurrencyFormatter('pt-BR', 'BRL', 'currency', minimumFractionDigits, maximumFractionDigits)
      .format(aValue);
  }

  return getCurrencyFormatter()
    .format(aValue);
}

function formatDateTimeLocale(aDate) {
  return new Date(aDate).toLocaleDateString(undefined, optionsDateTime);
}

function formatDateLocale(aDate) {
  return new Date(aDate).toLocaleDateString(undefined, optionsDate);
}

const getMonthList = (locale = 'en', notation = 'long') =>
  Array.from(
    Array(12)
      .keys(),
    key => Intl.DateTimeFormat(locale, {
      month: {
        s: 'short',
        n: 'numeric'
      } [notation[0]] || 'long'
    })
      .format(new Date(0, key))
  );

function getMonthName(aDate, aFormat = 'MMMM'){
  return moment(aDate).format(aFormat);
}

function getYear(aDate,aFormat = 'YYYY'){
  return moment(aDate).format(aFormat);
}

function getDayOfWeek(aDate,aFormat = 'dddd'){
  return moment(aDate).format(aFormat);
}

module.exports = {
  getCurrencyFormatter: getCurrencyFormatter,
  formatCurrency: formatCurrency,
  formatDate: formatDate,
  formatDateTime: formatDateTime,
  formatDateTimeLocale: formatDateTimeLocale,
  formatDateLocale: formatDateLocale,
  getMonthList: getMonthList,
  getMonthName: getMonthName,
  getYear: getYear,
  getDayOfWeek: getDayOfWeek,
  formatDateLong: formatDateLong,
  formatDateTimeLong: formatDateTimeLong
};

import { takeLatest } from "redux-saga/effects";
import ProjectActions from "./actions/ProjectActions";
import GenericAppActions from "./actions/GenericAppActions";
import { PROJECT_PAGINATE_URL } from "../../routes/Apps/ProjectApp";
import { PROJECT_DESTROY, PROJECT_STORE, PROJECT_UPDATE } from "../action-types/ProjectActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(ProjectActions.store, action.payload, PROJECT_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(ProjectActions.update, action.payload, PROJECT_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(ProjectActions.destroy, action.payload, PROJECT_PAGINATE_URL);
}

export const ProjectSaga = [
  takeLatest(PROJECT_STORE, fetchStore),
  takeLatest(PROJECT_UPDATE, fetchUpdate),
  takeLatest(PROJECT_DESTROY, fetchDestroy),
];



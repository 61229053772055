import React from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";

import { useAuth } from "../context/AuthContext";

function PrivateRoute ({component: Component, ...rest}) {
  const { isAuthenticated } = useAuth();
  return (
    <Route {...rest}
      render={
        (props) => (isAuthenticated() === true)
          ? <Component {...props}/>
          : <Redirect to={'/signin'} />
      }
    />
  )
}
// <Redirect to={{pathname: '/login', state: {from: props.location}}} />
export default PrivateRoute;

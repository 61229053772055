import { takeLatest } from "redux-saga/effects";
import ProductCategoryActions from "./actions/ProductCategoryActions";
import GenericAppActions from "./actions/GenericAppActions";
import { PRODUCT_CATEGORY_PAGINATE_URL } from "../../routes/Apps/ProductCategoryApp";
import { PRODUCT_CATEGORY_DESTROY, PRODUCT_CATEGORY_STORE, PRODUCT_CATEGORY_UPDATE } from "../action-types/ProductCategoryActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(ProductCategoryActions.store, action.payload, PRODUCT_CATEGORY_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(ProductCategoryActions.update, action.payload, PRODUCT_CATEGORY_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(ProductCategoryActions.destroy, action.payload, PRODUCT_CATEGORY_PAGINATE_URL);
}

export const ProductCategorySaga = [
  takeLatest(PRODUCT_CATEGORY_STORE, fetchStore),
  takeLatest(PRODUCT_CATEGORY_UPDATE, fetchUpdate),
  takeLatest(PRODUCT_CATEGORY_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import UserResourceActions from "./actions/UserResourceActions";
import GenericAppActions from "./actions/GenericAppActions";
import { USER_RESOURCE_PAGINATE_URL } from "../../routes/Apps/UserResourceApp";
import { USER_RESOURCE_DESTROY, USER_RESOURCE_STORE, USER_RESOURCE_UPDATE } from "../action-types/UserResourceActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(UserResourceActions.store, action.payload, USER_RESOURCE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(UserResourceActions.update, action.payload, USER_RESOURCE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(UserResourceActions.destroy, action.payload, USER_RESOURCE_PAGINATE_URL);
}

export const UserResourceSaga = [
  takeLatest(USER_RESOURCE_STORE, fetchStore),
  takeLatest(USER_RESOURCE_UPDATE, fetchUpdate),
  takeLatest(USER_RESOURCE_DESTROY, fetchDestroy),
];



export const GENERIC_APP_ADD_LABEL = 'GENERIC_APP_ADD_LABEL';
export const GENERIC_APP_CREATE_RECORD = 'GENERIC_APP_CREATE_RECORD';
export const GENERIC_APP_DELETE_RECORD = 'GENERIC_APP_DELETE_RECORD';
export const GENERIC_APP_DELETE_LABEL_ITEM = 'GENERIC_APP_DELETE_LABEL_ITEM';
export const GENERIC_APP_GET_RECORD_COUNTS = 'GENERIC_APP_GET_RECORD_COUNTS';

export const GENERIC_APP_GET_RECORDS_LIST = 'GENERIC_APP_GET_RECORDS_LIST';
export const GENERIC_APP_GET_RECORDS_LIST_SUCCESS = 'GENERIC_APP_GET_RECORDS_LIST_SUCCESS';

export const GENERIC_APP_GET_SEARCH_RECORDS_LIST = 'GENERIC_APP_GET_SEARCH_RECORDS_LIST';

export const GENERIC_APP_GET_LABELS_LIST = 'GENERIC_APP_GET_LABELS_LIST';
export const GENERIC_APP_SET_CURRENT_RECORD = 'GENERIC_APP_SET_CURRENT_RECORD';
export const GENERIC_APP_SET_FILTER_TYPE = 'GENERIC_APP_SET_FILTER_TYPE';
export const GENERIC_APP_TOGGLE_SIDEBAR_COLLAPSED = 'GENERIC_APP_TOGGLE_SIDEBAR_COLLAPSED';
export const GENERIC_APP_UPDATE_RECORD = 'GENERIC_APP_UPDATE_RECORD';
export const GENERIC_APP_UPDATE_RECORD_LABEL = 'GENERIC_APP_UPDATE_RECORD_LABEL';
export const GENERIC_APP_UPDATE_LABEL_ITEM = 'GENERIC_APP_UPDATE_LABEL_ITEM';
export const GENERIC_APP_UPDATE_STARRED_STATUS = 'GENERIC_APP_UPDATE_STARRED_STATUS';
export const GENERIC_APP_SET_CURRENT_PAGE_NO = 'GENERIC_APP_SET_CURRENT_PAGE_NO';
export const GENERIC_APP_SET_CURRENT_PAGE_LIMIT = 'GENERIC_APP_SET_CURRENT_PAGE_LIMIT';
export const GENERIC_APP_SHOW_DIALOG = 'GENERIC_APP_SET_CURRENT_PAGE_LIMIT';
export const GENERIC_APP_SET_DIALOG = 'GENERIC_APP_SET_DIALOG';
export const GENERIC_APP_UNSET_DIALOG = 'GENERIC_APP_UNSET_DIALOG';
export const GENERIC_APP_SET_FORM_CONTAINER_OPEN = 'GENERIC_APP_SET_FORM_CONTAINER_OPEN';

export const GENERIC_APP_UPDATE_OR_STORE = 'GENERIC_APP_UPDATE_OR_STORE';


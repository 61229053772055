import React from "react";
import PropTypes from "prop-types";
import CmtAvatar from "../../@coremat/CmtAvatar";

function ImageAuth(props){
  return(
      <CmtAvatar alt={props.slug} key={props.slug} id={props.slug} width={props.width} height={props.height} variant={props.variant}  {...props}/>
  )
}

ImageAuth.defaultProps = {
  width: '100',
  height: '100',
  variant: 'circle'
}

ImageAuth.propTypes = {
  slug: PropTypes.string,
  isPublic: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  variant: PropTypes.string
}

export default ImageAuth;

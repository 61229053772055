import React, { useContext, useEffect, useState } from "react";
import { firebaseAuth } from "../config/firebase.conf";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut as signOutFirebase, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useDispatch } from "react-redux";
import { USER_AFTER_LOGIN_SUCCESS } from "../redux/action-types/UserActionTypes";
import UserActions from "../redux/sagas/actions/UserActions";

const AuthCtx = React.createContext();

const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope("https://www.googleapis.com/auth/contacts.readonly");

export function useAuth() {
  return useContext(AuthCtx);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [appContext, setAppContext] = useState();
  const [loading, setLoading] = useState(true);
  const [afterLoginLoading, setAfterLoginLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    return firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        doAfterLoginOrSignUp(user).then(data => {
          setCurrentUser(user);
        }).finally(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, []);


  const signIn = (email, password) => {
    return signInWithEmailAndPassword(firebaseAuth, email, password);
  };

  const signInWithGoogle = () => {
    // Not used
    // return signInWithPopup(firebaseAuth, googleAuthProvider).then(r => {
    //     doAfterLoginOrSignUp(r);
    //     setCurrentUser(r);
    //     return Promise.resolve(r);
    // });
  };

  const signUp = (email, password) => {
    return createUserWithEmailAndPassword(firebaseAuth, email, password);
    // .then(r => {
    //   return doAfterLoginOrSignUp(r.user).then(data => {
    //     setCurrentUser(r.user);
    //   });
    // });
  };

  const doAfterLoginOrSignUp = (r) => {
      const user = {
        userName: r.email,
        oauthId: r.uid,
        firstName: r.email.substr(0, r.email.indexOf("@")),
        lastName: r.email.substr(0, r.email.indexOf("@")),
        email: r.email,
        accessToken: r.accessToken
      };

      return UserActions.doAfterLoginWithToken(user, r.accessToken).then(r1 => {
        dispatch({ type: USER_AFTER_LOGIN_SUCCESS, payload: r1.data });
        setAppContext(r1.data);
        return Promise.resolve(r1.data);
      });
  };

  const signOut = () => {
    return signOutFirebase(firebaseAuth);
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(firebaseAuth, email);
  };

  const isAuthenticated = () => {
    // console.log(firebaseAuth.currentUser);
    return firebaseAuth.currentUser !== null;
  };

  firebaseAuth.onAuthStateChanged(user => {
    setCurrentUser(user);
  });

  const value = {
    currentUser,
    appContext,
    signIn,
    signInWithGoogle,
    signUp,
    signOut,
    isAuthenticated,
    resetPassword

  };

  return (
    <AuthCtx.Provider value={value}>{(!loading) && children}</AuthCtx.Provider>
  );
}

import { takeLatest } from "redux-saga/effects";
import PlanActions from "./actions/PlanActions";
import GenericAppActions from "./actions/GenericAppActions";
import { PLAN_PAGINATE_URL } from "../../routes/Apps/PlanApp";
import { PLAN_DESTROY, PLAN_STORE, PLAN_UPDATE } from "../action-types/PlanActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(PlanActions.store, action.payload, PLAN_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(PlanActions.update, action.payload, PLAN_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(PlanActions.destroy, action.payload, PLAN_PAGINATE_URL);
}

export const PlanSaga = [
  takeLatest(PLAN_STORE, fetchStore),
  takeLatest(PLAN_UPDATE, fetchUpdate),
  takeLatest(PLAN_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import UserStatusActions from "./actions/UserStatusActions";
import GenericAppActions from "./actions/GenericAppActions";
import { USER_STATUS_PAGINATE_URL } from "../../routes/Apps/UserStatusApp";
import { USER_STATUS_DESTROY, USER_STATUS_STORE, USER_STATUS_UPDATE } from "../action-types/UserStatusActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(UserStatusActions.store, action.payload, USER_STATUS_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(UserStatusActions.update, action.payload, USER_STATUS_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(UserStatusActions.destroy, action.payload, USER_STATUS_PAGINATE_URL);
}

export const UserStatusSaga = [
  takeLatest(USER_STATUS_STORE, fetchStore),
  takeLatest(USER_STATUS_UPDATE, fetchUpdate),
  takeLatest(USER_STATUS_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import UnitMeasurementActions from "./actions/UnitMeasurementActions";
import GenericAppActions from "./actions/GenericAppActions";
import { UNIT_MEASUREMENT_PAGINATE_URL } from "../../routes/Apps/UnitMeasurementApp";
import { UNIT_MEASUREMENT_DESTROY, UNIT_MEASUREMENT_STORE, UNIT_MEASUREMENT_UPDATE } from "../action-types/UnitMeasurementActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(UnitMeasurementActions.store, action.payload, UNIT_MEASUREMENT_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(UnitMeasurementActions.update, action.payload, UNIT_MEASUREMENT_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(UnitMeasurementActions.destroy, action.payload, UNIT_MEASUREMENT_PAGINATE_URL);
}

export const UnitMeasurementSaga = [
  takeLatest(UNIT_MEASUREMENT_STORE, fetchStore),
  takeLatest(UNIT_MEASUREMENT_UPDATE, fetchUpdate),
  takeLatest(UNIT_MEASUREMENT_DESTROY, fetchDestroy),
];



import { Form, Formik } from "formik";
import { Button, Grid } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import FormikField from "../formik/FormikField";
import {
  AUTOCOMPLETE,
  AUTOCOMPLETE_MULTIPLE,
  CHECKBOX,
  COMPONENT,
  CURRENCY,
  DATE,
  DATE_TIME,
  FILE_UPLOAD_DROPZONE,
  INTEGER, NUMBER,
  SELECT,
  TEXT,
  TEXT_AREA,
  TEXT_DISABLED, TEXT_FIELD,
  TIME
} from "./VinSystemsFormBuilderFieldTypes";
import { Save } from "@material-ui/icons";
import FormikSelect from "../formik/FormikSelect";
import FormikFileUploadField from "../formik/FormikFileUploadField";
import FormikCheckboxField from "../formik/FormikCheckboxField";
import FormikTextAreaField from "../formik/FormikTextAreaField";
import FormikAutocompleteField from "../formik/FormikAutocomplete";
import FormikCurrencyField from "../formik/FormikCurrencyField";
import FormikAutocompleteMultiple from "../formik/FormikAutocompleteMultiple";
import FormikDateField from "../formik/FormikDateField";
import FormikDateTimeField from "../formik/FormikDateTimeField";
import FormikTimeField from "../formik/FormikTimeField";
import FormGroupTitle from "../form-container/FormGroupTitle";
import FormikDisabledField from "../formik/FormikDisabledField";
import FormikTextField from "../formik/FormikTextField";
import { useIntl } from "react-intl";
import FormikNumberField from "../formik/FormikNumberField";


const getFieldByType = (aField, formik, form) => {
  const isRequired = form?.validation?.fields[aField.name]?.exclusiveTests?.required || false;
  const vLabel = isRequired ? aField.label + " *" : aField.label;

  switch (aField.type.toLowerCase()) {
    case TEXT:
      return <FormikField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;
    case TEXT_FIELD:
      return <FormikTextField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;
    case TEXT_DISABLED:
      return <FormikDisabledField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;
    case SELECT:
      return <FormikSelect key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;
    case FILE_UPLOAD_DROPZONE:
      return <FormikFileUploadField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;
    case CHECKBOX:
      return <FormikCheckboxField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;
    case TEXT_AREA:
      return <FormikTextAreaField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;
    case AUTOCOMPLETE:
      return <FormikAutocompleteField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;
    case AUTOCOMPLETE_MULTIPLE:
      return <FormikAutocompleteMultiple key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;
    case CURRENCY:
      return <FormikCurrencyField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;
    case INTEGER:
      return <FormikCurrencyField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel}
                                  fractionDigits={0} currencySymbol={""} />;
    case NUMBER:
      return  <FormikNumberField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel}/>;
    case DATE:
      return <FormikDateField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} fractionDigits={0}
                              currencySymbol={""} />;
    case DATE_TIME:
      return <FormikDateTimeField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel}
                                  fractionDigits={0} currencySymbol={""} />;
    case TIME:
      return <FormikTimeField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} fractionDigits={0}
                              currencySymbol={""} />;
    case COMPONENT:
      const Comp = aField.fieldProps.component;
      return <Comp key={form.name + "-" + aField.name} formik={formik} fieldName={aField.name} type={aField.type} name={aField.name} label={vLabel} />;

    default:
      return <FormikField key={form.name + "-" + aField.name} {...aField.fieldProps} formik={formik} fieldName={aField.name} type={TEXT} name={aField.name} label={vLabel} />;
  }
};

const VinSystemsFormBuilder = (props) => {

  const [obj, setObj] = useState(props.currentRecord || {});
  const intl = useIntl();
  // const myMemo = useMemo(() => getFieldByType(aField, formik, form), [aField, formik, form])

  const handleSubmit = (values, formik) => {
    // console.log(values);
    try {
      if (props.onSubmit) {
        props.onSubmit(values, formik);
      }
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={obj} validationSchema={props.formObj.validation} onSubmit={handleSubmit} enableReinitialize={true} validateOnChange={props.validateOnChange}>
      {
        (pr) => {
          return (

            <Form>
              {
                props.formObj?.groups?.map((g) => (
                  <div>
                    <FormGroupTitle title={g.title} subTitle={g.subTitle} />
                    <Grid container spacing={2}>
                      {g.fields.map((o) => {
                        o.label = intl.formatMessage({ id: o.label });
                        return getFieldByType(o, pr, props.formObj);
                      })}
                    </Grid>
                  </div>
                ))
              }

              {props.onAddExtraFields && props.onAddExtraFields(pr)}
              {/*<Button onClick={(e) => console.log(obj)}>Test</Button>*/}

              {!props.formObj.isReadOnly &&
              <Button variant="contained" startIcon={props.submitIcon} color={props.submitColor} type="button" disabled={pr.isSubmitting} onClick={pr.handleSubmit} style={{ marginTop: "20px" }}>
                {props.submitLabel}
              </Button>
              }
              {/*<pre>{JSON.stringify(pr.values)}</pre>*/}
            </Form>
          );
        }
      }
    </Formik>
  );
};

VinSystemsFormBuilder.defaultProps = {
  submitColor: "primary",
  submitLabel: "Submit",
  submitIcon: <Save />,
  currentRecord: {},
  validateOnChange: false
};

VinSystemsFormBuilder.propTypes = {
  submitLabel: PropTypes.string,
  formObj: PropTypes.object,
  onSubmit: PropTypes.func,
  onAddExtraFields: PropTypes.func,
  submitIcon: PropTypes.any,
  currentRecord: PropTypes.object,
  validateOnChange: PropTypes.bool
};

export default VinSystemsFormBuilder;

import React, { Fragment } from "react";
import { FieldArray, getIn } from "formik";
import { Button, Grid, IconButton, Paper, Stack } from "@mui/material";
import { TextField, Typography } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import DeleteIcon from "@material-ui/icons/Delete";
import { Add } from "@material-ui/icons";
import PropTypes from 'prop-types';
import FormGroupTitle from "../../../components/form-container/FormGroupTitle";

const OrganizationContactsFieldArray = (props) => {
  const myObj = { name: "", email: "", cellphone: "", phone: "" };
  const { formik } = props;
  const { values, touched, errors, handleChange, handleBlur, isValid } = formik;
  const arr = values.organizationContacts || [];
  const arrLength = values?.organizationContacts?.length || 0;
  const arrName = 'organizationContacts';

  return (
    <FieldArray name={arrName}>
      {({ insert, remove, push }) => {

        return (
          <Grid spacing={4}>
            <FormGroupTitle title={'Contacts'} subTitle={''}/>

            {arrLength > 0 && arr.map((item, index) => {

              const namePrefix = `${arrName}[${index}]`;

              const titleName = `${namePrefix}.name`;
              const touchedTitle = getIn(touched, titleName);
              const errorTitle = getIn(errors, titleName);

              const emailName = `${namePrefix}.email`;
              const touchedText = getIn(touched, emailName);
              const errorText = getIn(errors, emailName);

              const cellphoneName = `${namePrefix}.cellphone`;
              const touchedCellphone = getIn(touched, cellphoneName);
              const errorCellphone = getIn(errors, cellphoneName);

              const phoneName = `${namePrefix}.phone`;
              const touchedPhone = getIn(touched, phoneName);
              const errorPhone = getIn(errors, phoneName);

              return (
                <Paper variant={"outlined"}  style={{ marginBottom: "10px" }} >
                  <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                    <TextField
                      // component={TextField}
                      fullWidth
                      name={titleName}
                      // fieldName={`organizationNotes.${index}.title`}
                      value={item.name}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Name"}
                      size={"small"}
                      autoComplete={false}
                      // formik={formik}
                    />
                    <TextField
                      // component={TextField}
                      fullWidth
                      name={emailName}
                      // fieldName={`organizationNotes.${index}.text`}
                      value={item.email}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Email"}
                      size={"small"}
                      autoComplete={false}
                      // formik={formik}
                    />
                    <TextField
                      // component={TextField}
                      fullWidth
                      name={cellphoneName}
                      // fieldName={`organizationNotes.${index}.text`}
                      value={item.cellphone}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Cellphone"}
                      size={"small"}
                      autoComplete={false}
                      // formik={formik}
                    />
                    <TextField
                      // component={TextField}
                      fullWidth
                      name={phoneName}
                      // fieldName={`organizationNotes.${index}.text`}
                      value={item.phone}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Phone"}
                      size={"small"}
                      autoComplete={false}
                      // formik={formik}
                    />

                    <IconButton aria-label="delete" onClick={() => remove(index)}>
                      <DeleteIcon color={"secondary"} />
                    </IconButton>
                    <IconButton aria-label="add" onClick={() => push(myObj)}>
                      <Add color={"primary"} />
                    </IconButton>
                  </Stack>
                </Paper>
              )}

            )}
            {/*<p>sdlfsdljfslkdj</p>*/}
            {/*<pre>{JSON.stringify(formik?.values?.organizationNotes)}</pre>*/}

            {arrLength === 0 &&
            <Button variant="outlined" startIcon={<Add />} onClick={() => push(myObj)}>
              Add
            </Button>
            }
          </Grid>
        );
      }}
    </FieldArray>
  );
};

OrganizationContactsFieldArray.propTypes = {
  formik: PropTypes.any
}

export default OrganizationContactsFieldArray;

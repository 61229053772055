import { call, put, takeLatest } from "redux-saga/effects";
import UserActions from "./actions/UserActions";
import { USER_AFTER_LOGIN, USER_AFTER_LOGIN_SUCCESS, USER_PAGE, USER_PAGE_SUCCESS, USER_STORE, USER_STORE_SUCCESS, USER_UPDATE } from "../action-types/UserActionTypes";
import AlertActions from "./actions/AlertActions";
import GenericAppActions from "./actions/GenericAppActions";
import UploadedFileActions from "./actions/UploadedFileActions";
import { UPLOADED_FILE_PAGINATE_URL } from "../../routes/Apps/UploadedFileApp";
import { UPDATE_CURRENT_USER_TENANT_SUCCESS } from "../action-types/TenantActionTypes";
import { USER_SET_CURRENT } from "../../@jumbo/constants/ActionTypes";

// import withReactContent from 'sweetalert2-react-content';
// import Swal from 'sweetalert2';

// const MySwal = withReactContent(Swal);

// function* showDialog(action) {
//   return new Promise(resolve => {
//     MySwal.fire({
//       target: document.getElementById('generic-app-dialog'),
//       position: action.payload.position,
//       icon: action.payload.icon,
//       title: action.payload.title,
//       timer: action.payload.timer
//     });
//   });
// }

function* fetchUser(action) {
  const user = yield call(UserActions.doAfterLogin, action.payload);
  yield put({type: USER_AFTER_LOGIN_SUCCESS, payload: user.data});
}



function* fetchUserPage(action) {
  const user = yield call(UserActions.getUserPage, action.payload);
  yield put({type: USER_PAGE_SUCCESS, payload: user.data});
}

function* fetchUserStore(action) {
  try{
    const user = yield call(UserActions.storeUser, action.payload);
    yield put({type: USER_STORE_SUCCESS, payload: user.data});
    yield AlertActions.showDialogSuccess('Success Saving User', '');
  }catch (e) {
    yield AlertActions.showDialogError('Error Saving User', e);
  }
}

function* fetchUpdate(action) {
  try{
    const user = yield call(UserActions.update, action.payload);
    yield put({type: USER_SET_CURRENT, payload: user.data});
    yield AlertActions.showDialogSuccess('Success Saving User', '');
  }catch (e) {
    yield AlertActions.showDialogError('Error Saving User', e);
  }
}

export const UserSaga = [
  takeLatest(USER_AFTER_LOGIN, fetchUser),
  takeLatest(USER_PAGE, fetchUserPage),
  takeLatest(USER_STORE, fetchUserStore),
  takeLatest(USER_UPDATE, fetchUpdate),
];

import { takeLatest } from "redux-saga/effects";
import IndustryActions from "./actions/IndustryActions";
import GenericAppActions from "./actions/GenericAppActions";
import { INDUSTRY_PAGINATE_URL } from "../../routes/Apps/IndustryApp";
import { INDUSTRY_DESTROY, INDUSTRY_STORE, INDUSTRY_UPDATE } from "../action-types/IndustryActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(IndustryActions.store, action.payload, INDUSTRY_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(IndustryActions.update, action.payload, INDUSTRY_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(IndustryActions.destroy, action.payload, INDUSTRY_PAGINATE_URL);
}

export const IndustrySaga = [
  takeLatest(INDUSTRY_STORE, fetchStore),
  takeLatest(INDUSTRY_UPDATE, fetchUpdate),
  takeLatest(INDUSTRY_DESTROY, fetchDestroy),
];



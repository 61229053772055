import React from "react";
import { FieldArray, getIn } from "formik";
import { Button, Grid, IconButton, Paper, Stack } from "@mui/material";
import { TextField } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import DeleteIcon from "@material-ui/icons/Delete";
import { Add } from "@material-ui/icons";
import PropTypes from "prop-types";
import FormGroupTitle from "../../../components/form-container/FormGroupTitle";

const UserNotesFieldArray = (props) => {
  const myObj = { title: "", text: "" };
  const { formik } = props;
  const { values, touched, errors, handleChange, handleBlur, isValid } = formik;

  const title = 'Notes';
  const arrName = 'userNotes';
  const arr = values[arrName] || [];
  const arrLength = values[arrName]?.length || 0;


  return (
    <FieldArray name={arrName}>
      {({ insert, remove, push }) => {

        return (
          <Grid spacing={4}>
            <FormGroupTitle title={title} subTitle={''}/>

            {arrLength > 0 && arr.map((item, index) => {

              const namePrefix = `${arrName}[${index}]`;

              const titleName = `${namePrefix}.title`;
              const touchedTitle = getIn(touched, titleName);
              const errorTitle = getIn(errors, titleName);

              const textName = `${namePrefix}.text`;
              const touchedText = getIn(touched, textName);
              const errorText = getIn(errors, textName);

              const noteDateName = `${namePrefix}.noteDate`;
              const touchedNoteDate = getIn(touched, noteDateName);
              const errorNoteDate = getIn(errors, noteDateName);

              return (
                <Paper variant={"outlined"}  style={{ marginBottom: "10px" }} >
                  <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                    <TextField
                      // component={TextField}
                      fullWidth
                      name={titleName}
                      // fieldName={`organizationNotes.${index}.title`}
                      value={item.title}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Title"}
                      size={"small"}
                      // formik={formik}

                    />
                    <TextField
                      // component={TextField}
                      fullWidth
                      name={textName}
                      // fieldName={`organizationNotes.${index}.text`}
                      value={item.text}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Text"}
                      size={"small"}
                      // formik={formik}
                    />
                    <LocalizationProvider  dateAdapter={DateAdapter}>
                      <DatePicker
                        {...props}
                        fullWidth={true}
                        name={noteDateName}
                        value={item.noteDate}
                        // value={pr.values && pr.values[props.fieldName]}
                        onChange={(e) => formik.setFieldValue(noteDateName, e)}
                        renderInput={(params) => <TextField {...params} variant={'outlined'} size="small" fullWidth={true}/>}
                        defaultValue={null}
                      />
                    </LocalizationProvider>

                    <IconButton aria-label="delete" onClick={() => remove(index)}>
                      <DeleteIcon color={"secondary"} />
                    </IconButton>
                    <IconButton aria-label="add" onClick={() => push(myObj)}>
                      <Add color={"primary"} />
                    </IconButton>
                  </Stack>
                </Paper>
              )}

            )}
            {/*<p>sdlfsdljfslkdj</p>*/}
            {/*<pre>{JSON.stringify(formik?.values?.organizationNotes)}</pre>*/}

            {arrLength === 0 &&
            <Button variant="outlined" startIcon={<Add />} onClick={() => push(myObj)}>
              Add
            </Button>
            }
          </Grid>
        );
      }}
    </FieldArray>
  );
};

UserNotesFieldArray.propTypes = {
  formik: PropTypes.any
}

export default UserNotesFieldArray;

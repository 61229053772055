import { takeLatest } from "redux-saga/effects";
import EmailHistoryActions from "./actions/EmailHistoryActions";
import GenericAppActions from "./actions/GenericAppActions";
import { EMAIL_HISTORY_PAGINATE_URL } from "../../routes/Apps/EmailHistoryApp";
import { EMAIL_HISTORY_DESTROY, EMAIL_HISTORY_STORE, EMAIL_HISTORY_UPDATE } from "../action-types/EmailHistoryActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(EmailHistoryActions.store, action.payload, EMAIL_HISTORY_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(EmailHistoryActions.update, action.payload, EMAIL_HISTORY_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(EmailHistoryActions.destroy, action.payload, EMAIL_HISTORY_PAGINATE_URL);
}

export const EmailHistorySaga = [
  takeLatest(EMAIL_HISTORY_STORE, fetchStore),
  takeLatest(EMAIL_HISTORY_UPDATE, fetchUpdate),
  takeLatest(EMAIL_HISTORY_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import JobTitleActions from "./actions/JobTitleActions";
import GenericAppActions from "./actions/GenericAppActions";
import { JOB_TITLE_PAGINATE_URL } from "../../routes/Apps/JobTitleApp";
import { JOB_TITLE_DESTROY, JOB_TITLE_STORE, JOB_TITLE_UPDATE } from "../action-types/JobTitleActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(JobTitleActions.store, action.payload, JOB_TITLE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(JobTitleActions.update, action.payload, JOB_TITLE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(JobTitleActions.destroy, action.payload, JOB_TITLE_PAGINATE_URL);
}

export const JobTitleSaga = [
  takeLatest(JOB_TITLE_STORE, fetchStore),
  takeLatest(JOB_TITLE_UPDATE, fetchUpdate),
  takeLatest(JOB_TITLE_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import UserTypeActions from "./actions/UserTypeActions";
import GenericAppActions from "./actions/GenericAppActions";
import { USER_TYPE_PAGINATE_URL } from "../../routes/Apps/UserTypeApp";
import { USER_TYPE_DESTROY, USER_TYPE_STORE, USER_TYPE_UPDATE } from "../action-types/UserTypeActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(UserTypeActions.store, action.payload, USER_TYPE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(UserTypeActions.update, action.payload, USER_TYPE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(UserTypeActions.destroy, action.payload, USER_TYPE_PAGINATE_URL);
}

export const UserTypeSaga = [
  takeLatest(USER_TYPE_STORE, fetchStore),
  takeLatest(USER_TYPE_UPDATE, fetchUpdate),
  takeLatest(USER_TYPE_DESTROY, fetchDestroy),
];



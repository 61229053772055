import React, { useState } from "react";
import PropTypes from "prop-types";
import { Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const AutocompleteMultiple = (props) => {

  const [open, setOpen] = useState(false);

  const getDescription = (option) => {
    return  option ? `${option.title} - ${option.id}` : '';
  }

  return (
      <Autocomplete
        multiple
        variant={'outlined'}
        id="tags-filled"
        options={props.options}
        // defaultValue={selectedOptions}
        value={props.selectedOptions}
        disableCloseOnSelect
        onChange={props.onChange}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        // isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={getDescription}
        getOptionSelected={(option, value) => option.id === value.id}
        // freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={getDescription(option)} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={props.label}
            placeholder={props.label}
          />
        )}
      />
  );
};

AutocompleteMultiple.defaultProps = {
  isOpen: false,
  selectedOptions: [],
  options: []
};

AutocompleteMultiple.propTypes = {
  onButtonClick: PropTypes.func,
  buttonIcon: PropTypes.any,
  value: PropTypes.object,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  options: PropTypes.array
};

export default AutocompleteMultiple;

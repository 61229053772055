import * as React from "react";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import PropTypes from 'prop-types';
import { hasPermissionsOr, hasPermissionsAnd } from "../../services/auth/PermissionCheckService";

function PermissionGuard(props) {

  const { currentUser } = useSelector(({ usersReducer }) => usersReducer);
  const { user } = currentUser || { roles: [], permissions: [] };

  const isRender = props.isAndOperator ? hasPermissionsAnd(user, props.requiredPermissions) : hasPermissionsOr(user, props.requiredPermissions);
  const rendered = isRender ? (<Fragment>{props.children}</Fragment>) : '';
  return (rendered);
}

PermissionGuard.defaultProps = {
  requiredPermissions: [],
  isAndOperator: true
}

PermissionGuard.propTypes = {
  requiredPermissions: PropTypes.array,
  isAndOperator: PropTypes.bool
}

export default PermissionGuard;

import React, { Fragment } from "react";
import GenericApp from "../GenericApp";
import ClientAppForm from "./ClientAppForm";
import { useDispatch, useSelector } from "react-redux";
import GenericAppUtils from "../GenericApp/GenericAppUtils";
import { useHistory } from "react-router-dom";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import AttachFileIcon from "@material-ui/icons/Attachment";
import { CLIENT_DESTROY, CLIENT_STORE, CLIENT_UPDATE } from "../../../redux/action-types/ClientActionTypes";

export const CLIENT_FRONTEND_INDEX_URL = '/apps/client';
export const CLIENT_PAGINATE_URL = '/Client/Paginate';
const NAME = 'client-app';
const TITLE = 'Client';
const SUBTITLE = 'App';
const ICON = <AttachFileIcon />;

function TenantApp(props) {

  const dispatch = useDispatch();
  const hist = useHistory();
  let { page, pageLimit } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const intl = useIntl();

  const handleIndex = () => {
    hist.push(CLIENT_FRONTEND_INDEX_URL);
  }

  const handleDestroy = (values) => {
    dispatch({type: CLIENT_DESTROY, payload: values});
  };

  const handleSubmit = (values, formik) => {
    const type = values.id ?  CLIENT_UPDATE : CLIENT_STORE;
    dispatch({type: type, payload: values});
  };

  const AppSettings = {
    name: `${NAME}-app`,
    title: <IntlMessages id={TITLE} />,
    subTitle: SUBTITLE,
    searchTitle: intl.formatMessage({ id: 'Search...' }),
    notFoundTitle: <IntlMessages id={'No records found'} />,
    isCreate: true,
    isRead: true,
    isUpdate: true,
    isDelete: true,
    urls: {
      indexFrontend: CLIENT_FRONTEND_INDEX_URL
    },
    createButton: {
      title: <IntlMessages id={'Create New'} />,
      icon: null
    },
    headers: [
      <IntlMessages id={'Name'} />,
      <IntlMessages id={'Id'} />
    ],
    menuList: [
      {
        title: "Menu",
        items: [
          { id: 1, name: <IntlMessages id={TITLE} />, slug: `${NAME}-all`, icon: ICON, onClick: handleIndex }
        ]
      }
    ],
    componentList: [ // to access the current on the list this component is tied to. Use the property: props.listItem
      //SmsSenderDialog // emple component
    ],
    recordMenuList: [
      {
        title: "Record Menu",
        items: [
          // { id: 1, name: "Users", slug: "users", icon: <GroupIcon />, onClick: (e) => console.log(e) }
        ]
      }
    ],
    dispatchActions: {
      paginate: GenericAppUtils.GetPaginateFunc(CLIENT_PAGINATE_URL),
    },
    events: {
      onRenderTitle: (item) => (item.name),
      onRenderSubTitle: (item) => (item.description),
      onRenderAvatar: (item) => (null), //(item) => (FileUploaderActions.getDownloadImageUrl(item.slug, false)),
      onRenderExtraCells: (item) => ([item.id]),
      onEditClick : (item) => console.log(item),
      onShowClick : (item) => console.log(item),
      onDeleteClick : handleDestroy,
      onDeleteMultiple : (items) => console.log(items),
      onCreateClick : (e) => console.log(e),
      onStarClick: null, //(item, status) => console.log(item, status),
      onSearchChange: null,//(s) => console.log(s),
      onSearchEnterKeyDown: null, //if this property is null, it will call the default search endpoint
    }
  };

  return (
    <Fragment>
      <GenericApp appSettings={AppSettings} form={<ClientAppForm onSubmit={handleSubmit}/>} isUseDialog={false} />
    </Fragment>
  );

}

export default TenantApp;

import React from 'react';
import PropTypes from 'prop-types';
import { FastField, Field } from "formik";
import { Grid, TextField } from "@material-ui/core";
import FieldContainer from "../form-component/container/FieldContainer";

const FormikField = (props) => {
  const pr = props.formik;
  return(
    <FieldContainer {...props} >
      <FastField
        {...props}
        component={TextField}
        variant={"outlined"}
        fullWidth
        onChange={pr.handleChange(props.fieldName)}
        error={Boolean(pr.errors[props.fieldName])}
        value={pr.values && pr.values[props.fieldName]}
        // pr.touched[props.fieldName] &&
        helperText={pr.errors[props.fieldName]}
        size="small"
      />
    </FieldContainer>
  )

}

FormikField.defaultProps ={
  isSetName: true
}
FormikField.propTypes ={
  fieldName: PropTypes.string,
  formik: PropTypes.any
}

export default FormikField;

import { call, put, takeLatest } from "redux-saga/effects";
import AlertActions from "./actions/AlertActions";
import { FETCH_START, FETCH_SUCCESS } from "../../@jumbo/constants/ActionTypes";
import { GENERIC_APP_GET_RECORDS_LIST_SUCCESS } from "../action-types/GenericAppActionTypes";
import NotificationActions from "./actions/NotificationActions";
import { NOTIFICATION_UNREAD } from "../action-types/NotificationTypes";

// function* fetchSystemSettingStore(action) {
//   try{
//     yield call(SystemSettingActions.storeSystemSetting, action.payload);
//     yield GenericAppActions.refreshListCurrentGenericApp(SYSTEM_SETTING_APP_PAGINATE_URL) ;
//     yield AlertActions.showDialogSuccess('Success','');
//   }catch (e) {
//     yield AlertActions.showDialogError('Error', e);
//   }
// }
//
// function* fetchSystemSettingSearch(action) {
//   try{
//     yield GenericAppActions.searchGenericAppPage(SystemSettingActions.searchSystemSetting, action);
//   }catch (e) {
//     yield AlertActions.showDialogError('Error Searching', e);
//   }
// }

function* fetchUnreadPage(action) {
  yield put({type: FETCH_START});
  try {
    action.payload.params.page = (action.payload.params.page + 1); // our server first page is based on 1 and the frontend is based on 0
    let aCallFunc = NotificationActions.getUserNotificationsUnreadPageFunc(action.payload.url, action.payload.params);
    const ret = yield call(aCallFunc);
    yield put({ type: GENERIC_APP_GET_RECORDS_LIST_SUCCESS, payload: ret.data });
  } catch (e) {
    console.log(e);
    yield AlertActions.showDialogError('Error', e);
  } finally {
    yield put({type: FETCH_SUCCESS});
  }
}

export const NotificationSaga = [
  takeLatest(NOTIFICATION_UNREAD, fetchUnreadPage)
];

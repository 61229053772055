import React, { useContext, useEffect, useState } from "react";
import ListTableView from "./ListTableView";
import ListGridView from "./ListGridView";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Hidden, TablePagination } from "@material-ui/core";
import useStyles from "../index.style";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getContactContainerHeight } from "../../../../@jumbo/constants/AppConstants";
import AppContext from "../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";
import EmptyContactResult from "./EmptyContactResult";
import { GenericAppContext } from "../GenericAppContext";
import { GENERIC_APP_GET_RECORDS_LIST, GENERIC_APP_SET_CURRENT_PAGE_LIMIT, GENERIC_APP_SET_CURRENT_PAGE_NO } from "../../../../redux/action-types/GenericAppActionTypes";
import useQueryString from '../../../../hooks/QueryStringHook';
import { Pagination } from "@mui/material";

const GenericAppList = ({ width, viewMode, onShowContactDetail, onClickEditContact }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  let { filterType, recordList, page, pageCount, pageLimit,totalRecords } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [checkedContacts, setCheckedContacts] = useState([]);
  const [showDuplicateMsg, setShowDuplicateMsg] = useState(true);
  const appSettings = useContext(GenericAppContext);
  const qrStr = useQueryString();
  // console.log(loc?.get('id'));

  useEffect(() => {
    let aId = qrStr?.get('id') || 0;
    if((!filterType.searchText) || (aId > 0)){
      dispatch({type: GENERIC_APP_GET_RECORDS_LIST, payload: appSettings.dispatchActions.paginate(page, pageLimit, aId)});
    }
  }, [filterType, dispatch, page, pageCount, pageLimit, totalRecords, qrStr]);

  const handleCellCheckBox = (isChecked, id) => {
    if (isChecked) {
      setCheckedContacts(checkedContacts.concat(id));
    } else {
      setCheckedContacts(checkedContacts.filter(contactId => contactId !== id));
    }
  };

  const toggleDuplicateMsgShow = () => {
    setShowDuplicateMsg(!showDuplicateMsg);
  };

  const handleHeaderCheckBox = isChecked => {
    if (isChecked) {
      const ids = recordList?.map(contact => contact.id);
      updateCheckedContacts(ids);
    } else {
      updateCheckedContacts([]);
    }
  };

  const updateCheckedContacts = contactIds => {
    setCheckedContacts(contactIds);
  };

  const handlePageChange = (e,pageNo) => {
    // console.log(parseInt(pageNo) +1);
    dispatch({type: GENERIC_APP_SET_CURRENT_PAGE_NO, payload: pageNo});
  }

  const classes = useStyles({
    height: getContactContainerHeight(width, showFooter),
  });

  const handlePageLimitChange = (e) => {
    dispatch({type: GENERIC_APP_SET_CURRENT_PAGE_LIMIT, payload: parseInt(e.target.value, 10)});
  }

  function defaultLabelDisplayedRows({ from, to, count }) {
    return `(${page +1}) | ${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  return recordList?.length > 0 ? (
    <Box className={classes.inBuildAppMainContent}>
      <PerfectScrollbar className={classes.perfectScrollbarContactCon}>
        {/*{showDuplicateMsg && (*/}
        {/*  <DuplicateContactsMsg contactsList={recordList || []} toggleDuplicateMsgShow={toggleDuplicateMsgShow} />*/}
        {/*)}*/}
        {viewMode === 'table' ? (
          <ListTableView
            checkedContacts={checkedContacts}
            handleCellCheckBox={handleCellCheckBox}
            handleHeaderCheckBox={handleHeaderCheckBox}
            updateCheckedContacts={updateCheckedContacts}
            onShowContactDetail={onShowContactDetail}
            onClickEditContact={onClickEditContact}
          />
        ) : (
          <ListGridView onShowContactDetail={onShowContactDetail} onClickEditContact={onClickEditContact} />
        )}
        {/*<Pagination count={pageCount} onChange={handlePageChange} />*/}

        <Hidden smDown={true}>
          <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={pageLimit}
            onRowsPerPageChange={handlePageLimitChange}
            rowsPerPageOptions={[1,10,25,50,100, 1000000]}
            labelDisplayedRows={defaultLabelDisplayedRows}
            labelRowsPerPage={'Rows:'}
          />
        </Hidden>

        <Hidden mdUp={true}>
          <TablePagination
            // component="div"
            count={totalRecords}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={pageLimit}
            onRowsPerPageChange={handlePageLimitChange}
            // rowsPerPageOptions={[1,10,25,50,100, 1000000]}
            labelDisplayedRows={() => ''}
            labelRowsPerPage={''}
          />
        </Hidden>

      </PerfectScrollbar>
    </Box>
  ) : (
    <Box className={classes.inBuildAppMainContent}>
      <EmptyContactResult />
    </Box>
  );
};

export default GenericAppList;

GenericAppList.prototype = {
  viewMode: PropTypes.string,
  onShowContactDetail: PropTypes.func,
  onClickEditContact: PropTypes.func,
};

GenericAppList.defaultProps = {
  viewMode: 'table',
};

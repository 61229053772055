import React from 'react';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import { useSelector } from "react-redux";

const FooterLogo = ({ color, websiteUrl, ...props }) => {
  // const logoUrl = color === 'white' ? '/images/logo-white-symbol.png' : '/images/footer-logo.png';
  const { currentUser } = useSelector(({ usersReducer }) => usersReducer);
  const logoUrl = currentUser?.settings?.companyLogoUrlSmall;
  return (
    <Box className="pointer" {...props}>
      <a href={websiteUrl} target={'_blank'}>
        <CmtImage src={logoUrl} alt="logo" height={25} />
      </a>
    </Box>
  );
};

export default FooterLogo;

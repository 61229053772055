import React from "react";
import { Box, Divider, Drawer, IconButton, Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import useStyles from "../../routes/Apps/GenericApp/index.style";
import CloseIcon from "@material-ui/icons/Close";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";

const styles = {
  drawerPaper: {
    width: '70%',
  },
};

function FormContainerDrawer(props){
  const classes = useStyles();
  const intl = useIntl();
  return(
    <Drawer
      id={props.domId}
      type="temporary"
      anchor={'right'}
      open={props.isOpen}
      onClose={props.onClose}
      classes={{
        paper: props.classes.drawerPaper,
      }}
    >
      <Box
        style={{
          padding: props.padding
        }}
      >
        <Typography className={classes.inBuildAppHeaderTitle} gutterBottom={true} component="div" variant="h1">
          { props.title } {/* already being i18n */}
          {props.subTitle &&
          <Typography variant="caption" display="block" color={'textSecondary'}>
            {props.subTitle}
          </Typography>}
        </Typography>
        {/*<Box>*/}
        {/*  <IconButton onClick={props.onClose}>*/}
        {/*    <CloseIcon />*/}
        {/*  </IconButton>*/}
        {/*</Box>*/}
      </Box>
      <Divider variant={'fullWidth'} />
      <Box
        style={{
          padding: props.padding
        }}
      >
        {props.children}
      </Box>
    </Drawer>
  )
}

FormContainerDrawer.defaultProps = {
  padding: 20,
  domId: 'generic-app-dialog'
}
FormContainerDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  padding: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  domId: PropTypes.string,
}

export default withStyles(styles)(FormContainerDrawer);

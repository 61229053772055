import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

const FieldContainer = (props) => {
  return(
    <Grid item xs={props.gridSizeXs || 12} sm={props.gridSizeSm || 12} md={props.gridSize || props.gridSizeMd || 6} lg={props.gridSize || 4} xl={props.gridSize || props.gridSizeXl || 3} >
      {props.children}
    </Grid>
  )

}

FieldContainer.defaultProps ={
  rows: 4
}
FieldContainer.propTypes ={
  gridSize: PropTypes.number,
  gridSizeXs: PropTypes.number,
  gridSizeSm: PropTypes.number,
  gridSizeMd: PropTypes.number,
  gridSizeLg: PropTypes.number,
  gridSizeXl: PropTypes.number,
}

export default FieldContainer;

import { takeLatest } from "redux-saga/effects";
import TaskServiceActions from "./actions/TaskServiceActions";
import GenericAppActions from "./actions/GenericAppActions";
import { TASK_SERVICE_PAGINATE_URL } from "../../routes/Apps/TaskServiceApp";
import { TASK_SERVICE_DESTROY, TASK_SERVICE_STORE, TASK_SERVICE_UPDATE } from "../action-types/TaskServiceActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(TaskServiceActions.store, action.payload, TASK_SERVICE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(TaskServiceActions.update, action.payload, TASK_SERVICE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(TaskServiceActions.destroy, action.payload, TASK_SERVICE_PAGINATE_URL);
}

export const TaskServiceSaga = [
  takeLatest(TASK_SERVICE_STORE, fetchStore),
  takeLatest(TASK_SERVICE_UPDATE, fetchUpdate),
  takeLatest(TASK_SERVICE_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import UserCategoryActions from "./actions/UserCategoryActions";
import GenericAppActions from "./actions/GenericAppActions";
import { USER_CATEGORY_PAGINATE_URL } from "../../routes/Apps/UserCategoryApp";
import { USER_CATEGORY_DESTROY, USER_CATEGORY_STORE, USER_CATEGORY_UPDATE } from "../action-types/UserCategoryActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(UserCategoryActions.store, action.payload, USER_CATEGORY_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(UserCategoryActions.update, action.payload, USER_CATEGORY_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(UserCategoryActions.destroy, action.payload, USER_CATEGORY_PAGINATE_URL);
}

export const UserCategorySaga = [
  takeLatest(USER_CATEGORY_STORE, fetchStore),
  takeLatest(USER_CATEGORY_UPDATE, fetchUpdate),
  takeLatest(USER_CATEGORY_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import SkuActions from "./actions/SkuActions";
import GenericAppActions from "./actions/GenericAppActions";
import { SKU_PAGINATE_URL } from "../../routes/Apps/SkuApp";
import { SKU_DESTROY, SKU_STORE, SKU_UPDATE } from "../action-types/SkuActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(SkuActions.store, action.payload, SKU_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(SkuActions.update, action.payload, SKU_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(SkuActions.destroy, action.payload, SKU_PAGINATE_URL);
}

export const SkuSaga = [
  takeLatest(SKU_STORE, fetchStore),
  takeLatest(SKU_UPDATE, fetchUpdate),
  takeLatest(SKU_DESTROY, fetchDestroy),
];



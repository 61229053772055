import HttpClient from "../../../utils/HttpClient";
import { call, put } from "redux-saga/effects";
import { GENERIC_APP_GET_RECORDS_LIST, GENERIC_APP_GET_RECORDS_LIST_SUCCESS } from "../../action-types/GenericAppActionTypes";
import { FETCH_START, FETCH_SUCCESS } from "../../../@jumbo/constants/ActionTypes";
import TenantActions from "./TenantActions";
import { TENANT_PAGINATE_URL } from "../../../routes/Apps/TenantApp";
import AlertActions from "./AlertActions";

const getGenericAppRecordPage = (aUrl, aPageObj) => {
  return () => {
    let p = new URLSearchParams(aPageObj);
    return HttpClient.HttpClientWebApi().get(aUrl+'?'+p.toString());
  }
}

function* refreshListCurrentGenericApp(aUrl, aPage = 1, aLimit = 10, aId = 0){
  yield put({type: GENERIC_APP_GET_RECORDS_LIST, payload: {url: aUrl, params: {page: 0, limit: 10, aId: aId}}});
}

function* searchGenericAppPage(aCallFunc, aAction) {
  try {
    yield put({type: FETCH_START});
    const ret = yield call(aCallFunc, aAction.payload);
    yield put({type: GENERIC_APP_GET_RECORDS_LIST_SUCCESS, payload: ret.data});
  }finally {
    yield put({type: FETCH_SUCCESS});
  }
}

function* fetchServer(aCallAction, aActionPayload, aRefreshUrl ) {
  try{
    yield call(aCallAction, aActionPayload);
    yield refreshListCurrentGenericApp(aRefreshUrl) ;
    yield AlertActions.showDialogSuccess('Success','');
  }catch (e) {
    console.error(e.response.data);
    yield AlertActions.showDialogError('Error', e?.response?.data?.Message || e, 0, JSON.stringify(e.response.data));
  }
}

function* doStoreOrUpdate(aCallActionStore, aCallActionUpdate, aActionPayload, aRefreshUrl, aIsStore) {
  if(aIsStore){
    yield fetchServer(aCallActionStore, aActionPayload, aRefreshUrl);
  } else {
    yield fetchServer(aCallActionUpdate, aActionPayload, aRefreshUrl);
  }
}

export default {
  getGenericAppRecordPage,
  refreshListCurrentGenericApp,
  searchGenericAppPage,
  fetchServer,
  doStoreOrUpdate
}

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import HttpClient from "../../utils/HttpClient";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import { v1 as uuidv1 } from "uuid";

const SelectedValueField = (props) => {
  const [text, setText] = useState("");

  const handleKeyPress = (e) => {
    if(e.key === 'Delete'){
      props.onButtonClick(e);
      return;
    }

    if(e.key === 'Backspace'){
      setText('Please click on the "X" icon or press delete instead.');
    }
  }

  return(
    <TextField
      {...props}
      size="small"
      // classes={{ notchedOutline: classes.customInputLabel }}
      variant={'outlined'}
      id="outlined-adornment-password"
      type={'text'}
      fullWidth={true}
      onKeyDown={handleKeyPress}
      helperText={text}
      InputProps={{
        endAdornment:(
        <InputAdornment position="end">
        <IconButton
        aria-label="clear value"
        onClick={props.onButtonClick}
        edge="end"
        >
        <ClearIcon/>
        </IconButton>
        </InputAdornment>
        )
      }}
    />
  )
}

const AutocompleteHttp = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState();
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      HttpClient.HttpClientWebApi().get(props.lookupUrl).then(r => {
        if (active) {
          setOptions(r.data);
        }
      });
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    // const val = pr.values && pr.values[props.fieldname];
    // if (props.value) {
      // setHasDefaultValue(true);
      // HttpClient.HttpClientWebApi().get(`${props.lookupByIdUrl}?id=${val}`).then(r => {
      // console.log(props.lookupByIdUrl);
      HttpClient.HttpClientWebApi().get(props.lookupByIdUrl).then(r => {
        setValue({id: r.data.id, title: r.data.title } );
      });
    // }
  }, []);

  const handleChange = (e, a) => {
    setValue(a);
    if(props.onSetValue){
      props.onSetValue(e, a)
    }
  };

  const getDescription = (option) => {
    return  option ? `${option.title} - ${option.id}` : '';
  }

  const handleClearClick = (e) => {
    handleChange(e, null);
    setOpen(true);
  }

  return (
    <Fragment>
      {
        value ? <SelectedValueField label={props.label} value={getDescription(value)} onButtonClick={handleClearClick} /> :

          <Autocomplete
            {... props}
            key={uuidv1()}
            open={open}
            size="small"
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={getDescription}
            options={options || []}
            loading={loading}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.label}
                variant={"outlined"}
                helperText={props.helperCaption}
                error={Boolean(props.helperCaption)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {props.onButtonClick &&
                      <IconButton
                        aria-label="clear value"
                        onClick={props.onButtonClick}
                        edge="end"
                        size={'small'}
                      >
                        {props.buttonIcon || <AddIcon color={'primary'} fontSize="small"/>}
                      </IconButton>
                      }

                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
      }
    </Fragment>
  );

};


AutocompleteHttp.defaultProps = {
  isOpen: false
};

AutocompleteHttp.propTypes = {
  lookupUrl: PropTypes.string,
  lookupByIdUrl: PropTypes.string,
  onClearValue: PropTypes.func,
  onSetValue: PropTypes.func,
  onButtonClick: PropTypes.func,
  buttonIcon: PropTypes.any,
  value: PropTypes.object,
  helperCaption: PropTypes.string
};

export default AutocompleteHttp;

import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import AutocompleteMultiple from "../dropdown/AutocompleteMultiple";
import FieldContainer from "../form-component/container/FieldContainer";

const FormikAutocompleteMultiple = (props) => {
  const pr = props.formik;
  const val = pr?.values && pr.values[props.fieldName];


  const handleClearClick = (e) => {
    // handleChange(e, null);
  }

  return (
    <FieldContainer {...props} >
      <AutocompleteMultiple
        {...props}
        size="small"
        value={val}
        onChange={props.onChange}
        onClearValue={handleClearClick}
        lookupUrl={props.lookupUrl}
        lookupByIdUrl={`${props.lookupByIdUrl}?id=${val}`}
        onButtonClick={ props.onAddButtonClick }
        selectedOptions={ props.selectedOptions }
        options={props.options}
      />
    </FieldContainer>
  );
};

FormikAutocompleteMultiple.defaultProps = {
  selectedOptions: [],
  options: []
};

FormikAutocompleteMultiple.propTypes = {
  fieldName: PropTypes.string,
  formik: PropTypes.any,
  onAddButtonClick: PropTypes.func,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  options: PropTypes.array
};

export default FormikAutocompleteMultiple;

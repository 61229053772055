import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Apps from "./Apps";
import Login from "./Auth/Login";
import PrivateRoute from "../utils/PrivateRoute";
import ForgotPasswordPage from "./Auth/ForgotPassword";
import Register from "./Auth/Register";

// const RestrictedRoute = ({component: Component, ...rest}) => {
//   const {authUser} = useSelector(({auth}) => auth);
//   return (
//     <Route
//       {...rest}
//       render={props =>
//         authUser ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/signin',
//               state: {from: props.location},
//             }}
//           />
//         )
//       }
//     />
//   );
// };

const Routes = () => {
  // const {authUser} = useSelector(({auth}) => auth);
  const { currentUser } = useSelector(({ usersReducer }) => usersReducer);
  // const { currentUser } = useAuth();
  const location = useLocation();

  // }, [account, inProgress, instance, atsResponse]);

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/signin'}/>;
  }
  // else if (currentUser && location.pathname === '/signin') {
  //   return <Redirect to={'/apps'}/>;
  // }

  return (
    <React.Fragment>
      {/*<HashRouter basename="/admin" hashType={'slash'}>*/}
        <Switch>
          {/*<Route path="/sample-page" component={SamplePage}/>*/}
          <Route path="/signin" component={Login}/>
          <Route path="/signup" component={Register}/>
          <Route path="/forgot-password" component={ForgotPasswordPage}/>
          {/*<Route component={Error404}/>*/}
          {/*<UnauthenticatedTemplate>*/}
          {/*  <Typography variant="h6">*/}
          {/*    <center>Please sign-in to acquire access tokens.</center>*/}
          {/*  </Typography>*/}
          {/*</UnauthenticatedTemplate>*/}

          {/*<AuthenticatedTemplate>*/}
          <PrivateRoute path="/apps"  component={Apps}/>
          {/*  <Route path="/apps" component={Apps} />  */}
          {/*</PrivateRoute>          */}
          {/*</AuthenticatedTemplate>*/}
          {/* Catch all  */}
          <Redirect from="*" to="/apps" />
        </Switch>
      {/*</HashRouter>*/}
    </React.Fragment>
  );
};

export default Routes;

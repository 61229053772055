import React, { Fragment } from "react";
import { FieldArray, getIn } from "formik";
import { Button, Grid, IconButton, Paper, Stack } from "@mui/material";
import { TextField, Typography } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import DeleteIcon from "@material-ui/icons/Delete";
import { Add } from "@material-ui/icons";
import PropTypes from 'prop-types';
import FormGroupTitle from "../../../components/form-container/FormGroupTitle";
import moment from "moment";

const TimesheetEntriesFieldArray = (props) => {
  const myObj = { title: "", text: "" };
  const { formik } = props;
  const { values, touched, errors, handleChange, handleBlur, isValid } = formik;

  return (
    <FieldArray name="timesheetEntries">
      {({ insert, remove, push }) => {

        return (
          <Grid spacing={4}>
            <FormGroupTitle title={'Entries'} subTitle={''}/>

            {values?.timesheetEntries?.length > 0 &&
            values.timesheetEntries.map((item, index) => {

              const entryDateName = `timesheetEntries[${index}].entryDate`;
              const touchedNoteDate = getIn(touched, entryDateName);
              const errorNoteDate = getIn(errors, entryDateName);

              const noteName = `timesheetEntries[${index}].note`;
              const touchedText = getIn(touched, noteName);
              const errorNote = getIn(errors, noteName);

              const quantityName = `timesheetEntries[${index}].quantity`;
              const touchedTitle = getIn(touched, quantityName);
              const errorQuantity = getIn(errors, quantityName);

              return (
                <Paper variant={"outlined"}  style={{ marginBottom: "10px" }} >
                  <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                    <LocalizationProvider  dateAdapter={DateAdapter}>
                      <DatePicker
                        {...props}
                        fullWidth={true}
                        name={entryDateName}
                        value={item.entryDate || ''}
                        // value={pr.values && pr.values[props.fieldName]}
                        onChange={(e) => formik.setFieldValue(entryDateName, e)}
                        renderInput={(params) => <TextField {...params} variant={'outlined'} size="small" fullWidth={true} helperText={errorNoteDate} error={Boolean(errorNoteDate)}/>}
                      />
                    </LocalizationProvider>
                    <TextField
                      // component={TextField}
                      fullWidth
                      name={quantityName}
                      // fieldName={`timesheetEntries.${index}.quantity`}
                      value={item.quantity}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Quantity"}
                      size={"small"}
                      helperText={errorQuantity}
                      error={Boolean(errorQuantity)}
                      // formik={formik}

                    />
                    <TextField
                      // component={TextField}
                      fullWidth
                      name={noteName}
                      // fieldName={`timesheetEntries.${index}.text`}
                      value={item.note}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Text"}
                      size={"small"}
                      helperText={errorNote}
                      error={Boolean(errorNote)}
                      // formik={formik}
                    />

                    <IconButton aria-label="delete" onClick={() => remove(index)}>
                      <DeleteIcon color={"secondary"} />
                    </IconButton>
                    <IconButton aria-label="add" onClick={() => push(myObj)}>
                      <Add color={"primary"} />
                    </IconButton>
                  </Stack>
                </Paper>
              )}

            )}
            {/*<p>sdlfsdljfslkdj</p>*/}
            {/*<pre>{JSON.stringify(formik?.values?.timesheetEntries)}</pre>*/}

            {/*{values?.timesheetEntries?.length === 0 &&*/}
            <Button variant="outlined" startIcon={<Add />} onClick={() => push(myObj)}>
              Add
            </Button>
            {/*}*/}
          </Grid>
        );
      }}
    </FieldArray>
  );
};

TimesheetEntriesFieldArray.propTypes = {
  formik: PropTypes.any
}

export default TimesheetEntriesFieldArray;

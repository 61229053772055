import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
// import useAuth from "../../hooks/AuthHook";
import { FETCH_TENANT_LIST, UPDATE_CURRENT_USER_TENANT } from "../../redux/action-types/TenantActionTypes";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";


const TenantSwitcherFormValidation = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  gender: Yup.string().required("Required"),
});



function TenantSwitcherSelect(props){

  const [tenantId, setTenantId] = useState();
  const usersReducer = useSelector((state) => state.usersReducer);
  const dispatch = useDispatch();
  const { appContext } = useAuth();
  const hist = useHistory();

  // console.log(usersReducer.currentUser?.user?.tenantId);

  // let tenantList = usersReducer.tenantList.map(i => {
  //   return {value: i.id, text: i.name}
  // });
  //
  // const formObj = {
  //   name:'tenant-switcher-form',
  //   validation: TenantSwitcherFormValidation,
  //   fields: [
  //     { name: 'tenantId', label: 'Tenant ID', type: SELECT, fieldProps: { items: tenantList } },
  //   ]
  // }

  useEffect(() => {
    dispatch({type: FETCH_TENANT_LIST});
    if(appContext){
      setTenantId(appContext.user.tenantId);
    }
  }, [appContext]);

  const handleChange = (e, v) => {
    dispatch({type: UPDATE_CURRENT_USER_TENANT, payload: v.id});
    setTenantId(v.id);
    hist.push('/apps');
  };

  return(

    <Autocomplete
      options={usersReducer.tenantList}
      getOptionLabel={(option) => option.name}
      id="disable-close-on-select"
      variant={'outlined'}
      value={usersReducer?.tenantList?.find(v => v.id === tenantId) || {}}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label="Tenant" variant="outlined" />
      )}
    />


    // <FormControl
    //   fullWidth
    //   variant={"outlined"}
    //   // value={tenantId || ''}
    //   // defaultValue={usersReducer.currentUser?.user?.tenantId || ''}
    // >
    //   <InputLabel id="demo-simple-select-label">Tenant Switcher</InputLabel>
    //   <Select
    //     labelId="demo-simple-select-label"
    //     id="demo-simple-select"
    //     value={tenantId || ''}
    //     label="Age"
    //     onChange={handleChange}
    //   >
    //     {
    //       usersReducer.tenantList.map(i => {
    //         return <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
    //       })
    //     }
    //
    //     {/*<MenuItem value={10}>Ten</MenuItem>*/}
    //     {/*<MenuItem value={20}>Twenty</MenuItem>*/}
    //     {/*<MenuItem value={30}>Thirty</MenuItem>*/}
    //   </Select>
    //   {/*<FormHelperText>Select the desired tenant</FormHelperText>*/}
    // </FormControl>

    // <VinSystemsFormBuilder formObj={formObj} onSubmit={handleSubmit} />
  )
}

export default TenantSwitcherSelect;

import * as React from "react";
import FormikAutocompleteField from "../../formik/FormikAutocomplete";
import PropTypes from "prop-types";
import { HttpClientConfig } from "../../../utils/HttpClient";

export const UserTypeAutocomplete = (props) => {
  const lookupUrl = `${HttpClientConfig.baseURL}/UserType/Lookup`;
  const lookupByIdUrl = `${HttpClientConfig.baseURL}/UserType/LookupById`;

  const handleAddClick = (e) => {
    console.log(e);
  }

  return (
    <FormikAutocompleteField {...props} lookupUrl={lookupUrl} lookupByIdUrl={lookupByIdUrl} onAddButtonClick={handleAddClick} />
  );
};

UserTypeAutocomplete.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  formik: PropTypes.any
}

export default UserTypeAutocomplete;

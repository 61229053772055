import { takeLatest } from "redux-saga/effects";
import GenderActions from "./actions/GenderActions";
import GenericAppActions from "./actions/GenericAppActions";
import { GENDER_PAGINATE_URL } from "../../routes/Apps/GenderApp";
import { GENDER_DESTROY, GENDER_STORE, GENDER_UPDATE } from "../action-types/GenderActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(GenderActions.store, action.payload, GENDER_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(GenderActions.update, action.payload, GENDER_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(GenderActions.destroy, action.payload, GENDER_PAGINATE_URL);
}

export const GenderSaga = [
  takeLatest(GENDER_STORE, fetchStore),
  takeLatest(GENDER_UPDATE, fetchUpdate),
  takeLatest(GENDER_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import SmsHistoryActions from "./actions/SmsHistoryActions";
import GenericAppActions from "./actions/GenericAppActions";
import { SMS_HISTORY_PAGINATE_URL } from "../../routes/Apps/SmsHistoryApp";
import { SMS_HISTORY_DESTROY, SMS_HISTORY_STORE, SMS_HISTORY_UPDATE } from "../action-types/SmsHistoryActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(SmsHistoryActions.store, action.payload, SMS_HISTORY_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(SmsHistoryActions.update, action.payload, SMS_HISTORY_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(SmsHistoryActions.destroy, action.payload, SMS_HISTORY_PAGINATE_URL);
}

export const SmsHistorySaga = [
  takeLatest(SMS_HISTORY_STORE, fetchStore),
  takeLatest(SMS_HISTORY_UPDATE, fetchUpdate),
  takeLatest(SMS_HISTORY_DESTROY, fetchDestroy),
];



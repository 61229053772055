import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getMessaging, onMessage, getToken  } from "firebase/messaging";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const firebaseAuth = getAuth();
export const firebaseDb = getFirestore();
export const msg = getMessaging(firebaseApp);

// onMessage(msg, (payload) => {
//   console.log('Message received. ', payload);
// });


export const notesRef = collection(firebaseDb, 'notes');

export default firebaseApp;

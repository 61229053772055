import { call, takeLatest } from "redux-saga/effects";
import AlertActions from "./actions/AlertActions";
import GenericAppActions from "./actions/GenericAppActions";
import { SYSTEM_SETTING_USER_SEARCH, SYSTEM_SETTING_USER_STORE } from "../action-types/SystemSettingUserTypes";
import { SYSTEM_SETTING_USER_APP_PAGINATE_URL } from "../../routes/Apps/SystemSettingUserApp";
import SystemSettingUserActions from "./actions/SystemSettingUserActions";

function* fetchSystemSettingStore(action) {
  try{
    yield call(SystemSettingUserActions.storeSystemSetting, action.payload);
    yield GenericAppActions.refreshListCurrentGenericApp(SYSTEM_SETTING_USER_APP_PAGINATE_URL) ;
    yield AlertActions.showDialogSuccess('Success','');
  }catch (e) {
    yield AlertActions.showDialogError('Error', e);
  }
}

function* fetchSystemSettingSearch(action) {
  try{
    yield GenericAppActions.searchGenericAppPage(SystemSettingUserActions.searchSystemSetting, action);
  }catch (e) {
    yield AlertActions.showDialogError('Error Searching', e);
  }
}

export const SystemSettingUserSaga = [
  takeLatest(SYSTEM_SETTING_USER_STORE, fetchSystemSettingStore),
  takeLatest(SYSTEM_SETTING_USER_SEARCH, fetchSystemSettingSearch),
];

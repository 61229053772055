import { call, put, takeLatest } from "redux-saga/effects";
import { GENERIC_APP_GET_RECORDS_LIST, GENERIC_APP_GET_RECORDS_LIST_SUCCESS, GENERIC_APP_UPDATE_OR_STORE } from "../action-types/GenericAppActionTypes";
import GenericAppActions from "./actions/GenericAppActions";
import { FETCH_START, FETCH_SUCCESS } from "../../@jumbo/constants/ActionTypes";
import AlertActions from "./actions/AlertActions";


function* fetchGenericAppPage(action) {
  yield put({type: FETCH_START});
  try {
    action.payload.params.page = (action.payload.params.page + 1); // our server first page is based on 1 and the frontend is based on 0
    let aCallFunc = GenericAppActions.getGenericAppRecordPage(action.payload.url, action.payload.params);
    const ret = yield call(aCallFunc);
    yield put({type: GENERIC_APP_GET_RECORDS_LIST_SUCCESS, payload: ret.data});
  }catch (e) {
    console.log(e);
    yield AlertActions.showDialogError('Error', e?.response?.data?.Message || e);
  }finally {
    yield put({type: FETCH_SUCCESS});
  }
}


export const GenericAppSaga = [
  takeLatest(GENERIC_APP_GET_RECORDS_LIST, fetchGenericAppPage),GENERIC_APP_UPDATE_OR_STORE

];

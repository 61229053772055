import React from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PermissionGuard from "../../../components/security/PermissionGuard";

const useStyles = makeStyles(theme => ({
  sideNavMenu: {
    position: 'relative',
  },
}));

const CmtVertical = props => {
  const { menuItems } = props;
  const classes = useStyles();
  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      {menuItems.map((item, index) => {

        if(item.requiredPermissions.length > 0){
          switch (item.type) {
            case 'section':
              return <PermissionGuard requiredPermissions={item.requiredPermissions}><NavSection {...item} key={index} /></PermissionGuard>;
            case 'collapse':
              return <PermissionGuard requiredPermissions={item.requiredPermissions}><NavCollapse {...item} key={index} /></PermissionGuard>;
            case 'item':
              return <PermissionGuard requiredPermissions={item.requiredPermissions}><NavMenuItem {...item} key={index} /></PermissionGuard>;
            default:
              return null;
          }
        }

        switch (item.type) {
          case 'section':
            return <NavSection {...item} key={index} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} />;
          case 'item':
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
};

export default CmtVertical;

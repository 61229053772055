import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { Grid, MenuItem, Select, TextField } from "@material-ui/core";
import FieldContainer from "../form-component/container/FieldContainer";

const FormikSelect = (props) => {
  const pr = props.formik;
  return(
    <FieldContainer {...props} >
      <Field
        {...props}
        variant={"outlined"}
        fullWidth
        size="small"
        select={true}
        component={TextField}
        defaultValue={''}
        onChange={pr.handleChange(props.fieldName)}
        error={Boolean(pr.errors[props.fieldName])}
        helperText={pr.errors[props.fieldName]}
      >
        {
          props.items.map((i, idx) => (
            <MenuItem key={idx+'-'+i.value} value={i.value}>{i.text}</MenuItem>
          ))
        }
      </Field>
    </FieldContainer>
  )

}


FormikSelect.defaultProps ={
  items: []
}

FormikSelect.propTypes ={
  fieldName: PropTypes.string,
  formik: PropTypes.any,
  items: PropTypes.array
}

export default FormikSelect;

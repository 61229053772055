import React from "react";
import GenericApp from "../GenericApp";
import SystemSettingUserAppForm from "./SystemSettingAppForm";
import SettingsIcon from "@material-ui/icons/Settings";
import { useDispatch } from "react-redux";
import { SYSTEM_SETTING_USER_SEARCH } from "../../../redux/action-types/SystemSettingUserTypes";
import GenericAppUtils from "../GenericApp/GenericAppUtils";

export const SYSTEM_SETTING_USER_APP_PAGINATE_URL = '/SystemSettingUser/Paginate';

function SystemSettingUserApp(props) {

  const dispatch = useDispatch();

  // const handleSearch = (s) => {
  //   dispatch({ type: SYSTEM_SETTING_USER_SEARCH, payload: s});
  // }

  const AppSettings = {
    name: "user-app",
    title: "User Settings",
    subTitle: "App",
    searchTitle: "Search User Settings",
    notFoundTitle: 'No records found',
    isCreate: false,
    isRead: true,
    isUpdate: true,
    isDelete: false,
    urls: {
      indexFrontend: '/apps/user-settings'
    },
    createButton: {
      title: "Create New",
      icon: null
    },
    headers: [
      'Name',
      'Group'
    ],
    menuList: [
      {
        title: "Menu",
        items: [
          { id: 1, name: "User Settings", slug: "user-settings", icon: <SettingsIcon />, onClick: (e) => console.log(e) }
        ]
      }
    ],
    recordMenuList: [
      {
        title: "Record Menu",
        items: [
          // { id: 1, name: "Users", slug: "users", icon: <GroupIcon />, onClick: (e) => console.log(e) }
        ]
      }
    ],
    dispatchActions: {
      paginate: GenericAppUtils.GetPaginateFunc(SYSTEM_SETTING_USER_APP_PAGINATE_URL),
    },
    events: {
      onRenderTitle: (item) => (item.name),
      onRenderSubTitle: (item) => (item.value),
      onRenderAvatar: (item) => (item.name),
      onRenderExtraCells: (item) => ([item.group]),
      onEditClick : (item) => console.log(item),
      onShowClick : (item) => console.log(item),
      onDeleteClick : (item) => console.log(item),
      onDeleteMultiple : (items) => console.log(items),
      onCreateClick : (e) => console.log(e),
      onStarClick: null, //(item, status) => console.log(item, status),
      onSearchChange: null,//(s) => console.log(s),
      onSearchEnterKeyDown: null, //if this property is null, it will call the default search endpoint
    }
  };

  return (
    <GenericApp appSettings={AppSettings} form={<SystemSettingUserAppForm/>} isUseDialog={false} />
  );

}

export default SystemSettingUserApp;

import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";

const useAuth = () => {
  const [user, setUser] = useState();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated(account);


  useEffect(() => {
    setUser(account);
  }, [account, isAuthenticated]);

  return account;
}

export default useAuth;

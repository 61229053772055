import HttpClient from "../../../utils/HttpClient";

const storeSystemSetting = (obj) => {
  return HttpClient.HttpClientWebApi().put(`/SystemSettingUser/UpdateValue/${obj.id}`, {value: obj.value});
}

const searchSystemSetting = (obj) => {
  let p = new URLSearchParams({ s: obj });
  return HttpClient.HttpClientWebApi().get(`/SystemSettingUser/Search?${p.toString()}`);
}

export default {
  storeSystemSetting,
  searchSystemSetting
}

import * as React from "react";
import PropTypes from "prop-types";
import HttpClient, { HttpClientConfig } from "../../../utils/HttpClient";
import FormikAutocompleteMultiple from "../../formik/FormikAutocompleteMultiple";
import { useEffect } from "react";

export const PermissionRoleAutocompleteMultiple = (props) => {
  const lookupUrl = `${HttpClientConfig.baseURL}/PermissionResource/Lookup`;
  const lookupByIdUrl = `${HttpClientConfig.baseURL}/PermissionResource/LookupById`;
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    HttpClient.HttpClientWebApi().get(lookupUrl).then(r => {
      setOptions(r.data);
      handleOnGetSelectedValues(r.data);
    });

  }, []);

  const handleAddClick = (e) => {
    console.log(e);
  }

  const handleChange = (e, a) => {
    const d = a?.map(a => ({ roleId: a.data.roleId, permissionId:  a.id}) ); // change this to adapt the current model
    props.formik.setFieldValue(props.fieldName, d);
    setSelectedOptions(a);
  };

  const handleOnGetSelectedValues = (e) => {
    // This method must return an object of this shape: { title: string, id: number, data: any };
    const a = props?.formik?.values?.permissionRoles?.map(o => { return { title: o.permission.name, id: o.permission.id, data: o }}); // change this to adapt the current model
    setSelectedOptions(a);
  }

  return (
    <FormikAutocompleteMultiple
      {...props}
      onAddButtonClick={handleAddClick}
      options={options}
      selectedOptions={selectedOptions}
      onChange={handleChange}
    />
  );
};

PermissionRoleAutocompleteMultiple.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  formik: PropTypes.any
}

export default PermissionRoleAutocompleteMultiple;

import HttpClient from "../../../utils/HttpClient";

const PREFIX = '/Timesheet';


const store = (aObj) => {
  return HttpClient.HttpClientWebApi().post(`${PREFIX}/Store`, aObj);
}

const storeTimeEntry = (aObj) => {
  return HttpClient.HttpClientWebApi().post(`${PREFIX}/TimeEntry`, aObj);
}

const listUserTimeEntry = (aObj) => {
  return HttpClient.HttpClientWebApi().post(`${PREFIX}/GetTimeEntryByPeriod`, aObj);
}

const listUserTimeEntrySummary = (aObj) => {
  return HttpClient.HttpClientWebApi().post(`${PREFIX}/GetTimeEntrySummaryByPeriod`, aObj);
}

const updateUserTimeEntry = (aObj) => {
  return HttpClient.HttpClientWebApi().put(`${PREFIX}/UpdateTimesheetEntry/${aObj.id}`, aObj);
}



const update = (aObj) => {
  return HttpClient.HttpClientWebApi().put(`${PREFIX}/Update/${aObj.id}`, aObj);
}

const destroy = (aObj) => {
  return HttpClient.HttpClientWebApi().delete(`${PREFIX}/Destroy/${aObj.id}`);
}

export default {
  store,
  update,
  destroy,
  storeTimeEntry,
  listUserTimeEntry,
  listUserTimeEntrySummary,
  updateUserTimeEntry
}

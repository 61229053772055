import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import { useSelector } from "react-redux";
import FileUploaderActions from "../../../../redux/sagas/actions/FileUploaderActions";
import { APPS_URL } from "../../../../consts/UrlContants";

const Logo = ({ color, ...props }) => {
  const usersReducer = useSelector((state) => state.usersReducer);
  const imgUrl = FileUploaderActions.getDownloadImageUrl(usersReducer?.currentUser?.tenant?.avatarUrlSmall, false)

  // const logoUrl = color === 'white' ? '/images/logo-white.png' : '/images/logo.png';
  // const logoSymbolUrl = color === 'white' ? '/images/logo-white-symbol.png' : '/images/logo-symbol.png';

  const logoUrl = imgUrl;//color === 'white' ? '/images/logo-white.png' : '/images/logo.png';
  const logoSymbolUrl = imgUrl;//color === 'white' ? '/images/logo-white-symbol.png' : '/images/logo-symbol.png';

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to={APPS_URL}>
          <CmtImage src={logoUrl} alt="logo" height={'50'} />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to={APPS_URL}>
          <CmtImage src={logoSymbolUrl} alt="logo"/>
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;

import React, { Fragment } from "react";
import { FieldArray, getIn } from "formik";
import { Button, Grid, IconButton, Paper, Stack } from "@mui/material";
import { TextField, Typography } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import DeleteIcon from "@material-ui/icons/Delete";
import { Add } from "@material-ui/icons";
import PropTypes from 'prop-types';
import FormGroupTitle from "../../../components/form-container/FormGroupTitle";

const ContactDevicesFieldArray = (props) => {
  const myObj = { name: "", email: "", cellphone: "", phone: "" };
  const { formik } = props;
  const { values, touched, errors, handleChange, handleBlur, isValid } = formik;
  const arr = values.contactDevices || [];
  const arrLength = values?.contactDevices?.length || 0;
  const arrName = 'contactDevices';
  const title = 'Devices';

  return (
    <FieldArray name={arrName}>
      {({ insert, remove, push }) => {

        return (
          <Grid spacing={4}>
            <FormGroupTitle title={title} subTitle={''}/>

            {arrLength > 0 && arr.map((item, index) => {

              const namePrefix = `${arrName}[${index}]`;

              const titleName = `${namePrefix}.name`;
              const touchedTitle = getIn(touched, titleName);
              const errorTitle = getIn(errors, titleName);

              const emailName = `${namePrefix}.email`;
              const touchedEmail = getIn(touched, emailName);
              const errorEmail = getIn(errors, emailName);

              const cellphoneName = `${namePrefix}.cellphone`;
              const touchedCellphone = getIn(touched, cellphoneName);
              const errorCellphone = getIn(errors, cellphoneName);

              const phoneName = `${namePrefix}.phone`;
              const touchedPhone = getIn(touched, phoneName);
              const errorPhone = getIn(errors, phoneName);

              const tokenName = `${namePrefix}.token`;
              const touchedToken = getIn(touched, tokenName);
              const errorToken = getIn(errors, tokenName);


              return (
                <Paper variant={"outlined"}  style={{ marginBottom: "10px" }} >
                  <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                    <TextField
                      fullWidth
                      name={titleName}
                      value={item.name}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Name"}
                      size={"small"}
                      autoComplete={false}
                    />
                    <TextField
                      fullWidth
                      name={emailName}
                      value={item.email}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Email"}
                      size={"small"}
                      autoComplete={false}
                      error={Boolean(errorEmail)}
                      helperText={errorEmail}
                    />
                    <TextField
                      fullWidth
                      name={cellphoneName}
                      value={item.cellphone}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Cellphone"}
                      size={"small"}
                      autoComplete={false}
                    />
                    <TextField
                      fullWidth
                      name={phoneName}
                      value={item.phone}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Phone"}
                      size={"small"}
                      autoComplete={false}
                    />
                    <TextField
                      fullWidth
                      name={tokenName}
                      value={item.token}
                      onChange={handleChange}
                      variant={"outlined"}
                      label={"Token"}
                      size={"small"}
                      autoComplete={false}
                    />


                    <IconButton aria-label="delete" onClick={() => remove(index)}>
                      <DeleteIcon color={"secondary"} />
                    </IconButton>
                    <IconButton aria-label="add" onClick={() => push(myObj)}>
                      <Add color={"primary"} />
                    </IconButton>
                  </Stack>
                </Paper>
              )}

            )}
            {/*<p>sdlfsdljfslkdj</p>*/}
            {/*<pre>{JSON.stringify(formik?.values?.organizationNotes)}</pre>*/}

            {arrLength === 0 &&
            <Button variant="outlined" startIcon={<Add />} onClick={() => push(myObj)}>
              Add
            </Button>
            }
          </Grid>
        );
      }}
    </FieldArray>
  );
};

ContactDevicesFieldArray.propTypes = {
  formik: PropTypes.any
}

export default ContactDevicesFieldArray;

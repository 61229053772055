import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { TEXT, DATE_TIME, CURRENCY, INTEGER, TEXT_AREA, TEXT_DISABLED, COMPONENT, FILE_UPLOAD_DROPZONE, CHECKBOX } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";
import ContactDevicesFieldArray from "./ContactDevicesFieldArray";
import SmsSenderDialog from "../../../components/dialog/SmsSenderDialog";

const FormValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email(),
  contactDevices: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email(),
    })
  )
});

const formObj = {
  name:'contact-app-form',
  validation: FormValidation,
  groups: [
    {
      title: "Main",
      subTitle: "",
      fields: [
        { name: "name", label: "Name", type: TEXT, fieldProps: {} },
        { name: "alias", label: "Alias", type: TEXT, fieldProps: {} },
        { name: "phone", label: "Phone", type: TEXT, fieldProps: {} },
        { name: "cellphone", label: "Cellphone", type: TEXT, fieldProps: {} },
        { name: "email", label: "Email", type: TEXT, fieldProps: {} },
        { name: "profilePictureUrl", label: "Profile Picture Url", type: FILE_UPLOAD_DROPZONE, fieldProps: {} }
      ]
    },
    {
      title: "Address",
      fields: [
        { name: "address", label: "Address", type: TEXT, fieldProps: {} },
        { name: "addressNo", label: "Address No", type: TEXT, fieldProps: {} },
        { name: "city", label: "City", type: TEXT, fieldProps: {} },
        { name: "province", label: "State", type: TEXT, fieldProps: {} },
        { name: "zipCode", label: "Zip", type: TEXT, fieldProps: {} },
        { name: "country", label: "Country", type: TEXT, fieldProps: {} }
      ]
    },
    {
      title: "Other",
      subTitle: "",
      fields: [
        { name: "description", label: "Description", type: TEXT, fieldProps: {} },
        { name: "code", label: "Code", type: TEXT, fieldProps: {} },
        { name: "statusMessage", label: "Status Message", type: TEXT, fieldProps: {} },
      ]
    },
    {
      title: "Opt-In",
      subTitle: "",
      fields: [
        { name: "optInSms", label: "SMS", type: CHECKBOX, fieldProps: { } },
        { name: "optInEmail", label: "Email", type: CHECKBOX, fieldProps: {} },
        { name: "optInMail", label: "Mail", type: CHECKBOX, fieldProps: {} },
        { name: "optInPushNotification", label: "Push Notification", type: CHECKBOX, fieldProps: {} },
      ]
    },
    {
      title: "Social",
      fields: [
        { name: "website", label: "Website", type: TEXT, fieldProps: {} },
        { name: "facebook", label: "Facebook", type: TEXT, fieldProps: {} },
        { name: "instagram", label: "Instagram", type: TEXT, fieldProps: {} },
        { name: "linkedIn", label: "Linked In", type: TEXT, fieldProps: {} },
        { name: "twitter", label: "Twitter", type: TEXT, fieldProps: {} }
      ]
    },
    {
      title: "Biography",
      fields: [
        { name: "biography", label: "Biography", type: TEXT_AREA, fieldProps: { gridSize: 12 } }
      ]
    },
    {
      title: "Info",
      fields: [
        { name: "createdDate", label: "Created", type: TEXT_DISABLED, fieldProps: {} },
        { name: "modifiedDate", label: "Modified", type: TEXT_DISABLED, fieldProps: {} },
        { name: "deletedDate", label: "Deleted", type: TEXT_DISABLED, fieldProps: {} }
      ]
    }
  ],
  record: null
}

const ContactAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState( currentRecord);

  const handleExtraFields = (formik) => {
    return(
      <Fragment>
        <ContactDevicesFieldArray formik={formik}/>
      </Fragment>
      )
  }

  return (
    <GenericAppFormBuilder  currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} onAddExtraFields={handleExtraFields} />
  )
}

ContactAppForm.propTypes = {
  onSubmit: PropTypes.func
}

export default ContactAppForm;

import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "../App";
import { PublicClientApplication } from "@azure/msal-browser";
import { isDevelopment, loginRequest } from "../AppConfig";
import { firebaseAuth } from '../config/firebase.conf';

const pca = new PublicClientApplication(loginRequest);
const accounts = pca.getAllAccounts();

async function getMsalToken() {
  let token = null;
  if (accounts.length > 0) {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    // token = await msalInstance.acquireTokenSilent(request);
    token = await msalInstance.acquireTokenSilent(request).then((response) => {
      // console.log(response);
      return response;
    }).catch((e) => {
      if (e instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(request);
      }
    });
  }

  return token?.accessToken;
}
export const HttpClientConfig = {
  headers: { 'Content-Type': 'application/json' },
  baseURL: isDevelopment() ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD
};

export const HttpClientConfigFileUploader = {
  headers: { 'Content-Type': 'application/json' },
  baseURL: isDevelopment() ? process.env.REACT_APP_BASE_URL_FILE_SERVICE_DEV : process.env.REACT_APP_BASE_URL_FILE_SERVICE_PROD
};



const HttpClientMain = (aCfg) => {
  const ax = axios.create(aCfg);

  // console.log('HttpClientMain');
  ax.interceptors.request.use(async function(config) {
    // console.log(firebaseAuth.currentUser);
    const t = await firebaseAuth?.currentUser?.getIdToken(false);
    // console.log('token',t);
    if (t){
      config.headers.Authorization = 'Bearer ' + t;
    }
    return config;
    // await getMsalToken().then(t => {
    //   if (t){
    //     config.headers.Authorization = 'Bearer ' + t;
    //   }
    //   return config;
    // }).catch(e => {
    //   console.error(e);
    //   return Promise.reject(e);
    // });
  }, function(error) {
    console.error(error);
    return Promise.reject(error);
  });

  return ax;
}

const HttpClientWebApi = () => {
  return HttpClientMain(HttpClientConfig)
}

const HttpClientFileUploader = () => {
  return HttpClientMain(HttpClientConfigFileUploader)
}

export default {
  HttpClientMain,
  HttpClientWebApi,
  HttpClientFileUploader
};

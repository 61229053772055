import React, { Fragment, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../index.style";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import CmtList from "../../../../@coremat/CmtList";
import List from "@material-ui/core/List";
import PropTypes from "prop-types";
import { setFilterType } from "../../../../redux/actions/ContactApp";
import ItemCell from "./ItemCell";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withWidth } from "@material-ui/core";
import { getAppSidebarHeight } from "../../../../@jumbo/constants/AppConstants";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AppContext from "../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";
import LabelCell from "./LabelCell";
import { GenericAppContext } from "../GenericAppContext";

const Sidebar = ({ onClickCreateContact, width }) => {
  const { isSideBarCollapsed, labelsList, filterType, contactsList } = useSelector(({ contactApp }) => contactApp);
  const { showFooter } = useContext(AppContext);
  const appSettings = useContext(GenericAppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getContactCounts());
  }, [dispatch, contactsList]);

  useEffect(() => {
    // dispatch(getLabelsList());
  }, [dispatch]);

  const onChangeFolder = folder => {
    dispatch(
      setFilterType({
        selectedFolder: folder,
        selectedLabel: '',
        searchText: '',
      }),
    );
  };

  const onChangeLabel = label => {
    dispatch(
      setFilterType({
        selectedFolder: '',
        selectedLabel: label,
        searchText: '',
      }),
    );
  };

  const classes = useStyles({
    isCollapsed: isSideBarCollapsed,
    height: getAppSidebarHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      {appSettings.isCreate &&
      <Box className={classes.inBuildAppSidebarHeader}>
        <Button className={classes.addTaskBtn} variant="contained" color="primary" onClick={(e) => onClickCreateContact(e)}>
          {appSettings.createButton.icon || <PersonAddIcon />}
          <Box component="span" className="add-task-btn-text">
            {appSettings.createButton.title}
          </Box>
        </Button>
      </Box>
      }

      <PerfectScrollbar className={classes.perfectScrollbarContactSidebar}>
        <List component="nav" className={classes.appNav}>

          {appSettings.menuList.map(i => {
            return(
              <Fragment key={i.title}>
                <ListItem component="div" className={classes.appNavHeaderItem}>
                  <Box component="span" className={classes.appNavHeaderItemText}>
                    {i.title}
                  </Box>
                </ListItem>
                <CmtList
                  data={i.items}
                  renderRow={(item, index) => (
                    <ItemCell
                      key={index}
                      item={item}
                      classes={classes}
                      selectedItem={filterType.selectedFolder}
                      onChange={item.onClick}
                    />
                  )}
                />
              </Fragment>
            )
          })}

          <CmtList
            data={labelsList}
            renderRow={(item, index) => (
              <LabelCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterType.selectedLabel}
                onChange={onChangeLabel}
              />
            )}
          />

          {/*<AddLabel />*/}

          {/*<MoreOptions classes={classes} contactsList={contactsList} />*/}
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(Sidebar);

Sidebar.prototype = {
  onClickCreateContact: PropTypes.func,
};

import React, { useState } from "react";
import * as Yup from "yup";
import { TEXT, DATE_TIME, CURRENCY, INTEGER, TEXT_AREA } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";

const FormValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
});

const formObj = {
  name:'audit-logs-app-form',
  validation: FormValidation,
  groups: [
    {
      title: 'Main',
      subTitle: '',
      fields: [
        { name: 'userId', label: 'User Id', type: TEXT, fieldProps: {} },
        { name: 'type', label: 'Type', type: TEXT, fieldProps: {} },
        { name: 'tableName', label: 'Table Name', type: TEXT, fieldProps: {} },
        { name: 'dateTime', label: 'Date Time', type: TEXT, fieldProps: {} },
        { name: 'oldValues', label: 'Old Values', type: TEXT_AREA, fieldProps: { gridSize: 12 } },
        { name: 'newValues', label: 'New Values', type: TEXT_AREA, fieldProps: { gridSize: 12 } },
        { name: 'affectedColumns', label: 'Affected Columns', type: TEXT_AREA, fieldProps: { gridSize: 12 } },
        { name: 'primaryKey', label: 'Primary Key', type: TEXT, fieldProps: {} },
      ]
    }
  ],
  record: null
}

const AuditResourceAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState( currentRecord);

  // const handleExtraFields = (formik) => {
  //   return(
  //     <FormikFileUploadField formik={formik} fieldName={'slug'} isPreviewOnly={true} isPublic={false} label={'Slug'}/>
  //   )
  // }

  return (
    <GenericAppFormBuilder  currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} />
  )
}

AuditResourceAppForm.propTypes = {
  onSubmit: PropTypes.func
}

export default AuditResourceAppForm;

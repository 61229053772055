import { takeLatest } from "redux-saga/effects";
import StyleActions from "./actions/StyleActions";
import GenericAppActions from "./actions/GenericAppActions";
import { STYLE_PAGINATE_URL } from "../../routes/Apps/StyleApp";
import { STYLE_DESTROY, STYLE_STORE, STYLE_UPDATE } from "../action-types/StyleActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(StyleActions.store, action.payload, STYLE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(StyleActions.update, action.payload, STYLE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(StyleActions.destroy, action.payload, STYLE_PAGINATE_URL);
}

export const StyleSaga = [
  takeLatest(STYLE_STORE, fetchStore),
  takeLatest(STYLE_UPDATE, fetchUpdate),
  takeLatest(STYLE_DESTROY, fetchDestroy),
];



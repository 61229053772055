import React, { useState } from "react";
import * as Yup from "yup";
import { TEXT, DATE_TIME, CURRENCY, INTEGER, CHECKBOX, TEXT_AREA } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";

const FormValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email()
});

const formObj = {
  name:'device-app-form',
  validation: FormValidation,
  groups: [
    {
      title: 'Main',
      subTitle: '',
      fields: [
        { name: "name", label: "Name", type: TEXT, fieldProps: {} },
        { name: "description", label: "Description", type: TEXT, fieldProps: {} },
        { name: "phone", label: "Phone", type: TEXT, fieldProps: {} },
        { name: "cellphone", label: "Cellphone", type: TEXT, fieldProps: {} },
        { name: "email", label: "Email", type: TEXT, fieldProps: {} },
        { name: "pushNotificationToken", label: "Push Token", type: TEXT_AREA, fieldProps: { gridSize: 12 } },
      ]
    },
    {
      title: "Opt-In",
      subTitle: "",
      fields: [
        { name: "optInSms", label: "SMS", type: CHECKBOX, fieldProps: { } },
        { name: "optInEmail", label: "Email", type: CHECKBOX, fieldProps: {} },
        { name: "optInMail", label: "Mail", type: CHECKBOX, fieldProps: {} },
        { name: "optInPushNotification", label: "Push Notification", type: CHECKBOX, fieldProps: {} },
      ]
    }
  ],
  record: null
}

const DeviceAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState( currentRecord);

  // const handleExtraFields = (formik) => {
  //   return(
  //     <FormikFileUploadField formik={formik} fieldName={'slug'} isPreviewOnly={true} isPublic={false} label={'Slug'}/>
  //   )
  // }

  return (
    <GenericAppFormBuilder  currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} />
  )
}

DeviceAppForm.propTypes = {
  onSubmit: PropTypes.func
}

export default DeviceAppForm;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MessageIcon from '@material-ui/icons/Message';
import ShareIcon from '@material-ui/icons/Share';
import EmailIcon from '@material-ui/icons/Email';
import CakeIcon from '@material-ui/icons/Cake';
import CmtMediaObject from '../../../../../../@coremat/CmtMediaObject';
import CmtAvatar from '../../../../../../@coremat/CmtAvatar';
import { getDateElements } from '../../../../../utils/dateHelper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Avatar, fade, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import NotificationActions from "../../../../../../redux/sagas/actions/NotificationActions";
import Button from "@material-ui/core/Button";
import ImageIcon from "@material-ui/icons/Image";

const useStyles = makeStyles(theme => ({
  feedItemRoot: {
    padding: '10px 0',
    position: 'relative',
    borderBottom: `1px solid ${fade(theme.palette.common.dark, 0.035)}`,
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
    '& .Cmt-media-image': {
      alignSelf: 'flex-start',
      width: 56,
    },
    '& .Cmt-media-body': {
      width: 'calc(100% - 56px)',
      flex: 'inherit',
    },
  },
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer',
  },
}));

const icons = {
  POSTING: <MessageIcon style={{ color: '#836AFF' }} />,
  SHARED_POST: <ShareIcon style={{ color: '#0795F4' }} />,
  INVITATION: <EmailIcon style={{ color: '#00C4B4' }} />,
  BIRTHDAY: <CakeIcon style={{ color: '#EF933C' }} />,
};





  // <Typography component="div" variant="h5" className={classes.titleRoot}>
  //   <Box component="span" color="primary.main">
  //     {item.title}
  //   </Box>
  //   {/*<Box component="span" ml={1}>*/}
  //   {/*  has recently posted an*/}
  //   {/*</Box>*/}
  //   <Box component="span" ml={1}>
  //     {item.description}
  //   </Box>
  //   <Box component="span" ml={1}>
  //     {item.description}
  //   </Box>
  //
  //   <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
  //     <Box fontSize={16} clone>
  //       {/*{icons[item.type]}*/}
  //     </Box>
  //     <Box ml={2}>{item.title}</Box>
  //   </Box>
  // </Typography>
// );

// const getSharedContent = (item, classes) => (
//   <Typography component="div" variant="h5" className={classes.titleRoot}>
//     <Box component="span" color="primary.main">
//       {item.title}
//     </Box>
//     <Box component="span" ml={1}>
//       has shared
//     </Box>
//     <Box component="span" ml={1} color="primary.main">
//       {`${item.metaData.post.owner.name}'s`}
//     </Box>
//     <Box component="span" ml={1}>
//       post.
//     </Box>
//   </Typography>
// );
//
// const getBirthdayContent = (item, classes) => (
//   <Typography component="div" variant="h5" className={classes.titleRoot}>
//     <Box component="span" color="blue">
//       {item.title}
//     </Box>
//     <Box component="span" ml={1}>
//       has birthday today.
//     </Box>
//   </Typography>
// );

// const getInvitationContent = (item, classes) => (
//   <Typography component="div" variant="h5" className={classes.titleRoot}>
//     <Box component="span" color="blue">
//       {item.metaData.user.name}
//     </Box>
//     <Box component="span" ml={1}>
//       has sent you a group invitation for
//     </Box>
//     <Box component="span" color="blue" ml={1}>
//       {item.metaData.group.name}
//     </Box>
//   </Typography>
// );

const NotificationItem = (props) => {
  const classes = useStyles();
  const { item } = props;
  const hist = useHistory();

  const handleNotificationClick = (item) => {
    return `/apps/notifications?id=${item.id}`;
  }

  const handleSetReadAt = (e, item) => {
    // console.log(item);

  }

  const getContent = (item, classes, aUrl) => (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <CmtAvatar size={40} src={item.title} alt={item.title} />
        </ListItemAvatar>

        <ListItemText
          primary={
            <Typography

              color="primary"
            >
              <Button variant={'link'} onCLick={handleSetReadAt} >{item.title}</Button>
            </Typography>
          }
          secondary={
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="caption"
              color="text"
            >
              {item.description}
            </Typography>
          }
        />
      </ListItem>
    </List>
  );

  const getTitle = (item, classes) => {
    return getContent(item, classes, handleNotificationClick(item));
    // switch (item.type) {
    //   case 'POSTING':
    //     return getPostContent(item, classes);
    //   case 'SHARED_POST':
    //     return getSharedContent(item, classes);
    //   case 'INVITATION':
    //     return getInvitationContent(item, classes);
    //   case 'BIRTHDAY':
    //     return getBirthdayContent(item, classes);
    //   default:
    //     return '';
    // }
  };

  const getSubTitle = () => (
    <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
      <Box fontSize={16} clone>
        {/*{icons[item.type]}*/}
      </Box>
      <Box ml={2}>{item.title}</Box>
    </Box>
  );

  return (
    <Box className={classes.feedItemRoot}>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={<Button variant="text" onClick={(e) => props.onNotificationClicked(e, item)}>{item.title}</Button>} secondary="Jan 9, 2014" />
        </ListItem>
      </List>
      {/*<CmtMediaObject*/}
      {/*  avatarPos="center"*/}
      {/*  // avatar={<CmtAvatar size={40} src={item.title} alt={item.title} />}*/}
      {/*  title={getTitle(item, classes)}*/}
      {/*  // subTitle={getSubTitle()}*/}
      {/*/>*/}
    </Box>
  );
};

export default NotificationItem;

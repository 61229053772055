import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { CHECKBOX, COMPONENT, DATE, FILE_UPLOAD_DROPZONE, TEXT, TEXT_AREA, TEXT_DISABLED } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";
import GenderAutocomplete from "../../../components/form-component/autocomplete/GenderAutocomplete";
import UserLabelAutocompleteMultiple from "../../../components/form-component/autocomplete/UserLabelAutocompleteMultiple";
import UserNotesFieldArray from "./UserNotesFieldArray";
import UserTypeAutocomplete from "../../../components/form-component/autocomplete/UserTypeAutocomplete";
import UserStatusAutocomplete from "../../../components/form-component/autocomplete/UserStatusAutocomplete";
import UserSkillAutocompleteMultiple from "../../../components/form-component/autocomplete/UserSkillAutocompleteMultiple";
import UserRoleAutocompleteMultiple from "../../../components/form-component/autocomplete/UserRoleAutocompleteMultiple";

const FormValidation = Yup.object().shape({
  email: Yup.string().email().required("Required")
});

const formObj = {
  name: "user-resource-app-form",
  validation: FormValidation,
  groups: [
    {
      title: "Main",
      subTitle: "My description",
      fields: [
        { name: "firstName", label: "First Name", type: TEXT, fieldProps: {} },
        { name: "lastName", label: "Last Name", type: TEXT, fieldProps: {} },
        { name: "miName", label: "Middle Initial", type: TEXT, fieldProps: {} },
        { name: "preferredName", label: "Preferred Name", type: TEXT, fieldProps: {} },
        { name: "email", label: "Email", type: TEXT, fieldProps: {} },
        { name: "birthDate", label: "Birth Date", type: DATE, fieldProps: {} },
        { name: "nationality", label: "Nationality", type: TEXT, fieldProps: {} },
        { name: "phone", label: "Phone", type: TEXT, fieldProps: {} },
        { name: "cellphone", label: "Cellphone", type: TEXT, fieldProps: {} },
        { name: "referenceNumber", label: "Reference #", type: TEXT, fieldProps: {} },
        { name: "gender", label: "Gender", type: COMPONENT, fieldProps: { component: GenderAutocomplete } },
        { name: "statusMessage", label: "Status Message", type: TEXT, fieldProps: {} }
      ]
    },
    {
      title: "Picture",
      subTitle: "",
      fields: [
        { name: "profilePictureUrl", label: "Profile Picture", type: FILE_UPLOAD_DROPZONE, fieldProps: {} }
      ]
    },
    {
      title: "Other",
      fields: [
        { name: "jobTitle", label: "Job Title", type: COMPONENT, fieldProps: { component: UserTypeAutocomplete } },
        { name: "userStatus", label: "Status", type: COMPONENT, fieldProps: { component: UserStatusAutocomplete } },
        { name: "userType", label: "Type", type: COMPONENT, fieldProps: { component: UserTypeAutocomplete } },
        { name: "userLabels", label: "Labels", type: COMPONENT, fieldProps: { component: UserLabelAutocompleteMultiple } },
        { name: "userSkills", label: "Skills", type: COMPONENT, fieldProps: { component: UserSkillAutocompleteMultiple } },
        { name: "roleUsers", label: "Roles", type: COMPONENT, fieldProps: { component: UserRoleAutocompleteMultiple } }
      ]
    },
    {
      title: "Address",
      fields: [
        { name: "address", label: "Address", type: TEXT, fieldProps: {} },
        { name: "addressNo", label: "Address No", type: TEXT, fieldProps: {} },
        { name: "city", label: "City", type: TEXT, fieldProps: {} },
        { name: "province", label: "State", type: TEXT, fieldProps: {} },
        { name: "zipCode", label: "Zip", type: TEXT, fieldProps: {} }
      ]
    },
    {
      title: "Education",
      fields: [
        { name: "highSchoolName", label: "High School Name", type: TEXT, fieldProps: {} },
        { name: "highSchoolGraduation", label: "High School Graduation", type: DATE, fieldProps: {} },
        { name: "collegeName", label: "College Name", type: TEXT, fieldProps: {} },
        { name: "collegeGraduation", label: "College Graduation", type: DATE, fieldProps: {} }
      ]
    },
    {
      title: "Primary Contact",
      fields: [
        { name: "primaryContactName", label: "Name", type: TEXT, fieldProps: {} },
        { name: "primaryContactEmail", label: "Email", type: TEXT, fieldProps: {} },
        { name: "primaryContactPhone", label: "Phone", type: TEXT, fieldProps: {} },
        { name: "primaryContactCellphone", label: "Cellphone", type: TEXT, fieldProps: {} }
      ]
    },
    {
      title: "Opt-In",
      subTitle: "",
      fields: [
        { name: "optInSms", label: "SMS", type: CHECKBOX, fieldProps: {} },
        { name: "optInEmail", label: "Email", type: CHECKBOX, fieldProps: {} },
        { name: "optInMail", label: "Mail", type: CHECKBOX, fieldProps: {} },
        { name: "optInPushNotification", label: "Push Notification", type: CHECKBOX, fieldProps: {} }
      ]
    },
    {
      title: "Biography",
      fields: [
        { name: "biography", label: "Biography", type: TEXT_AREA, fieldProps: { gridSize: 12 } }
      ]
    },
    {
      title: "Social",
      fields: [
        { name: "website", label: "Website", type: TEXT, fieldProps: {} },
        { name: "facebook", label: "Facebook", type: TEXT, fieldProps: {} },
        { name: "instagram", label: "Instagram", type: TEXT, fieldProps: {} },
        { name: "linkedIn", label: "Linked In", type: TEXT, fieldProps: {} },
        { name: "twitter", label: "Twitter", type: TEXT, fieldProps: {} }
      ]
    },
    {
      title: "Info",
      fields: [
        { name: "createdDate", label: "Created", type: TEXT_DISABLED, fieldProps: {} },
        { name: "modifiedDate", label: "Modified", type: TEXT_DISABLED, fieldProps: {} },
        { name: "deletedDate", label: "Deleted", type: TEXT_DISABLED, fieldProps: {} },
        { name: "oauthProviderId", label: "Oauth ID", type: TEXT_DISABLED, fieldProps: {} },
        { name: "tenantId", label: "Tenant ID", type: TEXT_DISABLED, fieldProps: {} },
        { name: "userName", label: "User Name", type: TEXT_DISABLED, fieldProps: {} }
      ]
    }
  ],
  record: null
};

const UserResourceAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState(currentRecord);

  const handleExtraFields = (formik) => {
    return (
      <Fragment>
        <UserNotesFieldArray formik={formik} />
      </Fragment>
    );
  };

  return (
    <GenericAppFormBuilder currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} onAddExtraFields={handleExtraFields} />
  );
};

UserResourceAppForm.propTypes = {
  onSubmit: PropTypes.func
};

export default UserResourceAppForm;

import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { Checkbox, Grid } from "@material-ui/core";
import FieldContainer from "../form-component/container/FieldContainer";

const FormikCheckboxField = (props) => {
  const pr = props.formik;
  return(
    <FieldContainer {...props} >
      <Field
        {...props}
        type="checkbox"
        component={Checkbox}
        fullWidth
        onChange={pr.handleChange(props.fieldName)}
        error={Boolean(pr.errors[props.fieldName])}
        value={(pr.values && pr.values[props.fieldName]) || ''}
        checked={pr.values && pr.values[props.fieldName]}
        // pr.touched[props.fieldName] &&
        helperText={pr.errors[props.fieldName]}
      />
      {props.label}
    </FieldContainer>
  )

}

FormikCheckboxField.defaultProps ={
  isSetName: true
}
FormikCheckboxField.propTypes ={
  fieldName: PropTypes.string,
  formik: PropTypes.any
}

export default FormikCheckboxField;

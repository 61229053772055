import React, { useState } from "react";
import * as Yup from "yup";
import { COMPONENT, TEXT, TEXT_DISABLED } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";
import PermissionRoleAutocompleteMultiple from "../../../components/form-component/autocomplete/PermissionRoleAutocompleteMultiple";

const FormValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
});

const formObj = {
  name:'role-app-form',
  validation: FormValidation,
  groups: [
    {
      title: 'Main',
      subTitle: '',
      fields: [
        { name: 'name', label: 'Name', type: TEXT, fieldProps: {} },
        { name: 'description', label: 'Description', type: TEXT, fieldProps: {} },
        { name: 'permissionRoles', label: 'Permissions', type: COMPONENT, fieldProps: { component: PermissionRoleAutocompleteMultiple} },
      ]
    },
    {
      title: "Info",
      fields: [
        { name: "createdDate", label: "Created", type: TEXT_DISABLED, fieldProps: {} },
        { name: "modifiedDate", label: "Modified", type: TEXT_DISABLED, fieldProps: {} },
        { name: "deletedDate", label: "Deleted", type: TEXT_DISABLED, fieldProps: {} }
      ]
    },

  ],
  record: null
}

const RoleResourceAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState( currentRecord);

  // const handleExtraFields = (formik) => {
  //   return(
  //     <FormikFileUploadField formik={formik} fieldName={'slug'} isPreviewOnly={true} isPublic={false} label={'Slug'}/>
  //   )
  // }

  return (
    <GenericAppFormBuilder  currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} />
  )
}

RoleResourceAppForm.propTypes = {
  onSubmit: PropTypes.func
}

export default RoleResourceAppForm;

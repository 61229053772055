import { takeLatest } from "redux-saga/effects";
import UploadedFileActions from "./actions/UploadedFileActions";
import GenericAppActions from "./actions/GenericAppActions";
import { UPLOADED_FILE_PAGINATE_URL } from "../../routes/Apps/UploadedFileApp";
import { UPLOADED_FILE_DESTROY, UPLOADED_FILE_STORE, UPLOADED_FILE_UPDATE } from "../action-types/UploadedFileActionTypes";

// function* fetchTenantList() {
//   const user = yield call(UploadedFileActions.tenantList);
//   yield put({type: FETCH_TENANT_LIST_SUCCESS, payload: user.data});
// }
//
// function* updateCurrentUserTenant(action) {
//   const user = yield call(UploadedFileActions.updateCurrentTenant, action.payload);
//   yield put({type: UPDATE_CURRENT_USER_TENANT_SUCCESS, payload: user.data});
// }

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(UploadedFileActions.store, action.payload, UPLOADED_FILE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(UploadedFileActions.update, action.payload, UPLOADED_FILE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(UploadedFileActions.destroy, action.payload, UPLOADED_FILE_PAGINATE_URL);
}

export const UploadedFileSaga = [
  takeLatest(UPLOADED_FILE_STORE, fetchStore),
  takeLatest(UPLOADED_FILE_UPDATE, fetchUpdate),
  takeLatest(UPLOADED_FILE_DESTROY, fetchDestroy),
];



import HttpClient from "../../../utils/HttpClient";

const PREFIX = '/Skill';


const store = (aObj) => {
  return HttpClient.HttpClientWebApi().post(`${PREFIX}/Store`, aObj);
}

const update = (aObj) => {
  return HttpClient.HttpClientWebApi().put(`${PREFIX}/Update/${aObj.id}`, aObj);
}

const destroy = (aObj) => {
  return HttpClient.HttpClientWebApi().delete(`${PREFIX}/Destroy/${aObj.id}`);
}

export default {
  store,
  update,
  destroy
}

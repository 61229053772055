import * as React from 'react';
import * as Yup from "yup";
import { TEXT_AREA } from "../form/VinSystemsFormBuilderFieldTypes";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Tooltip, Typography } from "@mui/material";
import MessageIcon from "@material-ui/icons/Message";
import { useState } from "react";
import { Form, Formik } from "formik";
import { Save, Send } from "@material-ui/icons";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import HttpClient from "../../utils/HttpClient";
import AlertActions from "../../redux/sagas/actions/AlertActions";
import SmsSenderActions from '../../redux/sagas/actions/SmsSenderActions';

const FormValidation = Yup.object().shape({
  message: Yup.string().required("Required"),
});

const formObj = {
  name:'skill-form',
  validation: FormValidation,
  groups: [
    {
      title: 'Main',
      subTitle: '',
      fields: [
        { name: 'message', label: 'Message', type: TEXT_AREA, fieldProps: { gridSize: 12} },
      ]
    }
  ],
  record: null
};

export default function SmsSenderDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [obj, setObj] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values, formik) => {
    SmsSenderActions.sendSmsSingle(props.listItem.cellphone, values.message).then(r => {
      AlertActions.showDialogSuccess('Success', 'Message sent');
    }).catch(e => {
      AlertActions.showDialogError('Success', e?.response?.data?.message || JSON.stringify(e));
    }).finally(() => {
      setOpen(false);
    });
  };

  return (
    <div>
      {(props?.listItem?.cellphone && props?.listItem?.optInSms) &&
        <Tooltip title="SMS">
          <IconButton variant="outlined" onClick={handleClickOpen}>
            <MessageIcon/>
          </IconButton>
        </Tooltip>
      }
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle><IntlMessages id={"Sms Sender"}/></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <IntlMessages id={"You are about to send a message to"} />: <Typography color={'primary'}>{`${props.listItem.name}(${props.listItem.cellphone})`}</Typography>.
          </DialogContentText>
          {/*<GenericAppFormBuilder  currentRecord={{}} formObj={formObj} onSubmit={handleSubmit} />*/}
          <Formik initialValues={obj} validationSchema={FormValidation} onSubmit={handleSubmit} enableReinitialize={true} validateOnChange={false}>
            {
              (pr) => {
                return(
                  <Form>
                    <TextField
                      autoFocus
                      id="message"
                      name="message"
                      label={<IntlMessages id={"Message"}/>}
                      fullWidth
                      variant="outlined"
                      onChange={pr.handleChange('message')}
                      helperText={pr.errors['message']}
                      error={Boolean(pr.errors['message'])}
                      multiline={true}
                      rows={4}
                    />
                    {/*<pre>{JSON.stringify(pr)}</pre>*/}
                    <Button variant="contained" startIcon={<Send/>} type="button" disabled={pr.isSubmitting} color={'primary'} onClick={pr.handleSubmit} style={{ marginTop: "20px" }}>
                      <IntlMessages id={"Send"}/>
                    </Button>
                  </Form>
                )
              }
            }
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/*<Button onClick={handleClose}>Subscribe</Button>*/}
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider, useDispatch } from "react-redux";
import { Switch } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import configureStore, { history } from "./redux/store";
import AppWrapper from "./@jumbo/components/AppWrapper";
import AppContextProvider from "./@jumbo/components/contextProvider/AppContextProvider";
import Routes from "./routes";
import { MsalProvider, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./AppConfig";
import { EventType, InteractionRequiredAuthError, InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import { USER_AFTER_LOGIN_SUCCESS } from "./redux/action-types/UserActionTypes";
import UserActions from "./redux/sagas/actions/UserActions";
import { AuthProvider } from "./context/AuthContext";
import { useAuth } from "./context/AuthContext";

export const store = configureStore();

export const msalInstance = new PublicClientApplication(msalConfig);

const AuthContext = React.createContext();

const MsalGuard = (props) => {

  const dispatch = useDispatch();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuth();
  const [authIsLoading, setAuthIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if(auth) {
      if (accounts.length > 0) {
        const request = {
          ...loginRequest,
          account: accounts[0]
        };

        const user = {
          userName: auth.username,
          oauthId: auth.localAccountId,
          firstName: auth.idTokenClaims.given_name,
          lastName: auth.idTokenClaims.family_name,
          email: auth.idTokenClaims.emails[0],
        }

        // I tried using redux saga here, but the saga was being called before the token was set by msal and I was getting 401 from our server
        msalInstance.acquireTokenSilent(request).then((response) => {
          UserActions.doAfterLoginWithToken(user, response.accessToken).then(r => {
            dispatch({ type: USER_AFTER_LOGIN_SUCCESS, payload: r.data });
            setCurrentUser(r.data);
          });
        }).catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect(request);
          }
        });
      }
    }

    setAuthIsLoading(false);
  }, [auth]);


  useEffect(() => {

    const callbackId = instance.addEventCallback((message) => {
      // This will be run every time an event is emitted after registering this callback

      // if ((message.eventType === EventType.LOGIN_SUCCESS) && (inProgress === InteractionStatus.Startup)) {
      //
      // }

      if ((message.eventType === EventType.LOGOUT_SUCCESS) && (inProgress === InteractionStatus.Startup)) {
        // console.log(message.payload);
      }
    });

    if (!account && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest);
    }

    // return () => {
    //   // This will be run on component unmount
    //   if (callbackId) {
    //     instance.removeEventCallback(callbackId);
    //   }
    // }

  }, [inProgress]);

  const values = {
    currentUser
  }

  return(
    <AuthContext.Provider value={values}>
      {(((!authIsLoading) && isAuthenticated) ? props.children : 'Loading...')}
    </AuthContext.Provider>
  )

  // return (((!authIsLoading) && isAuthenticated) ? props.children : 'Loading...');
}

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {/*<MsalProvider instance={msalInstance}>*/}
        {/*  <MsalGuard>*/}
        <AuthProvider>
          <AppContextProvider>
            <AppWrapper>
              <Switch>
                <Routes />
              </Switch>
            </AppWrapper>
          </AppContextProvider>
        </AuthProvider>
          {/*</MsalGuard>*/}
        {/*</MsalProvider>*/}
      </ConnectedRouter>
    </Provider>
  );
};

export default App;

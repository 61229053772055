import React, { useState } from "react";
import VinSystemsFormBuilder from "../../../components/form/VinSystemsFormBuilder";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

function GenericAppFormBuilder(props) {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState(currentRecord);

  return (
    <VinSystemsFormBuilder {...props} currentRecord={obj} />
  );
}

GenericAppFormBuilder.propTypes = {
  submitLabel: PropTypes.string,
  formObj: PropTypes.object,
  onSubmit: PropTypes.func,
  submitIcon: PropTypes.any,
  currentRecord: PropTypes.object,
  onAddExtraFields: PropTypes.func
};

export default GenericAppFormBuilder;

import { takeLatest } from "redux-saga/effects";
import BrandActions from "./actions/BrandActions";
import GenericAppActions from "./actions/GenericAppActions";
import { BRAND_PAGINATE_URL } from "../../routes/Apps/BrandApp";
import { BRAND_DESTROY, BRAND_STORE, BRAND_UPDATE } from "../action-types/BrandActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(BrandActions.store, action.payload, BRAND_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(BrandActions.update, action.payload, BRAND_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(BrandActions.destroy, action.payload, BRAND_PAGINATE_URL);
}

export const BrandSaga = [
  takeLatest(BRAND_STORE, fetchStore),
  takeLatest(BRAND_UPDATE, fetchUpdate),
  takeLatest(BRAND_DESTROY, fetchDestroy),
];



export const TEXT = 'text';
export const TEXT_FIELD = 'text_field';
export const TEXT_DISABLED = 'text_diabled';
export const SELECT = 'select';
export const FILE_UPLOAD_DROPZONE = 'file_upload_dropzone';
export const CHECKBOX = 'checkbox';
export const TEXT_AREA = 'text_area';
export const AUTOCOMPLETE = 'autocomplete';
export const COMPONENT = 'component';
export const CURRENCY = 'currency';
export const INTEGER = 'integer';
export const NUMBER = 'integer';
export const AUTOCOMPLETE_MULTIPLE = 'autocomplete_multiple';
export const DATE = 'date';
export const DATE_TIME = 'date_time';
export const TIME = 'time';

import { takeLatest } from "redux-saga/effects";
import DeviceActions from "./actions/DeviceActions";
import GenericAppActions from "./actions/GenericAppActions";
import { DEVICE_PAGINATE_URL } from "../../routes/Apps/DeviceApp";
import { DEVICE_DESTROY, DEVICE_STORE, DEVICE_UPDATE } from "../action-types/DeviceActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(DeviceActions.store, action.payload, DEVICE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(DeviceActions.update, action.payload, DEVICE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(DeviceActions.destroy, action.payload, DEVICE_PAGINATE_URL);
}

export const DeviceSaga = [
  takeLatest(DEVICE_STORE, fetchStore),
  takeLatest(DEVICE_UPDATE, fetchUpdate),
  takeLatest(DEVICE_DESTROY, fetchDestroy),
];



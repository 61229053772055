import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { COMPONENT, FILE_UPLOAD_DROPZONE, TEXT, TEXT_AREA, TEXT_DISABLED } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";
import IndustryAutocompleteMultiple from "../../../components/form-component/autocomplete/IndustryAutocompleteMultiple";
import OrganizationTypeAutocomplete from "../../../components/form-component/autocomplete/OrganizationTypeAutocomplete";
import OrganizationCategoryAutocomplete from "../../../components/form-component/autocomplete/OrganizationCategoryAutocomplete";
import LabelAutocompleteMultiple from "../../../components/form-component/autocomplete/LabelAutocompleteMultiple";
import OrganizationNotesFieldArray from "./OrganizationNotesFieldArray";
import OrganizationContactsFieldArray from "./OrganizationContactsFieldArray";


const FormValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email()
});

const formObj = {
  name: "organization-form",
  validation: FormValidation,
  groups: [
    {
      title: "Main",
      subTitle: "",
      fields: [
        { name: "name", label: "Name", type: TEXT, fieldProps: {} },
        { name: "alias", label: "Alias", type: TEXT, fieldProps: { } },
        { name: "description", label: "Description", type: TEXT, fieldProps: {} },
        { name: "code", label: "Code", type: TEXT, fieldProps: {} },
        { name: "phone", label: "Phone", type: TEXT, fieldProps: {} },
        { name: "cellphone", label: "Cellphone", type: TEXT, fieldProps: {} },
        { name: "businessPhone", label: "Business Phone", type: TEXT, fieldProps: {} },
        { name: "businessFax", label: "Business Fax", type: TEXT, fieldProps: {} },
        { name: "email", label: "Email", type: TEXT, fieldProps: {} },
        { name: "statusMessage", label: "Status Message", type: TEXT, fieldProps: {} },
        { name: "organizationType", label: "Organization Type", type: COMPONENT, fieldProps: { component: OrganizationTypeAutocomplete } },
        { name: "organizationCategory", label: "Organization Category", type: COMPONENT, fieldProps: { component: OrganizationCategoryAutocomplete } },
        { name: "organizationIndustries", label: "Industries", type: COMPONENT, fieldProps: { component: IndustryAutocompleteMultiple } },
        { name: "organizationLabels", label: "Labels", type: COMPONENT, fieldProps: { component: LabelAutocompleteMultiple } },
        // { name: 'industries', label: 'Industries', type: COMPONENT, fieldProps: { component: IndustryAutocompleteMultiple } },
      ]
    },
    {
      title: 'Picture',
      subTitle: '',
      fields: [
        { name: 'profilePictureUrl', label: 'Profile Picture', type: FILE_UPLOAD_DROPZONE, fieldProps: {} },
      ]
    },
    {
      title: "Address",
      fields: [
        { name: "address", label: "Address", type: TEXT, fieldProps: {} },
        { name: "addressNo", label: "Address No", type: TEXT, fieldProps: {} },
        { name: "city", label: "City", type: TEXT, fieldProps: {} },
        { name: "province", label: "State", type: TEXT, fieldProps: {} },
        { name: "zipCode", label: "Zip", type: TEXT, fieldProps: {} },
        { name: "country", label: "Country", type: TEXT, fieldProps: {} }
      ]
    },
    {
      title: "Contact",
      fields: [
        { name: "contactName", label: "Contact Name", type: TEXT, fieldProps: {} },
        { name: "contactEmail", label: "Contact Email", type: TEXT, fieldProps: {} },
        { name: "contactPhone", label: "Contact Phone", type: TEXT, fieldProps: {} },
        { name: "contactCellphone", label: "Contact Cellphone", type: TEXT, fieldProps: {} }
      ]
    },
    {
      title: "Social",
      fields: [
        { name: "website", label: "Website", type: TEXT, fieldProps: {} },
        { name: "facebook", label: "Facebook", type: TEXT, fieldProps: {} },
        { name: "instagram", label: "Instagram", type: TEXT, fieldProps: {} },
        { name: "linkedIn", label: "Linked In", type: TEXT, fieldProps: {} },
        { name: "twitter", label: "Twitter", type: TEXT, fieldProps: {} }
      ]
    },
    {
      title: "Biography",
      fields: [
        { name: "biography", label: "Biography", type: TEXT_AREA, fieldProps: { gridSize: 12 } }
      ]
    },
    {
      title: "Info",
      fields: [
        { name: "createdDate", label: "Created", type: TEXT_DISABLED, fieldProps: {} },
        { name: "modifiedDate", label: "Modified", type: TEXT_DISABLED, fieldProps: {} },
        { name: "deletedDate", label: "Deleted", type: TEXT_DISABLED, fieldProps: {} }
      ]
    }
  ],
  record: null
};

const OrganizationAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState(currentRecord);

  const handleExtraFields = (formik) => {

    return (
      <Fragment style={{backgroundColor: 'gray'}}>
        <OrganizationContactsFieldArray formik={formik}/>
        <OrganizationNotesFieldArray formik={formik}/>
      </Fragment>
    );
  };

  return (
    <GenericAppFormBuilder currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} onAddExtraFields={handleExtraFields} />
  );
};

OrganizationAppForm.propTypes = {
  onSubmit: PropTypes.func
};

export default OrganizationAppForm;

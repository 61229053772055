import HttpClient, { HttpClientConfig } from "../../../utils/HttpClient";
import axios from "axios";

const PREFIX = '/User';

const doAfterLogin = (user) => {
  return HttpClient.HttpClientWebApi().post('/User/AfterUserLogin', user);
}

const doAfterLoginWithToken = (aUser, aToken) => {
  const cfg = HttpClientConfig;
  cfg.headers.Authorization = 'Bearer ' + aToken;
  return axios.post('/User/AfterUserLogin', aUser, cfg);
}

const getUserPage = (aPageObj) => {
  let p = new URLSearchParams(aPageObj);
  return HttpClient.HttpClientWebApi().get('/User/List?'+p.toString());
}

const update = (aObj) => {
  return HttpClient.HttpClientWebApi().put(`${PREFIX}/Update/${aObj.id}`, aObj);
}



const storeUser = (user) => {
  return HttpClient.HttpClientWebApi().post('/User/Store', user);
}

export default {
  doAfterLogin,
  doAfterLoginWithToken,
  getUserPage,
  storeUser,
  update
}

import { takeLatest } from "redux-saga/effects";
import PermissionResourceActions from "./actions/PermissionResourceActions";
import GenericAppActions from "./actions/GenericAppActions";
import { PERMISSION_PAGINATE_URL } from "../../routes/Apps/PermissionResourceApp";
import { PERMISSION_DESTROY, PERMISSION_STORE, PERMISSION_UPDATE } from "../action-types/PermissionResourceActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(PermissionResourceActions.store, action.payload, PERMISSION_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(PermissionResourceActions.update, action.payload, PERMISSION_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(PermissionResourceActions.destroy, action.payload, PERMISSION_PAGINATE_URL);
}

export const PermissionResourceSaga = [
  takeLatest(PERMISSION_STORE, fetchStore),
  takeLatest(PERMISSION_UPDATE, fetchUpdate),
  takeLatest(PERMISSION_DESTROY, fetchDestroy),
];



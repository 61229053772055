import React, { useState } from "react";
import * as Yup from "yup";
import { FILE_UPLOAD_DROPZONE, TEXT, TEXT_AREA, TEXT_DISABLED } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useDispatch, useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import { TENANT_STORE, TENANT_UPDATE } from "../../../redux/action-types/TenantActionTypes";

const FormValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  originUrl: Yup.string().required("Required"),
  connectionString: Yup.string().required("Required"),
});

const formObj = {
  name:'notification-form',
  validation: FormValidation,
  groups: [
    {
      title: 'Main',
      subTitle: '',
      fields: [
        { name: 'name', label: 'Name', type: TEXT, fieldProps: {} },
        { name: 'description', label: 'Description', type: TEXT, fieldProps: {} },
        { name: 'email', label: 'Email', type: TEXT, fieldProps: {} },
        { name: 'createdDate', label: 'Created', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'modifiedDate', label: 'Modified', type: TEXT_DISABLED, fieldProps: {} }
      ]
    },
    {
      title: 'Configuration',
      subTitle: '',
      fields: [
        { name: 'originUrl', label: 'Origins', type: TEXT_AREA, fieldProps: {} },
        { name: 'connectionString', label: 'Connection String', type: TEXT_AREA, fieldProps: {} },
      ]
    },
    {
      title: 'Avatar',
      subTitle: '',
      fields: [
        { name: 'avatarUrlLarge', label: 'Large', type: FILE_UPLOAD_DROPZONE, fieldProps: {} },
        { name: 'avatarUrlMedium', label: 'Medium', type: FILE_UPLOAD_DROPZONE, fieldProps: {} },
        { name: 'avatarUrlSmall', label: 'Small', type: FILE_UPLOAD_DROPZONE, fieldProps: {} }
      ]
    },
    {
      title: 'Address',
      subTitle: '',
      fields: [
        { name: 'address', label: 'Address', type: TEXT, fieldProps: {} },
        { name: 'addressNo', label: 'Address #', type: TEXT, fieldProps: {} },
        { name: 'city', label: 'City', type: TEXT, fieldProps: {} },
        { name: 'province', label: 'Province', type: TEXT, fieldProps: {} },
        { name: 'zipCode', label: 'Zip', type: TEXT, fieldProps: {} },
      ]
    },

  ],
  record: null
}

const TenantAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState( currentRecord);
  const dispatch = useDispatch();

  const handleSubmit = (values, formik) => {
    const type = values.id ?  TENANT_UPDATE : TENANT_STORE;
    dispatch({type: type, payload: values});
  };

  return (
    <GenericAppFormBuilder  currentRecord={obj} formObj={formObj} onSubmit={handleSubmit} />
  )
}

export default TenantAppForm;

import { SUPER_ADMIN } from "@vinsystems/web-client/src/@jumbo/constants/PermissionConsts";

export const isGoku = (user) => {
  try {
    return (user?.roleUsers?.findIndex(r => r?.role?.name === SUPER_ADMIN) !== -1) ;//&& (user?.permissions?.findIndex(r => r.name === SUPER_ADMIN) !== -1);
  } catch (e) {
    return false;
  }
};

const containsAll = (smallArr, largeArr) => {
  return smallArr.every((el) => {
    return largeArr.indexOf(el) !== -1;
  });
};

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function mapPerms(user) {
  return (user?.permissions || []).map(p => {
    return p.name.toLowerCase();
  });
}

export const hasPermissionsAnd = (user, requiredPermissions) => {
  if (isGoku(user)) {
    return true;
  }

  const perms = mapPerms(user);
  return containsAll(requiredPermissions, perms);
};

export const hasPermissionsOr = (user, requiredPermissions) => {
  if (isGoku(user)) {
    return true;
  }

  const perms = mapPerms(user);
  return (intersection(requiredPermissions, perms).length > 0);
};

import { takeLatest } from "redux-saga/effects";
import ProductActions from "./actions/ProductActions";
import GenericAppActions from "./actions/GenericAppActions";
import { PRODUCT_PAGINATE_URL } from "../../routes/Apps/ProductApp";
import { PRODUCT_DESTROY, PRODUCT_STORE, PRODUCT_UPDATE } from "../action-types/ProductActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(ProductActions.store, action.payload, PRODUCT_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(ProductActions.update, action.payload, PRODUCT_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(ProductActions.destroy, action.payload, PRODUCT_PAGINATE_URL);
}

export const ProductSaga = [
  takeLatest(PRODUCT_STORE, fetchStore),
  takeLatest(PRODUCT_UPDATE, fetchUpdate),
  takeLatest(PRODUCT_DESTROY, fetchDestroy),
];



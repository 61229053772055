import React, { useContext, useState } from "react";
import { Box, Typography } from '@material-ui/core';
import useStyles from './index.style';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import CmtSearch from '../../../@coremat/CmtSearch';
import { setFilterType, toggleExpandSidebar } from '../../../redux/actions/ContactApp';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import GridOnIcon from '@material-ui/icons/GridOn';
import ListIcon from '@material-ui/icons/List';
import Hidden from '@material-ui/core/Hidden';
import { GenericAppContext } from "./GenericAppContext";
import { GENERIC_APP_GET_RECORDS_LIST, GENERIC_APP_SET_CURRENT_PAGE_NO, GENERIC_APP_SET_FILTER_TYPE } from "../../../redux/action-types/GenericAppActionTypes";
import { Refresh } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const AppHeader = ({ onChangeViewMode, viewMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appSettings = useContext(GenericAppContext);
  const [sText, setSText] = useState('');

  const { filterType, page, pageLimit } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const { searchText } = filterType;
  const hist = useHistory();

  const dispatchSearch = (s) => {
    const fType ={
      selectedFolder: s ? '' : 'contacts',
      selectedLabel: '',
      searchText: s || '',
    };

    setSText(s);
    dispatch({type: GENERIC_APP_SET_FILTER_TYPE, payload: fType});
  }
  const handleSearchText = e => {
    dispatch({type: GENERIC_APP_SET_CURRENT_PAGE_NO, payload: 0});
    dispatchSearch(e.target.value);
    if(appSettings.events.onSearchChange){
      appSettings.events.onSearchChange(e.target.value);
    }

    // dispatch(
    //   setFilterType({
    //     selectedFolder: e.target.value ? '' : 'contacts',
    //     selectedLabel: '',
    //     searchText: e.target.value,
    //   }),
    // );
  };

  const handleSearchEnterPressedText = e => {
    if ((e.key === 'Enter') && (sText)) {
      if(appSettings.events.onSearchEnterKeyDown){
        appSettings.events.onSearchEnterKeyDown(sText);
      }else{
        dispatch({type: GENERIC_APP_GET_RECORDS_LIST, payload: appSettings.dispatchActions.paginate(page, pageLimit, 0, sText)});
      }
    }
  };

  const refreshList = () => {
    dispatchSearch('');
    hist.push(appSettings.urls.indexFrontend);
    dispatch({type: GENERIC_APP_GET_RECORDS_LIST, payload: appSettings.dispatchActions.paginate(page, pageLimit)});
  }

  // const dispatchSearch = (s) => {
  //   const fType ={
  //     selectedFolder: s ? '' : 'contacts',
  //     selectedLabel: '',
  //     searchText: s || '',
  //   };
  //
  //   dispatch({type: GENERIC_APP_SET_FILTER_TYPE, payload: fType});
  // }

  return (
    <Box className={classes.inBuildAppHeader}>
      <Box className={classes.inBuildAppHeaderSidebar}>
        <Hidden smDown>
          <IconButton onClick={() => dispatch(toggleExpandSidebar())}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Typography className={classes.inBuildAppHeaderTitle} component="div" variant="h1">
          {appSettings.title}
        </Typography>
      </Box>

      {appSettings.isRead &&
      <Box className={classes.inBuildAppHeaderContent}>
        {appSettings.isRead &&
        <CmtSearch placeholder={appSettings.searchTitle} value={sText || ''} onChange={handleSearchText} onKeyDown={handleSearchEnterPressedText} border={false} />
        }
        <Box ml="auto" display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton
              className="icon-btn active"
              color={viewMode === 'table' ? 'primary' : 'default'}
              onClick={() => onChangeViewMode('table')}>
              <ListIcon />
            </IconButton>
          </Box>
          <Box ml={1}>
            <IconButton
              className="icon-btn"
              color={viewMode === 'grid' ? 'primary' : 'default'}
              onClick={() => onChangeViewMode('grid')}>
              <GridOnIcon />
            </IconButton>
          </Box>
          <Box ml={1}>
            <IconButton
              className="icon-btn"
              color={viewMode === 'grid' ? 'primary' : 'default'}
              onClick={refreshList}>
              <Refresh />
            </IconButton>
          </Box>
        </Box>
      </Box>
      }
    </Box>
  );
};

export default AppHeader;

AppHeader.prototype = {
  onChangeViewMode: PropTypes.func,
};

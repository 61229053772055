import React from "react";
import { Grid } from "@material-ui/core";
// import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from "react-dropzone-uploader";
import FileUploaderActions from "../../redux/sagas/actions/FileUploaderActions";
import { NotificationContainer } from "react-notifications";
import PropTypes from "prop-types";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

// const useStyles = makeStyles(theme => ({
//   root: {
//     '& > *:not(:last-child)': {
//       marginRight: theme.spacing(3),
//     },
//   },
// }));

const FileUploaderDropzone = (props) => {


  // const toast = (innerHTML) => {
  //   const el = document.getElementById('toast')
  //   el.innerHTML = innerHTML
  //   el.className = 'show'
  //   setTimeout(() => { el.className = el.className.replace('show', '') }, 3000)
  // }

  // const handleChangeStatus = ({ meta, file, xhr}, status) => {
  //   if (status === 'done'){
  //     let response = JSON.parse(xhr.response);
  //   }
  // };

  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = ({ file, meta, remove }, status) => {
    if (status === "headers_received") {
      FileUploaderActions.uploadFile(file).then(r => {
        if (props.onUploadSuccess) {
          props.onUploadSuccess(r.data, file);
        }

        if (props.isRemoveAfterUpload) {
          remove();
        }
      });
    } else if (status === "aborted") {
      console.log(`${meta.name}, upload failed...`);
      // toast(`${meta.name}, upload failed...`)
    }
  };


  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
      {/*{props.slug &&*/}
      {/*<ImageAuth slug={props.slug} isPublic={props.isPublic} />*/}
      {/*}*/}
      {/*<ImageAuth slug={'ps5.jpg'} isPublic={true} />*/}
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        // onSubmit={handleSubmit}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent=""
        // onChange={handleOnChange}
      />
      <NotificationContainer />
    </Grid>
  );
};

FileUploaderDropzone.defaultProps = {
  isRemoveAfterUpload: true,
  isPublic: false,
};

FileUploaderDropzone.propTypes = {
  onUploadSuccess: PropTypes.func,
  isRemoveAfterUpload: PropTypes.bool,
  isPublic: PropTypes.bool
};

export default FileUploaderDropzone;

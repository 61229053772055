import { takeLatest } from "redux-saga/effects";
import ContactActions from "./actions/ContactActions";
import GenericAppActions from "./actions/GenericAppActions";
import { CONTACT_PAGINATE_URL } from "../../routes/Apps/ContactApp";
import { CONTACT_DESTROY, CONTACT_STORE, CONTACT_UPDATE } from "../action-types/ContactActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(ContactActions.store, action.payload, CONTACT_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(ContactActions.update, action.payload, CONTACT_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(ContactActions.destroy, action.payload, CONTACT_PAGINATE_URL);
}

export const ContactSaga = [
  takeLatest(CONTACT_STORE, fetchStore),
  takeLatest(CONTACT_UPDATE, fetchUpdate),
  takeLatest(CONTACT_DESTROY, fetchDestroy),
];



import React, { useEffect, useState } from "react";
import SidebarToggleHandler from '../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, InputBase, Menu, MenuItem } from "@material-ui/core";
import { alpha } from '@material-ui/core/styles';
import LanguageSwitcher from '../LanguageSwitcher';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchIcon from '@material-ui/icons/Search';
import AppsMenu from './AppsMenu';
import HeaderNotifications from './HeaderNotifications';
import HeaderMessages from './HeaderMessages';
import Hidden from '@material-ui/core/Hidden';
import Logo from '../Logo';
import SearchPopover from '../SearchPopover';
import { USER_AFTER_LOGIN } from "../../../../constants/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { AccountCircle } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import CmtAvatar from "../../../../../@coremat/CmtAvatar";
import IntlMessages from "../../../../utils/IntlMessages";
import { ADMIN, SUPER_ADMIN } from "../../../../constants/PermissionConsts";
import PermissionGuard from "../../../../../components/security/PermissionGuard";
import FileUploaderActions from "../../../../../redux/sagas/actions/FileUploaderActions";
import { useAuth } from "../../../../../context/AuthContext";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 64,
    [theme.breakpoints.up('md')]: {
      minHeight: 72,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  searchRoot: {
    position: 'relative',
    width: 260,
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 18,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 30,
      backgroundColor: alpha(theme.palette.common.dark, 0.38),
      color: alpha(theme.palette.common.white, 0.7),
      boxSizing: 'border-box',
      padding: '5px 15px 5px 50px',
      transition: 'all 0.3s ease',
      '&:focus': {
        backgroundColor: alpha(theme.palette.common.dark, 0.58),
        color: alpha(theme.palette.common.white, 0.7),
      },
    },
  },
  langRoot: {
    borderLeft: `solid 1px ${alpha(theme.palette.common.dark, 0.15)}`,
    minHeight: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minHeight: 64,
    },
  },
  iconBtn: {
    color: alpha(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
}));

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const account = useAuth();
  // const { instance, accounts, inProgress } = useMsal();
  const history = useHistory();
  const { currentUser } = useSelector(({ usersReducer }) => usersReducer);
  // console.log(currentUser);


  const { signOut } = useAuth();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    history.push('/apps/profile');
  };

  const onSettingsClick = () => {
    handlePopoverClose();
    history.push('/apps/user-settings');
  };
  const onTenantSettingsClick = () => {
    handlePopoverClose();
    history.push('/apps/tenant-settings');
  };

  const onNotificationsClick = () => {
    handlePopoverClose();
    history.push('/apps/notifications');
  };

  const onLogoutClick = () => {
    handlePopoverClose();
    signOut().then(r => {
      console.log(r);
    });
    // instance.logoutRedirect();
    // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };


  return(
    <div>
      {/*<IconButton*/}
      {/*  size="large"*/}
      {/*  aria-label="account of current user"*/}
      {/*  aria-controls="menu-appbar"*/}
      {/*  aria-haspopup="true"*/}
      {/*  onClick={handleMenu}*/}
      {/*  color="inherit"*/}
      {/*>*/}
      {/*  <AccountCircle />*/}
      {/*</IconButton>*/}
      <CmtAvatar alt={currentUser?.user?.firstName} src={ FileUploaderActions.getDownloadImageUrl(currentUser?.user?.profilePictureUrl, false) } color={'primary'} phCharLength={2} size={'small'} onClick={handleMenu} />
      {/*<CmtAvatar  color={'primary'} phCharLength={2} size={'small'} onClick={handleMenu} />*/}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <AccountCircle color={'primary'} />
          <div className="ml-2" ><Typography color={'primary'}>{currentUser?.user?.userName}</Typography></div>
        </MenuItem>

        <MenuItem onClick={handlePopoverClose}>
          <PersonIcon />
          <div className="ml-2"><IntlMessages id={'Profile'} /></div>
        </MenuItem>
        <MenuItem onClick={onSettingsClick}>
          <SettingsIcon />
          <div className="ml-2"><IntlMessages id={'User Settings'} /></div>
        </MenuItem>
        <PermissionGuard requiredPermissions={[ADMIN]}>
          <MenuItem onClick={onTenantSettingsClick}>
            <SettingsIcon />
            <div className="ml-2"><IntlMessages id={'Tenant Settings'} /></div>
          </MenuItem>
        </PermissionGuard>
        <MenuItem onClick={onNotificationsClick}>
          <SettingsIcon />
          <div className="ml-2"><IntlMessages id={'Notificatons'} /></div>
        </MenuItem>
        <MenuItem onClick={onLogoutClick}>
          <ExitToAppIcon color={'secondary'} />
          <div className="ml-2"><IntlMessages id={'Logout'} /></div>
        </MenuItem>
      </Menu>
    </div>
  )
}

const Header = () => {

  const classes = useStyles();
  // const usersReducer = useSelector((state) => state.usersReducer);
  // const auth = useAuth();
  // const dispatch = useDispatch();

  // useEffect(() => {
    // const user = {
    //   userName: auth.username,
    //   oauthId: auth.localAccountId,
    //   firstName: auth.idTokenClaims.given_name,
    //   lastName: auth.idTokenClaims.family_name,
    //   email: auth.idTokenClaims.emails[0],
    // }
    // dispatch({type: USER_AFTER_LOGIN, payload: user});

    // console.log(auth);
  // }, []);


  return (
    <Toolbar className={classes.root}>
      <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      <Logo ml={2} color="white" />
      <Box flex={1} />
      {/*<Hidden smDown>*/}
      {/*  <Box pr={3} className={classes.searchRoot}>*/}
      {/*    <InputBase placeholder={'Search here...'} inputProps={{ 'aria-label': 'search' }} />*/}
      {/*    <SearchIcon />*/}
      {/*  </Box>*/}
      {/*</Hidden>*/}
      {/*<Hidden mdUp>*/}
      {/*  <SearchPopover iconClassName={classes.iconBtn} />*/}
      {/*</Hidden>*/}

      {/*<AppsMenu />*/}
      <HeaderMessages />
      <HeaderNotifications />
      <Box className={classes.langRoot}>
        <LanguageSwitcher />
      </Box>
      <Box className={classes.langRoot}>
        <UserMenu/>
      </Box>
    </Toolbar>
  );
};

export default Header;

import { takeLatest } from "redux-saga/effects";
import OrganizationTypeActions from "./actions/OrganizationTypeActions";
import GenericAppActions from "./actions/GenericAppActions";
import { ORGANIZATION_TYPE_PAGINATE_URL } from "../../routes/Apps/OrganizationTypeApp";
import { ORGANIZATION_TYPE_DESTROY, ORGANIZATION_TYPE_STORE, ORGANIZATION_TYPE_UPDATE } from "../action-types/OrganizationTypeActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(OrganizationTypeActions.store, action.payload, ORGANIZATION_TYPE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(OrganizationTypeActions.update, action.payload, ORGANIZATION_TYPE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(OrganizationTypeActions.destroy, action.payload, ORGANIZATION_TYPE_PAGINATE_URL);
}

export const OrganizationTypeSaga = [
  takeLatest(ORGANIZATION_TYPE_STORE, fetchStore),
  takeLatest(ORGANIZATION_TYPE_UPDATE, fetchUpdate),
  takeLatest(ORGANIZATION_TYPE_DESTROY, fetchDestroy),
];



import React, { useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SidebarThemeContext from "../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext";
import TenantSwitcherSelect from "../../../../components/tenant/TenantSwitcherSelect";
import PermissionGuard from "../../../../components/security/PermissionGuard";
import { SUPER_ADMIN } from "../../../constants/PermissionConsts";

const useStyles = makeStyles(theme => ({
  root: {
    padding: '8px 16px 8px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });


  return (
    <PermissionGuard requiredPermissions={[SUPER_ADMIN]}>
      <div className={classes.root}>
        <TenantSwitcherSelect/>
      </div>
    </PermissionGuard>
  );
};

export default SidebarHeader;

import React, { useState } from "react";
import * as Yup from "yup";
import { TEXT } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";

const FormValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
});

const formObj = {
  name:'job-title-form',
  validation: FormValidation,
  groups: [
    {
      title: 'Main',
      subTitle: '',
      fields: [
        { name: 'name', label: 'Name', type: TEXT, fieldProps: {} },
        { name: 'description', label: 'Description', type: TEXT, fieldProps: {} },
        { name: 'type', label: 'Type', type: TEXT, fieldProps: {} },
        { name: 'code', label: 'Code', type: TEXT, fieldProps: {} },
      ]
    }
  ],
  record: null
}

const JobTitleAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState( currentRecord);

  // const handleExtraFields = (formik) => {
  //   return(
  //     <FormikFileUploadField formik={formik} fieldName={'slug'} isPreviewOnly={true} isPublic={false} label={'Slug'}/>
  //   )
  // }

  return (
    <GenericAppFormBuilder  currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} />
  )
}

JobTitleAppForm.propTypes = {
  onSubmit: PropTypes.func
}

export default JobTitleAppForm;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.dark,
    },
  },
}));

const FormContainerDialog = (props) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // console.log(props.title);
  return (
    <Dialog open={props.isOpen} onClose={props.onClose} className={classes.dialogRoot} fullScreen={true} id={'generic-app-dialog'}>
      <DialogTitle id="id">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} >
            {props.title}
            {props.subTitle &&
            <Typography variant="caption" display="block" color={'textSecondary'}>
              {props.subTitle}
            </Typography>}
          </Box>

          <Box>
            <IconButton onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

FormContainerDialog.defaultProps = {

};

FormContainerDialog.prototype = {
  handleDialog: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isOpen: PropTypes.bool
};

export default FormContainerDialog;

// export const FETCH_TENANT_SWITCH_SUCCESS = 'FETCH_TENANT_SWITCH_SUCCESS';
export const FETCH_TENANT_LIST = 'FETCH_TENANT_LIST';
export const FETCH_TENANT_LIST_SUCCESS = 'FETCH_TENANT_LIST_SUCCESS';
export const UPDATE_CURRENT_USER_TENANT = 'UPDATE_CURRENT_USER_TENANT';
export const UPDATE_CURRENT_USER_TENANT_SUCCESS = 'UPDATE_CURRENT_USER_TENANT_SUCCESS';
export const TENANT_STORE = 'TENANT_STORE';
export const TENANT_UPDATE = 'TENANT_UPDATE';
export const TENANT_DESTROY = 'TENANT_DESTROY';
export const TENANT_STORE_SUCCESS = 'TENANT_STORE_SUCCESS';





import { takeLatest } from "redux-saga/effects";
import AlertActions from "./actions/AlertActions";
import { ALERT_SHOW } from "../action-types/AlertActionTypes";

function* showDialog(action) {
  return AlertActions.showDialog(action);
}

export const AlertSaga = [
  takeLatest(ALERT_SHOW, showDialog)
];

import { takeLatest } from "redux-saga/effects";
import TimesheetActions from "./actions/TimesheetActions";
import GenericAppActions from "./actions/GenericAppActions";
import { TIMESHEET_PAGINATE_URL } from "../../routes/Apps/TimesheetApp";
import { TIMESHEET_DESTROY, TIMESHEET_STORE, TIMESHEET_UPDATE } from "../action-types/TimesheetActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(TimesheetActions.store, action.payload, TIMESHEET_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(TimesheetActions.update, action.payload, TIMESHEET_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(TimesheetActions.destroy, action.payload, TIMESHEET_PAGINATE_URL);
}

export const TimesheetSaga = [
  takeLatest(TIMESHEET_STORE, fetchStore),
  takeLatest(TIMESHEET_UPDATE, fetchUpdate),
  takeLatest(TIMESHEET_DESTROY, fetchDestroy),
];



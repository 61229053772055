import { call, put, takeLatest } from "redux-saga/effects";
import TenantActions from "./actions/TenantActions";
import {
  FETCH_TENANT_LIST,
  FETCH_TENANT_LIST_SUCCESS,
  TENANT_DESTROY,
  TENANT_STORE,
  TENANT_UPDATE,
  UPDATE_CURRENT_USER_TENANT,
  UPDATE_CURRENT_USER_TENANT_SUCCESS
} from "../action-types/TenantActionTypes";
import GenericAppActions from "./actions/GenericAppActions";
import { SYSTEM_SETTING_APP_PAGINATE_URL } from "../../routes/Apps/SystemSettingApp";
import AlertActions from "./actions/AlertActions";
import { TENANT_PAGINATE_URL } from "../../routes/Apps/TenantApp";

function* fetchTenantList() {
  const user = yield call(TenantActions.tenantList);
  yield put({type: FETCH_TENANT_LIST_SUCCESS, payload: user.data});
}

function* updateCurrentUserTenant(action) {
  const user = yield call(TenantActions.updateCurrentTenant, action.payload);
  yield put({type: UPDATE_CURRENT_USER_TENANT_SUCCESS, payload: user.data});
}

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(TenantActions.store, action.payload, TENANT_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(TenantActions.update, action.payload, TENANT_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(TenantActions.update, action.payload, TENANT_PAGINATE_URL);
}

export const TenantSaga = [
  takeLatest(FETCH_TENANT_LIST, fetchTenantList),
  takeLatest(UPDATE_CURRENT_USER_TENANT, updateCurrentUserTenant),
  takeLatest(TENANT_STORE, fetchStore),
  takeLatest(TENANT_UPDATE, fetchUpdate),
  takeLatest(TENANT_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import ClientActions from "./actions/ClientActions";
import GenericAppActions from "./actions/GenericAppActions";
import { CLIENT_PAGINATE_URL } from "../../routes/Apps/ClientApp";
import { CLIENT_DESTROY, CLIENT_STORE, CLIENT_UPDATE } from "../action-types/ClientActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(ClientActions.store, action.payload, CLIENT_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(ClientActions.update, action.payload, CLIENT_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(ClientActions.destroy, action.payload, CLIENT_PAGINATE_URL);
}

export const ClientSaga = [
  takeLatest(CLIENT_STORE, fetchStore),
  takeLatest(CLIENT_UPDATE, fetchUpdate),
  takeLatest(CLIENT_DESTROY, fetchDestroy),
];



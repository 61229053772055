import { takeLatest } from "redux-saga/effects";
import AuditResourceActions from "./actions/AuditResourceActions";
import GenericAppActions from "./actions/GenericAppActions";
import { AUDIT_RESOURCE_PAGINATE_URL } from "../../routes/Apps/AuditResourceApp";
import { AUDIT_RESOURCE_DESTROY, AUDIT_RESOURCE_STORE, AUDIT_RESOURCE_UPDATE } from "../action-types/AuditResourceActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(AuditResourceActions.store, action.payload, AUDIT_RESOURCE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(AuditResourceActions.update, action.payload, AUDIT_RESOURCE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(AuditResourceActions.destroy, action.payload, AUDIT_RESOURCE_PAGINATE_URL);
}

export const AuditResourceSaga = [
  takeLatest(AUDIT_RESOURCE_STORE, fetchStore),
  takeLatest(AUDIT_RESOURCE_UPDATE, fetchUpdate),
  takeLatest(AUDIT_RESOURCE_DESTROY, fetchDestroy),
];



import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import AutocompleteHttp from "../http/AutocompleteHttp";
import FieldContainer from "../form-component/container/FieldContainer";

const FormikAutocompleteField = (props) => {
  const pr = props.formik;
  const val = pr?.values && pr.values[props.fieldName];

  const handleChange = (e, a) => {
    const v = props.isPostObject ? a?.data : ( a? a.id : null)
    pr.setFieldValue(props.fieldName, v);
  };

  const handleClearClick = (e) => {
    handleChange(e, null);
  }

  return (
    <FieldContainer {...props} >
      <AutocompleteHttp
        {...props}
        value={val || ''}
        onSetValue={handleChange}
        onClearValue={handleClearClick}
        lookupUrl={props.lookupUrl}
        lookupByIdUrl={`${props.lookupByIdUrl}?id=${val?.id || val}`}
        helperCaption={pr.errors[props.fieldName]?.id}
        // onButtonClick={props.onAddButtonClick} // remove the comment to have the + button
      />
    </FieldContainer>
  );
};

FormikAutocompleteField.defaultProps = {
  isPostObject: true
};

FormikAutocompleteField.propTypes = {
  fieldName: PropTypes.string,
  formik: PropTypes.any,
  lookupUrl: PropTypes.string,
  lookupByIdUrl: PropTypes.string,
  onAddButtonClick: PropTypes.func,
  isPostObject: PropTypes.bool
};

export default FormikAutocompleteField;

import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Field } from "formik";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import FieldContainer from "../form-component/container/FieldContainer";

const FormikCurrencyField = (props) => {
  const pr = props.formik;
  const [text, setText] = useState("");

  const handleChange = (e) => {
    pr.setFieldValue(props.fieldName, e.target.value);

  }

  const handleKeyDown = (e) => {
    const allowedKeys = ['0','1','2','3','4','5','6','7','8','9','Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];
    if(props.fractionDigits > 0){
      allowedKeys.push('.', ',');
    }

    if(!allowedKeys.find((k) => k === e.key)){
      e.preventDefault();
      return;
    }

    if(((e.key === ',') || (e.key === '.')) && (e.target.value.indexOf(props.decimalSeparator) !== -1)){
      e.preventDefault();
      return;
    }

    if(e.key === ','){
      e.target.value += props.decimalSeparator;
      e.preventDefault();
    }
  }

  const handleEnter = (e) => {
    e.target.value = e.target.value.replace(props.thousandSeparator,'');
    pr.setFieldValue(props.fieldName, e.target.value);
  }

  const handleBlur = (e) => {
    let val = parseFloat(e.target.value);
    if(!isNaN(val)){
      const strNum = val.toLocaleString(props.locale, { minimumFractionDigits: props.fractionDigits, maximumFractionDigits: props.fractionDigits })
      pr.setFieldValue(props.fieldName, strNum);
      setText("");
      return;
    }

    setText("Please enter a valid number");
  }

  return(
    <FieldContainer {...props} >
      <Field
        {...props}
        component={TextField}
        variant={"outlined"}
        size="small"
        fullWidth
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleEnter}
        onBlur={handleBlur}
        error={Boolean(pr.errors[props.fieldName])}
        value={pr.values && pr.values[props.fieldName]}
        helperText={pr.errors[props.fieldName] || text}
        InputProps={{
          startAdornment: <InputAdornment position="start">{props.currencySymbol}</InputAdornment>,
        }}
      />
    </FieldContainer>
  )

}

FormikCurrencyField.defaultProps ={
  currencySymbol: "$",
  fractionDigits: 2,
  decimalSeparator: '.',
  thousandSeparator: ',',
  locale: 'en-US'
}
FormikCurrencyField.propTypes ={
  fieldName: PropTypes.string,
  formik: PropTypes.any,
  currencySymbol: PropTypes.string,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  locale: PropTypes.string,
  fractionDigits: PropTypes.number
}

export default FormikCurrencyField;

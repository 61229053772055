import { all } from "redux-saga/effects";
// import UserSaga from "./UserSaga";
// import UserSaga1 from "./UserSaga";
import { UserSaga } from "./UserSaga";
import { TenantSaga } from "./TenantSaga";
import { GenericAppSaga } from "./GenericAppSaga";
import { AlertSaga } from "./AlertSaga";
import { SystemSettingSaga } from "./SystemSettingSaga";
import { SystemSettingUserSaga } from "./SystemSettingUserSaga";
import { NotificationSaga } from "./NoticationSaga";
import { UploadedFileSaga } from "./UploadedFileSaga";


import { UserStatusSaga } from "./UserStatusSaga";
import { UserTypeSaga } from "./UserTypeSaga";
import { JobTitleSaga } from "./JobTitleSaga";
import { UserCategorySaga } from "./UserCategorySaga";
import { OrganizationSaga } from "./OrganizationSaga";
import { OrganizationTypeSaga } from "./OrganizationTypeSaga";
import { OrganizationCategorySaga } from "./OrganizationCategorySaga";
import { IndustrySaga } from "./IndustrySaga";
import { SkillSaga } from "./SkillSaga";
import { LabelSaga } from "./LabelSaga";
import { ContactSaga } from "./ContactSaga";
import { DeviceSaga } from "./DeviceSaga";
import { GenderSaga } from "./GenderSaga";
import { UserResourceSaga } from "./UserResourceSaga";
import { AuditResourceSaga } from "./AuditResourceSaga";
import { PlanSaga } from "./PlanSaga";
import { ProductSaga } from "./ProductSaga";
import { ProductTypeSaga } from "./ProductTypeSaga";
import { ProductCategorySaga } from "./ProductCategorySaga";
import { BrandSaga } from "./BrandSaga";
import { UnitMeasurementSaga } from "./UnitMeasurementSaga";
import { SkuSaga } from "./SkuSaga";
import { StyleSaga } from "./StyleSaga";
import { RoleResourceSaga } from "./RoleResourceSaga";
import { PermissionResourceSaga } from "./PermissionResourceSaga";
import { SmsHistorySaga } from "./SmsHistorySaga";
import { EmailHistorySaga } from "./EmailHistorySaga";
import { ProjectSaga } from "./ProjectSaga";
import { ClientSaga } from "./ClientSaga";
import { TaskSaga } from "./TaskSaga";
import { TaskServiceSaga } from "./TaskServiceSaga";
import { TimesheetSaga } from "./TimesheetSaga";
//  SAGA_REGISTER_IMPORT_COMMENT-DO-NOT-REMOVE-THIS-COMMENT-NOR-CHANGE-IT


// const tenantList = () => {
//   console.log("tenantList");
//
//   const h = HttpClient();
//   console.log(h);
//
//   return h.get('/Tenant/List');
// }
//
// function* fetchTenantList() {
//   // try {
//   console.log('fetchTenantList');
//   const user = yield call(tenantList);
//   // console.log(user.data);
//   yield put({type: FETCH_TENANT_LIST_SUCCESS, payload: user.data});
//   // } catch (e) {
//   // e.then(x => console.log(x));
//   // console.log(e);
//
//   // yield put({type: "USER_FETCH_FAILED", message: e.message});
//   // }
// }


export default function* rootSaga() {
  // yield takeLatest('FETCH_TENANT_LIST', fetchTenantList);

  // yield all([
  //   UserSaga(),
  //   UserSaga1()
  // ]);

  yield all([
    ...UserSaga,
    ...TenantSaga,
    ...GenericAppSaga,
    ...AlertSaga,
    ...SystemSettingSaga,
    ...SystemSettingUserSaga,
    ...NotificationSaga,
    ...UploadedFileSaga,

...UserStatusSaga,
...UserTypeSaga,
...UserTypeSaga,
...JobTitleSaga,
...UserCategorySaga,
...OrganizationSaga,
...OrganizationTypeSaga,
...OrganizationCategorySaga,
...IndustrySaga,
...SkillSaga,
...LabelSaga,
...ContactSaga,
...DeviceSaga,
...GenderSaga,
...UserResourceSaga,
...AuditResourceSaga,
...PlanSaga,
...ProductSaga,
...ProductTypeSaga,
...ProductCategorySaga,
...BrandSaga,
...UnitMeasurementSaga,
...SkuSaga,
...StyleSaga,
...RoleResourceSaga,
...PermissionResourceSaga,
...SmsHistorySaga,
...EmailHistorySaga,
...ProjectSaga,
...ClientSaga,
...TaskSaga,
...TaskServiceSaga,
...TimesheetSaga,
//  DO NOT REMOVE THIS COMMENT NOR CHANGE IT IMPORT
  ]);

}

import { takeLatest } from "redux-saga/effects";
import SkillActions from "./actions/SkillActions";
import GenericAppActions from "./actions/GenericAppActions";
import { SKILL_PAGINATE_URL } from "../../routes/Apps/SkillApp";
import { SKILL_DESTROY, SKILL_STORE, SKILL_UPDATE } from "../action-types/SkillActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(SkillActions.store, action.payload, SKILL_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(SkillActions.update, action.payload, SKILL_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(SkillActions.destroy, action.payload, SKILL_PAGINATE_URL);
}

export const SkillSaga = [
  takeLatest(SKILL_STORE, fetchStore),
  takeLatest(SKILL_UPDATE, fetchUpdate),
  takeLatest(SKILL_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import RoleResourceActions from "./actions/RoleResourceActions";
import GenericAppActions from "./actions/GenericAppActions";
import { ROLE_PAGINATE_URL } from "../../routes/Apps/RoleResourceApp";
import { ROLE_DESTROY, ROLE_STORE, ROLE_UPDATE } from "../action-types/RoleResourceActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(RoleResourceActions.store, action.payload, ROLE_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(RoleResourceActions.update, action.payload, ROLE_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(RoleResourceActions.destroy, action.payload, ROLE_PAGINATE_URL);
}

export const RoleResourceSaga = [
  takeLatest(ROLE_STORE, fetchStore),
  takeLatest(ROLE_UPDATE, fetchUpdate),
  takeLatest(ROLE_DESTROY, fetchDestroy),
];



import React, { Fragment } from "react";
import GenericApp from "../GenericApp";
import ProductCategoryAppForm from "./ProductCategoryAppForm";
import { useDispatch, useSelector } from "react-redux";
import GenericAppUtils from "../GenericApp/GenericAppUtils";
import { useHistory } from "react-router-dom";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import AttachFileIcon from "@material-ui/icons/Attachment";
import { PRODUCT_CATEGORY_DESTROY, PRODUCT_CATEGORY_STORE, PRODUCT_CATEGORY_UPDATE } from "../../../redux/action-types/ProductCategoryActionTypes";

export const PRODUCT_CATEGORY_FRONTEND_INDEX_URL = '/apps/product-category';
export const PRODUCT_CATEGORY_PAGINATE_URL = '/ProductCategory/Paginate';
const NAME = 'product-category';
const TITLE = 'Product Category';
const SUBTITLE = 'App';
const ICON = <AttachFileIcon />;

function TenantApp(props) {

  const dispatch = useDispatch();
  const hist = useHistory();
  let { page, pageLimit } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const intl = useIntl();

  const handleIndex = () => {
    hist.push(PRODUCT_CATEGORY_FRONTEND_INDEX_URL);
  }

  const handleDestroy = (values) => {
    dispatch({type: PRODUCT_CATEGORY_DESTROY, payload: values});
  };

  const handleSubmit = (values, formik) => {
    const type = values.id ?  PRODUCT_CATEGORY_UPDATE : PRODUCT_CATEGORY_STORE;
    dispatch({type: type, payload: values});
  };

  const AppSettings = {
    name: `${NAME}-app`,
    title: <IntlMessages id={TITLE} />,
    subTitle: SUBTITLE,
    searchTitle: intl.formatMessage({ id: 'Search...' }),
    notFoundTitle: <IntlMessages id={'No records found'} />,
    isCreate: true,
    isRead: true,
    isUpdate: true,
    isDelete: true,
    urls: {
      indexFrontend: PRODUCT_CATEGORY_FRONTEND_INDEX_URL
    },
    createButton: {
      title: <IntlMessages id={'Create New'} />,
      icon: null
    },
    headers: [
      <IntlMessages id={'Name'} />,
      <IntlMessages id={'Id'} />
    ],
    menuList: [
      {
        title: "Menu",
        items: [
          { id: 1, name: <IntlMessages id={TITLE} />, slug: `${NAME}-all`, icon: ICON, onClick: handleIndex }
        ]
      }
    ],
    recordMenuList: [
      {
        title: "Record Menu",
        items: [
          // { id: 1, name: "Users", slug: "users", icon: <GroupIcon />, onClick: (e) => console.log(e) }
        ]
      }
    ],
    dispatchActions: {
      paginate: GenericAppUtils.GetPaginateFunc(PRODUCT_CATEGORY_PAGINATE_URL),
    },
    events: {
      onRenderTitle: (item) => (item.name),
      onRenderSubTitle: (item) => (item.description),
      onRenderAvatar: (item) => (null), //(item) => (FileUploaderActions.getDownloadImageUrl(item.slug, false)),
      onRenderExtraCells: (item) => ([item.id]),
      onEditClick : (item) => console.log(item),
      onShowClick : (item) => console.log(item),
      onDeleteClick : handleDestroy,
      onDeleteMultiple : (items) => console.log(items),
      onCreateClick : (e) => console.log(e),
      onStarClick: null, //(item, status) => console.log(item, status),
      onSearchChange: null,//(s) => console.log(s),
      onSearchEnterKeyDown: null, //if this property is null, it will call the default search endpoint
    }
  };

  return (
    <Fragment>
      <GenericApp appSettings={AppSettings} form={<ProductCategoryAppForm onSubmit={handleSubmit}/>} isUseDialog={false} />
    </Fragment>
  );

}

export default TenantApp;

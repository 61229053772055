import React, { useState } from "react";
import * as Yup from "yup";
import { TEXT, DATE_TIME, CURRENCY, INTEGER, TEXT_DISABLED, TEXT_AREA } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";

const FormValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
});

const formObj = {
  name:'email-history-app-form',
  validation: FormValidation,
  isReadOnly: true,
  groups: [
    {
      title: 'User',
      subTitle: '',
      fields: [
        { name: 'userName', label: 'User Name', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'userId', label: 'User Id', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'userOauthId', label: 'User Oauth Id', type: TEXT_DISABLED, fieldProps: {} },
      ]
    },
    {
      title: 'Main',
      subTitle: '',
      fields: [
        { name: 'subject', label: 'Subject', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'sent', label: 'Sent', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'sent', label: 'Sent', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'status', label: 'Status', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'attachmentCount', label: 'Attachment Count', type: TEXT_DISABLED, fieldProps: { } },
        { name: 'from', label: 'From', type: TEXT_AREA, fieldProps: { gridSize: 12 , disabled: true} },
        { name: 'to', label: 'To', type: TEXT_AREA, fieldProps: { gridSize: 12 , disabled: true} },
        { name: 'cc', label: 'Cc', type: TEXT_AREA, fieldProps: { gridSize: 12 , disabled: true} },
        { name: 'bcc', label: 'Bcc', type: TEXT_AREA, fieldProps: { gridSize: 12 , disabled: true} },
        { name: 'message', label: 'Message', type: TEXT_AREA, fieldProps: { gridSize: 12 , disabled: true} },
        { name: 'errorMessage', label: 'Error Message', type: TEXT_AREA, fieldProps: { gridSize: 12 , disabled: true} },
      ]
    },
    {
      title: 'Info',
      fields: [
        { name: 'createdDate', label: 'Created', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'modifiedDate', label: 'Modified', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'deletedDate', label: 'Deleted', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'tenantId', label: 'Tenant ID', type: TEXT_DISABLED, fieldProps: {} },
      ]
    }
  ],
  record: null
}

const EmailHistoryAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState( currentRecord);

  // const handleExtraFields = (formik) => {
  //   return(
  //     <FormikFileUploadField formik={formik} fieldName={'slug'} isPreviewOnly={true} isPublic={false} label={'Slug'}/>
  //   )
  // }

  return (
    <GenericAppFormBuilder  currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} />
  )
}

EmailHistoryAppForm.propTypes = {
  onSubmit: PropTypes.func
}

export default EmailHistoryAppForm;

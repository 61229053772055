import React, { useState } from "react";
import * as Yup from "yup";
import { TEXT, DATE_TIME, CURRENCY, INTEGER, TEXT_DISABLED, CHECKBOX, DATE, FILE_UPLOAD_DROPZONE, TEXT_AREA } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";

const FormValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
});

const formObj = {
  name:'client-app-form',
  validation: FormValidation,
  groups: [
    {
      title: 'Main',
      subTitle: '',
	  isReadOnly: false,
      fields: [
        { name: 'name', label: 'Name', type: TEXT, fieldProps: {} },
        { name: 'email', label: 'Email', type: TEXT, fieldProps: {} },
        { name: 'phone', label: 'Phone', type: TEXT, fieldProps: {} },
        { name: 'cellphone', label: 'Cellphone', type: TEXT, fieldProps: {} },
        { name: "fax", label: "Fax", type: TEXT, fieldProps: {} },
        { name: 'description', label: 'Description', type: TEXT, fieldProps: {} },
        { name: 'type', label: 'Type', type: TEXT, fieldProps: {} },
        { name: 'code', label: 'Code', type: TEXT, fieldProps: {} },
        { name: "statusMessage", label: "Status Message", type: TEXT, fieldProps: {} },
      ]
    },
    {
      title: 'Picture',
      subTitle: '',
      fields: [
        { name: 'profilePictureUrl', label: 'Profile Picture', type: FILE_UPLOAD_DROPZONE, fieldProps: {} },
      ]
    },
    {
      title: "Address",
      fields: [
        { name: "address", label: "Address", type: TEXT, fieldProps: {} },
        { name: "addressNo", label: "Address No", type: TEXT, fieldProps: {} },
        { name: "city", label: "City", type: TEXT, fieldProps: {} },
        { name: "province", label: "State", type: TEXT, fieldProps: {} },
        { name: "zipCode", label: "Zip", type: TEXT, fieldProps: {} },
        { name: "country", label: "Country", type: TEXT, fieldProps: {} }
      ]
    },
    {
      title: "Opt-In",
      subTitle: "",
      fields: [
        { name: "optInSms", label: "SMS", type: CHECKBOX, fieldProps: { } },
        { name: "optInEmail", label: "Email", type: CHECKBOX, fieldProps: {} },
        { name: "optInMail", label: "Mail", type: CHECKBOX, fieldProps: {} },
        { name: "optInPushNotification", label: "Push Notification", type: CHECKBOX, fieldProps: {} },
      ]
    },
    {
      title: "Biography",
      fields: [
        { name: "biography", label: "Biography", type: TEXT_AREA, fieldProps: { gridSize: 12 } }
      ]
    },
    {
      title: "Info",
      fields: [
        { name: "createdDate", label: "Created", type: TEXT_DISABLED, fieldProps: {} },
        { name: "modifiedDate", label: "Modified", type: TEXT_DISABLED, fieldProps: {} },
        { name: "deletedDate", label: "Deleted", type: TEXT_DISABLED, fieldProps: {} }
      ]
    }
  ],
  record: null
}

const ClientAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState( currentRecord);

  // const handleExtraFields = (formik) => {
  //   return(
  //     <FormikFileUploadField formik={formik} fieldName={'slug'} isPreviewOnly={true} isPublic={false} label={'Slug'}/>
  //   )
  // }

  return (
    <GenericAppFormBuilder  currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} />
  )
}

ClientAppForm.propTypes = {
  onSubmit: PropTypes.func
}

export default ClientAppForm;

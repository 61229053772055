// import firebase from 'firebase';
// import {initializeApp} from "firebase/app";
import { GoogleAuthProvider } from "firebase/auth";
// import { getFirestore, collection, getDocs } from "firebase/firestore";
// import { getMessaging, onMessage, getToken  } from "firebase/messaging";
import { firebaseAuth, firebaseDb } from "../../../config/firebase.conf";

// Initialize Firebase
// const config = {
//   apiKey: 'AIzaSyCYaTKjfam_qMXDnGfcdnBxScEq89VQtLk',
//   authDomain: 'curious-sandbox-196209.firebaseapp.com',
//   databaseURL: 'https://curious-sandbox-196209.firebaseio.com',
//   projectId: 'curious-sandbox-196209',
//   storageBucket: '',
//   messagingSenderId: '1034032747860',
// };

// const config = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };

// firebase.initializeApp(config);
const auth = firebaseAuth;  //firebase.auth();

const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = null; //new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = null; //new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = null; //new firebase.auth.TwitterAuthProvider();

const database = firebaseDb;
export { auth, database, googleAuthProvider, githubAuthProvider, facebookAuthProvider, twitterAuthProvider };

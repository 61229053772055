import { takeLatest } from "redux-saga/effects";
import LabelActions from "./actions/LabelActions";
import GenericAppActions from "./actions/GenericAppActions";
import { LABEL_PAGINATE_URL } from "../../routes/Apps/LabelApp";
import { LABEL_DESTROY, LABEL_STORE, LABEL_UPDATE } from "../action-types/LabelActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(LabelActions.store, action.payload, LABEL_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(LabelActions.update, action.payload, LABEL_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(LabelActions.destroy, action.payload, LABEL_PAGINATE_URL);
}

export const LabelSaga = [
  takeLatest(LABEL_STORE, fetchStore),
  takeLatest(LABEL_UPDATE, fetchUpdate),
  takeLatest(LABEL_DESTROY, fetchDestroy),
];



import { takeLatest } from "redux-saga/effects";
import OrganizationCategoryActions from "./actions/OrganizationCategoryActions";
import GenericAppActions from "./actions/GenericAppActions";
import { ORGANIZATION_CATEGORY_PAGINATE_URL } from "../../routes/Apps/OrganizationCategoryApp";
import { ORGANIZATION_CATEGORY_DESTROY, ORGANIZATION_CATEGORY_STORE, ORGANIZATION_CATEGORY_UPDATE } from "../action-types/OrganizationCategoryActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(OrganizationCategoryActions.store, action.payload, ORGANIZATION_CATEGORY_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(OrganizationCategoryActions.update, action.payload, ORGANIZATION_CATEGORY_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(OrganizationCategoryActions.destroy, action.payload, ORGANIZATION_CATEGORY_PAGINATE_URL);
}

export const OrganizationCategorySaga = [
  takeLatest(ORGANIZATION_CATEGORY_STORE, fetchStore),
  takeLatest(ORGANIZATION_CATEGORY_UPDATE, fetchUpdate),
  takeLatest(ORGANIZATION_CATEGORY_DESTROY, fetchDestroy),
];



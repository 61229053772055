import React, { Fragment } from "react";
import GenericApp from "../GenericApp";
import TenantAppForm from "./TenantAppForm";
import { useDispatch, useSelector } from "react-redux";
import NotificationsIcon from "@material-ui/icons/Notifications";
import GenericAppUtils from "../GenericApp/GenericAppUtils";
import { useHistory } from "react-router-dom";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import { TENANT_DESTROY } from "../../../redux/action-types/TenantActionTypes";
import FileUploaderActions from "../../../redux/sagas/actions/FileUploaderActions";

export const TENANT_FRONTEND_INDEX_URL = '/apps/tenants';
export const TENANT_PAGINATE_URL = '/TenantResource/Paginate';

function TenantApp(props) {

  const dispatch = useDispatch();
  const hist = useHistory();
  let { page, pageLimit } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const intl = useIntl();

  const handleIndex = () => {
    // dispatchSearch('');
    hist.push(TENANT_FRONTEND_INDEX_URL);
  }

  const handleDestroy = (values) => {
    dispatch({type: TENANT_DESTROY, payload: values});
  };

  // const handleNotificationsUnread = () => {
  //   dispatchSearch('notification-unread');
  //   hist.push(TENANT_FRONTEND_INDEX_URL);
  //   const func = GenericAppUtils.GetPaginateFunc(TENANT_PAGINATE_URL);
  //   dispatch({type: TENANT_UNREAD, payload: func(page, pageLimit, 0)});
  // }

  // const dispatchSearch = (s) => {
  //   const fType ={
  //     selectedFolder: s ? '' : 'contacts',
  //     selectedLabel: '',
  //     searchText: s || '',
  //   };
  //
  //   dispatch({type: GENERIC_APP_SET_FILTER_TYPE, payload: fType});
  // }

  const AppSettings = {
    name: "tenant-app",
    title: <IntlMessages id={'Tenants'} />,
    subTitle: "App",
    searchTitle: intl.formatMessage({ id: 'Search Tenants' }),
    notFoundTitle: <IntlMessages id={'No records found'} />,
    isCreate: true,
    isRead: true,
    isUpdate: true,
    isDelete: true,
    urls: {
      indexFrontend: TENANT_FRONTEND_INDEX_URL
    },
    createButton: {
      title: <IntlMessages id={'Create New'} />,
      icon: null
    },
    headers: [
      <IntlMessages id={'Name'} />,
      <IntlMessages id={'Id'} />,
    ],
    menuList: [
      {
        title: "Menu",
        items: [
          { id: 1, name: <IntlMessages id={'Tenants'} />, slug: "tenants-all", icon: <NotificationsIcon />, onClick: handleIndex }
        ]
      }
    ],
    recordMenuList: [
      {
        title: "Record Menu",
        items: [
          // { id: 1, name: "Users", slug: "users", icon: <GroupIcon />, onClick: (e) => console.log(e) }
        ]
      }
    ],
    dispatchActions: {
      paginate: GenericAppUtils.GetPaginateFunc(TENANT_PAGINATE_URL),
    },
    events: {
      onRenderTitle: (item) => (item.name),
      onRenderSubTitle: (item) => (item.description),
      onRenderAvatar: (item) => (FileUploaderActions.getDownloadImageUrl(item.avatarUrlSmall, false)),
      onRenderExtraCells: (item) => ([item.id]),
      onEditClick : (item) => console.log(item),
      onShowClick : (item) => console.log(item),
      onDeleteClick : handleDestroy,
      onDeleteMultiple : (items) => console.log(items),
      onCreateClick : (e) => console.log(e),
      onStarClick: null, //(item, status) => console.log(item, status),
      onSearchChange: null,//(s) => console.log(s),
      onSearchEnterKeyDown: null, //if this property is null, it will call the default search endpoint
    }
  };

  return (
    <Fragment>
      <GenericApp appSettings={AppSettings} form={<TenantAppForm/>} isUseDialog={false} />
    </Fragment>

  );

}

export default TenantApp;

import * as React from "react";
import { Paper, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import IntlMessages from "../../@jumbo/utils/IntlMessages";

const FormGroupTitle = (props) => {
  return (
    <Paper elevation={0} style={{
      padding: 10
    }}>
      <Typography variant="h1" gutterBottom component="div"><IntlMessages id={props.title}/></Typography>
      {props.subTitle &&
      <Typography variant="caption" display="block" color={"textSecondary"}>
        <IntlMessages id={props.subTitle}/>
      </Typography>}
    </Paper>
  );
};

FormGroupTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
}

export default FormGroupTitle;

import React, { useContext } from "react";
import Box from '@material-ui/core/Box';
import CmtAvatar from '../../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import useStyles from '../ContactCell.style';
import PropTypes from 'prop-types';
import ContactCellOptions from '../ListTableView/ContactCellOptions';
import { GenericAppContext } from "../../GenericAppContext";
import TableCell from "@material-ui/core/TableCell";

const ContactCell = ({ contact, onShowContactDetail, onClickEditContact }) => {
  const classes = useStyles();
  const { firstName, email, phones, company, designation, profile_pic } = contact;
  const appSettings = useContext(GenericAppContext);
  return (
    <Box className={classes.gridContactCell} onClick={() => onShowContactDetail(contact)}>
      <Box className={classes.gridContactCellHeader} display="flex" mb={3}>
        <Box width={{ sm: 'calc(100% - 56px)' }} display="flex" alignItems="center">
          <Box mr={4}>
            <CmtAvatar size={40} src={appSettings.events.onRenderAvatar(contact) || ''} alt={appSettings.events.onRenderTitle(contact)} />
          </Box>

          <Box width="calc(100% - 56px)">
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {appSettings.events.onRenderTitle(contact)}
            </Typography>
            <Typography className={classes.subTitleRoot}>{appSettings.events.onRenderSubTitle(contact)}</Typography>
          </Box>
        </Box>
        <Box ml={{ sm: 'auto' }} onClick={e => e.stopPropagation()}>
          <ContactCellOptions contact={contact} onClickEditContact={onClickEditContact} />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column">
        {appSettings.events.onRenderExtraCells(contact).map(str => {
          return(
            <Box mb={2} component="p" className={classes.textTruncate}>
              {str}
            </Box>
          )
        })}
      </Box>
    </Box>
  );
};

export default ContactCell;

ContactCell.prototype = {
  contact: PropTypes.object.isRequired,
  onShowContactDetail: PropTypes.func,
  onClickEditContact: PropTypes.func,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import ImageAuth from "../file-upload/ImageAuth";
import FileUploaderDropzone from "../file-upload/FileUploaderDropZone";
import AlertActions from "../../redux/sagas/actions/AlertActions";
import DeleteIcon from "@material-ui/icons/Delete";
import { CloudDownload } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import FileUploaderActions from "../../redux/sagas/actions/FileUploaderActions";
import FieldContainer from "../form-component/container/FieldContainer";

const FileInfoTable = (props) => {
  return(
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items && props.items.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <b>{row.name}</b>
              </TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  )
}

const FormikFileUploadField = (props) => {
  const pr = props.formik;

  const [value, setValue] = useState(pr.values && pr.values[props.fieldName]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const vUrl = FileUploaderActions.getDownloadImageUrl(value, props.isPublic);

  const handleUploadSuccess = (dataResp, file) => {
    pr.setFieldValue(props.fieldName, dataResp.slug);
    setValue(dataResp.slug);
    AlertActions.showToast('Picture Uploaded!', 'success');
  }

  const handleClearClick = (e) => {
    pr.setFieldValue(props.fieldName, null);
    setValue(null);
  }

  const handleInfoClick = (e) => {

    FileUploaderActions.getFileInfo(value).then(r => {
      const items = [];
      for (const key in r.data) {
        const it = {name: key, value: r.data[key]}
        items.push(it);
      }
      AlertActions.showDialogHtml('','', <FileInfoTable items={items}/>, 0);
    });
    // pr.setFieldValue(props.fieldName, null);
    // setValue(null);
  }

  const handleDownloadClick = (e) => {
    window.open(vUrl);
  }

  const removeComp = value && (
    <div>
      <IconButton aria-label="delete" onClick={handleClearClick} size="small">
        <DeleteIcon fontSize="small" color={"secondary"}/>
      </IconButton>
      <IconButton aria-label="download" size="small" onClick={handleDownloadClick}>
        <CloudDownload fontSize="small" color={"primary"}/>
      </IconButton>
      <IconButton aria-label="info" onClick={handleInfoClick} size="small">
        <InfoIcon fontSize="small"/>
      </IconButton>
    </div>
  );

  const handleImageClick = (e) => {
    AlertActions.showDialogImage('',value, vUrl,0);
  }

  return(
    <FieldContainer {...props} >
      <Typography variant={'p'}>{props.label}</Typography>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <ListItem>
          <ListItemAvatar>
            {value &&
                <ImageAuth slug={value} isPublic={props.isPublic} onClick={handleImageClick} src={vUrl} />
            }
          </ListItemAvatar>
          <ListItemText primary={<Typography variant={'p'}>{value?.length > 10 ? value.substr(0,10) + ' ...': value}</Typography>} secondary={removeComp} />
        </ListItem>
      </List>
      {(!props.isPreviewOnly) &&
      <FileUploaderDropzone isPublic={props.isPublic} onUploadSuccess={handleUploadSuccess} />
      }
    </FieldContainer>
  )
}

FormikFileUploadField.defaultProps ={
  isSetName: true,
  isPublic: false,
  isPreviewOnly: false,
}

FormikFileUploadField.propTypes ={
  fieldName: PropTypes.string,
  formik: PropTypes.any,
  isPublic: PropTypes.bool,
  isPreviewOnly: PropTypes.bool
}

export default FormikFileUploadField;

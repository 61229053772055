import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField } from "@material-ui/core";
import DateAdapter from "@mui/lab/AdapterMoment";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import FieldContainer from "../form-component/container/FieldContainer";

const FormikDateTimeField = (props) => {
  const pr = props.formik;

  const handleChange = (e) => {
    pr.setFieldValue(props.fieldName, e)
  }

  return(
    <FieldContainer {...props} >
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DateTimePicker
          {...props}
          fullWidth={true}
          value={pr.values && pr.values[props.fieldName]}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} variant={'outlined'} size="small" fullWidth={true} error={Boolean(pr.errors[props.fieldName])} helperText={pr.errors[props.fieldName]} />}
        />
      </LocalizationProvider>
    </FieldContainer>
  )
}

FormikDateTimeField.defaultProps ={

}
FormikDateTimeField.propTypes ={
  fieldName: PropTypes.string,
  formik: PropTypes.any
}

export default FormikDateTimeField;

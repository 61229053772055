import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { call, put } from "redux-saga/effects";
import { GENERIC_APP_SET_FORM_CONTAINER_OPEN } from "../../action-types/GenericAppActionTypes";

const MySwal = withReactContent(Swal);

function showDialog(action) {
  return new Promise(resolve => {
    MySwal.fire({
      target: document.getElementById(action.target),
      position: action.position,
      icon: action.icon,
      title: action.title,
      text: action.text,
      timer: action.timer || 0,
      imageUrl: action.imageUrl,
      html: action.html,
      onClose(popup) {
        resolve(popup);
      }
    });
  });
}

function showDialogImage(title, text, imageUrl, timer = 3000, aTarget = 'generic-app-dialog') {
  return showDialog({title: title, text: text, imageUrl: imageUrl, timer: timer, target: aTarget});
}

function showDialogHtml(title, text, html, timer = 3000, aTarget = 'generic-app-dialog') {
  return showDialog({title: title, text: text, html: html, timer: timer, target: aTarget});
}



function showDialogSuccess(title, text, timer = 3000, html = '', aTarget = 'generic-app-dialog') {
  return showDialog({title: title, text: text, icon: 'success', timer: timer, html: html, target: aTarget});
}

function showDialogWarning(title, text, timer = 3000, html = '', aTarget = 'generic-app-dialog') {
  return showDialog({title: title, text: text, icon: 'warning', timer: timer, html: html, target: aTarget});
}

function showDialogInfo(title, text, timer = 3000, html = '', aTarget = 'generic-app-dialog') {
  return showDialog({title: title, text: text, icon: 'info', timer: timer, html: html, target: aTarget});
}

function showDialogError(title, text, timer = 0, html = '', aTarget = 'generic-app-dialog') {
  return showDialog({title: title, text: text, icon: 'error', timer: timer, html: html, target: aTarget});
}

function* showDialogGen(obj, aIsCloseGenericAppContainer = true){
  const opts = {
    title: '',
    text: '',
    position: 'center',
    icon: 'success',
    timer: 3000,
    show: true,
    ...obj
  };

  yield call(showDialog, opts);

  if(aIsCloseGenericAppContainer){
    yield put({type: GENERIC_APP_SET_FORM_CONTAINER_OPEN, payload: false});
  }
}

function* hideDialogGen(obj){
  const opts = {
    title: '',
    text: '',
    position: 'center',
    icon: 'success',
    timer: 5000,
    show: false,
    ...obj
  };

  yield call(showDialog, opts);
}

function showToast(aMsg, variant, aTimer = 3000, aPosition = 'top-end', aTarget = 'generic-app-dialog'){
  const Toast = MySwal.mixin({
    toast: true,
    position: aPosition,
    showConfirmButton: false,
    timer: aTimer,
    timerProgressBar: true,
    onOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  Toast.fire({
    target: document.getElementById(aTarget),
    icon: variant,
    title: aMsg,
  });
}

function showToastSuccess(title, aTimer = 3000, aPosition = 'top-end', aTarget = 'generic-app-dialog') {
  return showToast(title, 'success', aTimer, aPosition, aTarget);
}

function showToastInfo(title, aTimer = 3000, aPosition = 'top-end', aTarget = 'generic-app-dialog') {
  return showToast(title, 'info', aTimer, aPosition, aTarget);
}

function showToastWarning(title, aTimer = 3000, aPosition = 'top-end', aTarget = 'generic-app-dialog') {
  return showToast(title, 'warning', aTimer, aPosition, aTarget);
}

function showToastError(title, aTimer = 3000, aPosition = 'top-end', aTarget = 'generic-app-dialog') {
  return showToast(title, 'error', aTimer, aPosition, aTarget);
}

export default {
  showDialogGen,
  hideDialogGen,
  showDialog,
  showDialogSuccess,
  showDialogWarning,
  showDialogInfo,
  showDialogError,
  showToast,
  showToastSuccess,
  showToastInfo,
  showToastWarning,
  showToastError,
  showDialogImage,
  showDialogHtml
}

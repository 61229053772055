import HttpClient from "../../../utils/HttpClient";

const tenantList = () => {
  return HttpClient.HttpClientWebApi().get('/Tenant/List');
}

const updateCurrentTenant = (aTenantId) => {
  return HttpClient.HttpClientWebApi().post('/Tenant/UpdateCurrentUserTenant', aTenantId)
}

const store = (aObj) => {
  return HttpClient.HttpClientWebApi().post('/TenantResource/Store', aObj);
}

const update = (aObj) => {
  return HttpClient.HttpClientWebApi().put(`/TenantResource/Update/${aObj.id}`, aObj);
}

const destroy = (aObj) => {
  return HttpClient.HttpClientWebApi().delete(`/TenantResource/Destroy/${aObj.id}`);
}


export default {
  tenantList,
  updateCurrentTenant,
  store,
  update,
  destroy
}

import React, { useState } from "react";
import * as Yup from "yup";
import { TEXT, DATE_TIME, CURRENCY, INTEGER, COMPONENT, DATE, CHECKBOX, TEXT_DISABLED } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import PropTypes from "prop-types";
import GenderAutocomplete from "../../../components/form-component/autocomplete/GenderAutocomplete";
import ProjectAutocomplete from "../../../components/form-component/autocomplete/ProjectAutocomplete";
import ClientAutocomplete from "../../../components/form-component/autocomplete/ClientAutocomplete";
import TaskServiceAutocomplete from "../../../components/form-component/autocomplete/TaskServiceAutocomplete";
import TimesheetEntriesFieldArray from "./TimesheetEntriesFieldArray";

const FormValidation = Yup.object().shape({
  project: Yup.object().shape({
      id: Yup.number().required()
  }),
  client: Yup.object().shape({
    id: Yup.number().required()
  }),
  taskService: Yup.object().shape({
    id: Yup.number().required()
  }),
  timesheetEntries: Yup.array().of(
    Yup.object().shape({
      entryDate: Yup.date().required(),
      quantity: Yup.number().positive().required()
    })
  )
});

// public Project  { get; set; }
// public Client  { get; set; }
// public  TaskService { get; set; }
// public bool Billable { get; set; }
// public bool Closed { get; set; }
// public DateTime ClosedDate { get; set; }
//
// public long TenantId { get; set; }
// public string TenantContext { get; set; }
//
// public ICollection<TimesheetEntry> TimesheetEntries { get; set; } = new List<TimesheetEntry>();

const formObj = {
  name: "timesheet-app-form",
  validation: FormValidation,
  groups: [
    {
      title: "Main",
      subTitle: "",
      isReadOnly: false,
      fields: [
        { name: "project", label: "Project", type: COMPONENT, fieldProps: { component: ProjectAutocomplete } },
        { name: "client", label: "Client", type: COMPONENT, fieldProps: { component: ClientAutocomplete } },
        { name: "taskService", label: "Task Service", type: COMPONENT, fieldProps: { component: TaskServiceAutocomplete } },
        { name: "closedDate", label: "Closed Date", type: DATE, fieldProps: {} },
        { name: "billable", label: "Billable", type: CHECKBOX, fieldProps: {} },
        { name: "closed", label: "Closed", type: CHECKBOX, fieldProps: {} }
      ]
    },
    {
      title: "Info",
      fields: [
        { name: "createdDate", label: "Created", type: TEXT_DISABLED, fieldProps: {} },
        { name: "modifiedDate", label: "Modified", type: TEXT_DISABLED, fieldProps: {} },
        { name: "deletedDate", label: "Deleted", type: TEXT_DISABLED, fieldProps: {} }
      ]
    }
  ],
  record: null
};

const TimesheetAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState(currentRecord);

  const handleExtraFields = (formik) => {
    return(
      <TimesheetEntriesFieldArray formik={formik}/>
    )
  }

  return (
    <GenericAppFormBuilder currentRecord={obj} formObj={formObj} onSubmit={props.onSubmit} onAddExtraFields={handleExtraFields} />
  );
};

TimesheetAppForm.propTypes = {
  onSubmit: PropTypes.func
};

export default TimesheetAppForm;

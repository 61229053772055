import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField } from "@material-ui/core";
import DateAdapter from "@mui/lab/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import FieldContainer from "../form-component/container/FieldContainer";
import moment from "moment";

const FormikDateField = (props) => {
  const pr = props.formik;

  const handleChange = (e) => {
    pr.setFieldValue(props.fieldName, e)
  }

  return(
    <FieldContainer {...props} >
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DesktopDatePicker
          {...props}
          size="small"
          inputFormat="L"
          value={pr.values && (props.isUtc? moment(pr.values[props.fieldName]).utc(): pr.values[props.fieldName])}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} variant={'outlined'} size="small" fullWidth={true} error={Boolean(pr.errors[props.fieldName])} helperText={pr.errors[props.fieldName]} />}
          defaultValue={null}
        />
      </LocalizationProvider>
    </FieldContainer>
  )

}

FormikDateField.defaultProps ={
  isUtc: true

}
FormikDateField.propTypes ={
  fieldName: PropTypes.string,
  formik: PropTypes.any,
  isUtc: PropTypes.bool
}

export default FormikDateField;

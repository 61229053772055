import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import PageLoader from "../../@jumbo/components/PageComponents/PageLoader";
import { HashRouter } from "react-router-dom";

const Dashboards = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, "");
  // console.log(requestedUrl);
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {/*<Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/to-do`} />*/}
        {/*<Route path={`${requestedUrl}/to-do`} component={lazy(() => import('./TaskList'))} />*/}
        {/*<Route path={`${requestedUrl}/mail`} component={lazy(() => import('./MailApp'))} />*/}
        {/*<Route path={`${requestedUrl}/contact`} component={lazy(() => import('./ContactApp'))} />*/}
        {/*<Route path={`${requestedUrl}/generic`} component={lazy(() => import('./GenericApp'))} />*/}
        {/*<Route path={`${requestedUrl}/user`} component={lazy(() => import('./UserApp'))} />*/}
        <Route path={`${requestedUrl}/settings`} component={lazy(() => import("./SystemSettingApp"))} />
        <Route path={`${requestedUrl}/user-settings`} component={lazy(() => import("./SystemSettingUserApp"))} />
        <Route path={`${requestedUrl}/tenant-settings`} component={lazy(() => import("./SystemSettingTenantApp"))} />
        <Route path={`${requestedUrl}/notifications`} component={lazy(() => import("./NotificationApp"))} />
        <Route path={`${requestedUrl}/tenants`} component={lazy(() => import("./TenantApp"))} />
        <Route path={`${requestedUrl}/uploaded-files`} component={lazy(() => import("./UploadedFileApp"))} />
        {/*<Route path={`${requestedUrl}/chat`} component={lazy(() => import('./Chat'))} />*/}
        {/*<Route path={`${requestedUrl}/kanban-board`} component={lazy(() => import('./KanbanBoard'))} />*/}
        <Route path={`${requestedUrl}/profile`} component={lazy(() => import("./Profile"))} />
        {/*<Route path={`${requestedUrl}/social-apps/wall`} component={lazy(() => import('./Wall'))} />*/}


        <Route path={`${requestedUrl}/user-status`} component={lazy(() => import("./UserStatusApp"))} />
        <Route path={`${requestedUrl}/user-type`} component={lazy(() => import("./UserTypeApp"))} />
        <Route path={`${requestedUrl}/job-title`} component={lazy(() => import("./JobTitleApp"))} />
        <Route path={`${requestedUrl}/user-category`} component={lazy(() => import("./UserCategoryApp"))} />
        <Route path={`${requestedUrl}/organization`} component={lazy(() => import("./OrganizationApp"))} />
        <Route path={`${requestedUrl}/organization-type`} component={lazy(() => import("./OrganizationTypeApp"))} />
        <Route path={`${requestedUrl}/organization-category`} component={lazy(() => import("./OrganizationCategoryApp"))} />
        <Route path={`${requestedUrl}/industry`} component={lazy(() => import("./IndustryApp"))} />
        <Route path={`${requestedUrl}/skill`} component={lazy(() => import("./SkillApp"))} />
        <Route path={`${requestedUrl}/label`} component={lazy(() => import("./LabelApp"))} />
        <Route path={`${requestedUrl}/contact`} component={lazy(() => import("./ContactApp"))} />
        <Route path={`${requestedUrl}/device`} component={lazy(() => import("./DeviceApp"))} />
        <Route path={`${requestedUrl}/gender`} component={lazy(() => import("./GenderApp"))} />
        <Route path={`${requestedUrl}/user`} component={lazy(() => import("./UserResourceApp"))} />
        <Route path={`${requestedUrl}/audit-logs`} component={lazy(() => import("./AuditResourceApp"))} />
        <Route path={`${requestedUrl}/plan`} component={lazy(() => import("./PlanApp"))} />
        <Route path={`${requestedUrl}/product`} component={lazy(() => import("./ProductApp"))} />
        <Route path={`${requestedUrl}/product-type`} component={lazy(() => import("./ProductTypeApp"))} />
        <Route path={`${requestedUrl}/product-category`} component={lazy(() => import("./ProductCategoryApp"))} />
        <Route path={`${requestedUrl}/brand`} component={lazy(() => import("./BrandApp"))} />
        <Route path={`${requestedUrl}/unit-measurement`} component={lazy(() => import("./UnitMeasurementApp"))} />
        <Route path={`${requestedUrl}/sku`} component={lazy(() => import("./SkuApp"))} />
        <Route path={`${requestedUrl}/style`} component={lazy(() => import("./StyleApp"))} />
        <Route path={`${requestedUrl}/role`} component={lazy(() => import("./RoleResourceApp"))} />
        <Route path={`${requestedUrl}/permission`} component={lazy(() => import("./PermissionResourceApp"))} />
        <Route path={`${requestedUrl}/sms-history`} component={lazy(() => import("./SmsHistoryApp"))} />
        <Route path={`${requestedUrl}/email-history`} component={lazy(() => import("./EmailHistoryApp"))} />
        <Route path={`${requestedUrl}/project`} component={lazy(() => import("./ProjectApp"))} />
        <Route path={`${requestedUrl}/client`} component={lazy(() => import("./ClientApp"))} />
        <Route path={`${requestedUrl}/task`} component={lazy(() => import("./TaskApp"))} />
        <Route path={`${requestedUrl}/task-service`} component={lazy(() => import("./TaskServiceApp"))} />
        <Route path={`${requestedUrl}/timesheet`} component={lazy(() => import('./TimesheetApp'))} />
        <Route path={`${requestedUrl}/timesheet-entry`} component={lazy(() => import('./TimesheetApp/TimesheetEntryWrapper'))} />
{/*DO NOT REMOVE THIS COMMENT NOR CHANGE IT*/}
        {/*<Route component={lazy(() => import('../ExtraPages/404'))} />*/}
      </Switch>
    </Suspense>
  );
};

export default Dashboards;

import { takeLatest } from "redux-saga/effects";
import OrganizationActions from "./actions/OrganizationActions";
import GenericAppActions from "./actions/GenericAppActions";
import { ORGANIZATION_PAGINATE_URL } from "../../routes/Apps/OrganizationApp";
import { ORGANIZATION_DESTROY, ORGANIZATION_STORE, ORGANIZATION_UPDATE } from "../action-types/OrganizationActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(OrganizationActions.store, action.payload, ORGANIZATION_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(OrganizationActions.update, action.payload, ORGANIZATION_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(OrganizationActions.destroy, action.payload, ORGANIZATION_PAGINATE_URL);
}

export const OrganizationSaga = [
  takeLatest(ORGANIZATION_STORE, fetchStore),
  takeLatest(ORGANIZATION_UPDATE, fetchUpdate),
  takeLatest(ORGANIZATION_DESTROY, fetchDestroy),
];



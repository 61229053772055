export const msalConfig = {
  auth: {
    clientId: "81cb2722-b33e-485e-9dfa-1c87b913e25c",
    authority: "https://vinsystems.b2clogin.com/vinsystems.onmicrosoft.com/b2c_1_susi",
    knownAuthorities: ['vinsystems.b2clogin.com'],
    redirectUri: 'http://localhost:3000',
    postLogoutRedirectUri: "http://localhost:3000"
  }
};

export const loginRequest = {
  scopes: ["https://vinsystems.onmicrosoft.com/8357388c-da39-44aa-847f-3ae4e58a1cee/access_as_user"]
}

export function isDevelopment(){
  return process.env.REACT_APP_ENV === 'development'
}

export function isProduction(){
  return process.env.REACT_APP_ENV === 'production'
}



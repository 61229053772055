import React, { useState } from "react";
import * as Yup from "yup";
import { TEXT, TEXT_DISABLED } from "../../../components/form/VinSystemsFormBuilderFieldTypes";
import { useDispatch, useSelector } from "react-redux";
import GenericAppFormBuilder from "../GenericApp/GenericAppFormBuilder";
import { USER_STORE } from "../../../redux/action-types/UserActionTypes";
import { SYSTEM_SETTING_STORE } from "../../../redux/action-types/SystemSettingTypes";

const FormValidation = Yup.object().shape({
  group: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  value: Yup.string().required("Required"),
  type: Yup.string().required("Required")
});

const formObj = {
  name:'profile-main-form',
  validation: FormValidation,
  groups: [
    {
      title: 'Main',
      subTitle: 'My description',
      fields: [
        { name: 'group', label: 'Group', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'name', label: 'Name', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'value', label: 'Value', type: TEXT, fieldProps: {} },
        { name: 'type', label: 'Type', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'createdDate', label: 'Created', type: TEXT_DISABLED, fieldProps: {} },
        { name: 'modifiedDate', label: 'Modified', type: TEXT_DISABLED, fieldProps: {} }
      ]
    }
  ],
  record: null
}

const SystemSettingAppForm = (props) => {
  const { currentRecord } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const [obj, setObj] = useState( currentRecord);
  const dispatch = useDispatch();

  const handleSubmit = (values, formik) => {
    dispatch({type: SYSTEM_SETTING_STORE, payload: values});
  };

  return (
    <GenericAppFormBuilder  currentRecord={obj} formObj={formObj} onSubmit={handleSubmit} />
  )
}

export default SystemSettingAppForm;

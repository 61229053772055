import HttpClient from "../../../utils/HttpClient";

const getUserNotificationsPage = (aUrl, aPageObj, aIsUnread = true) => {
  let p = new URLSearchParams(aPageObj);
  if(aIsUnread){
    return HttpClient.HttpClientWebApi().get('/NotificationUser/PaginateUnread?'+p.toString());
  }
  return HttpClient.HttpClientWebApi().get('/NotificationUser/Paginate?'+p.toString());
}

const getUserNotificationsUnreadPageFunc = (aUrl, aPageObj) => {
  return () => {
    let p = new URLSearchParams(aPageObj);
    return HttpClient.HttpClientWebApi().get('/NotificationUser/PaginateUnread?'+p.toString());
  }
}

const setUserNotificationsReadAt = (aNotificationId, aReadAt) => {
    return HttpClient.HttpClientWebApi().post('/NotificationUser/SetReadAt', { notificationId: aNotificationId, readAt: aReadAt });
}

// const searchNotification = (obj) => {
//   let p = new URLSearchParams({ s: obj });
//   return HttpClient().get(`/SystemSetting/Search?${p.toString()}`);
// }

export default {
  getUserNotificationsPage,
  getUserNotificationsUnreadPageFunc,
  setUserNotificationsReadAt
}

import React, { Fragment } from "react";
import GenericApp from "../GenericApp";
import UploadedFileAppForm from "./UploadedFileAppForm";
import { useDispatch, useSelector } from "react-redux";
import NotificationsIcon from "@material-ui/icons/Notifications";
import GenericAppUtils from "../GenericApp/GenericAppUtils";
import { useHistory } from "react-router-dom";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import { UPLOADED_FILE_DESTROY, UPLOADED_FILE_STORE, UPLOADED_FILE_UPDATE } from "../../../redux/action-types/UploadedFileActionTypes";
import FileUploaderActions from "../../../redux/sagas/actions/FileUploaderActions";
import AttachFileIcon from "@material-ui/icons/Attachment";

export const UPLOADED_FILE_FRONTEND_INDEX_URL = '/apps/uploaded-files';
export const UPLOADED_FILE_PAGINATE_URL = '/UploadedFile/Paginate';

function TenantApp(props) {

  const dispatch = useDispatch();
  const hist = useHistory();
  let { page, pageLimit } = useSelector(({ genericAppReducer }) => genericAppReducer);
  const intl = useIntl();

  const handleIndex = () => {
    hist.push(UPLOADED_FILE_FRONTEND_INDEX_URL);
  }

  const handleDestroy = (values) => {
    dispatch({type: UPLOADED_FILE_DESTROY, payload: values});
  };

  const handleSubmit = (values, formik) => {
    const type = values.id ?  UPLOADED_FILE_UPDATE : UPLOADED_FILE_STORE;
    dispatch({type: type, payload: values});
  };

  const AppSettings = {
    name: "uploaded-files-app",
    title: <IntlMessages id={'Uploaded Files'} />,
    subTitle: "App",
    searchTitle: intl.formatMessage({ id: 'Search...' }),
    notFoundTitle: <IntlMessages id={'No records found'} />,
    isCreate: false,
    isRead: true,
    isUpdate: true,
    isDelete: true,
    urls: {
      indexFrontend: UPLOADED_FILE_FRONTEND_INDEX_URL
    },
    createButton: {
      title: <IntlMessages id={'Create New'} />,
      icon: null
    },
    headers: [
      <IntlMessages id={'File Name'} />,
      <IntlMessages id={'Id'} />,
      <IntlMessages id={'Size Bytes'} />,
      <IntlMessages id={'Mime Type'} />,
    ],
    menuList: [
      {
        title: "Menu",
        items: [
          { id: 1, name: <IntlMessages id={'Uploaded Files'} />, slug: "uploaded-files-all", icon: <AttachFileIcon />, onClick: handleIndex }
        ]
      }
    ],
    recordMenuList: [
      {
        title: "Record Menu",
        items: [
          // { id: 1, name: "Users", slug: "users", icon: <GroupIcon />, onClick: (e) => console.log(e) }
        ]
      }
    ],
    dispatchActions: {
      paginate: GenericAppUtils.GetPaginateFunc(UPLOADED_FILE_PAGINATE_URL),
    },
    events: {
      onRenderTitle: (item) => (item.fileName),
      onRenderSubTitle: (item) => (item.fileNameOriginal),
      onRenderAvatar: (item) => (FileUploaderActions.getDownloadImageUrl(item.slug, false)),
      onRenderExtraCells: (item) => ([item.id, item.sizeBytes, item.mimeType]),
      onEditClick : (item) => console.log(item),
      onShowClick : (item) => console.log(item),
      onDeleteClick : handleDestroy,
      onDeleteMultiple : (items) => console.log(items),
      onCreateClick : (e) => console.log(e),
      onStarClick: null, //(item, status) => console.log(item, status),
      onSearchChange: null,//(s) => console.log(s),
      onSearchEnterKeyDown: null, //if this property is null, it will call the default search endpoint
    }
  };

  return (
    <Fragment>
      <GenericApp appSettings={AppSettings} form={<UploadedFileAppForm onSubmit={handleSubmit}/>} isUseDialog={false} />
    </Fragment>

  );

}

export default TenantApp;

import { takeLatest } from "redux-saga/effects";
import TaskActions from "./actions/TaskActions";
import GenericAppActions from "./actions/GenericAppActions";
import { TASK_PAGINATE_URL } from "../../routes/Apps/TaskApp";
import { TASK_DESTROY, TASK_STORE, TASK_UPDATE } from "../action-types/TaskActionTypes";

function* fetchStore(action) {
  yield GenericAppActions.fetchServer(TaskActions.store, action.payload, TASK_PAGINATE_URL);
}

function* fetchUpdate(action) {
  yield GenericAppActions.fetchServer(TaskActions.update, action.payload, TASK_PAGINATE_URL);
}

function* fetchDestroy(action) {
  yield GenericAppActions.fetchServer(TaskActions.destroy, action.payload, TASK_PAGINATE_URL);
}

export const TaskSaga = [
  takeLatest(TASK_STORE, fetchStore),
  takeLatest(TASK_UPDATE, fetchUpdate),
  takeLatest(TASK_DESTROY, fetchDestroy),
];



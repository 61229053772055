import React from "react";
import { AccountCircle, Apartment, ArrowRight, Assessment, CalendarToday, Contactless, House, Lock, Person, PostAdd, Store, Timeline } from "@material-ui/icons";
import IntlMessages from "../../../utils/IntlMessages";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { SUPER_ADMIN } from "../../../constants/PermissionConsts";
import AttachFileIcon from "@material-ui/icons/Attachment";

const sidebarNavsUnsorted = [
  {
    name: <IntlMessages id={"Super Admin"} />,
    icon: <Lock />,
    type: "collapse",
    requiredPermissions: [SUPER_ADMIN],
    sorted: true,
    children: [
      {
        id: "Tenant",
        name: <IntlMessages id={"Tenant"} />,
        type: "item",
        icon: <House />,
        link: "/apps/tenants",
        requiredPermissions: []
      },
      {
        id: "System Settings",
        name: <IntlMessages id={"System Settings"} />,
        type: "item",
        icon: <SettingsIcon />,
        link: "/apps/settings",
        requiredPermissions: []
      },
      {
        id: "Uploaded Files",
        name: <IntlMessages id={"Uploaded Files"} />,
        type: "item",
        icon: <AttachFileIcon />,
        link: "/apps/uploaded-files",
        requiredPermissions: []
      },
      {
        id: "Audit Logs",
        name: <IntlMessages id={"Audit Logs"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/audit-logs",
        requiredPermissions: []
      },
      {
        id: "Permission",
        name: <IntlMessages id={"Permission"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/permission",
        requiredPermissions: []
      },
      {
        id: "Sms History",
        name: <IntlMessages id={"Sms History"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/sms-history",
        requiredPermissions: []
      },
      {
        id: "Email History",
        name: <IntlMessages id={"Email History"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/email-history",
        requiredPermissions: []
      }
    ]
  },
  {
    name: <IntlMessages id={"Dashboard"} />,
    icon: <DashboardIcon />,
    type: "collapse",
    requiredPermissions: [],
    sorted: true,
    children: [
      {
        id: "",
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page",
        requiredPermissions: []
      }
    ]
  },
  // {
  //   name: <IntlMessages id={"sidebar.main"} />,
  //   icon: <Timeline />,
  //   type: "collapse",
  //   requiredPermissions: [],
  //   children: [
  //     // {
  //     //   name: <IntlMessages id={'Contact'} />,
  //     //   type: 'item',
  //     //   icon: <Contactless />,
  //     //   link: '/apps/contact',
  //     //   requiredPermissions: [],
  //     // }
  //
  //   ]
  //
  // },
  {
    id: "Register",
    name: <IntlMessages id={"Register"} />,
    icon: <Assessment />,
    type: "collapse",
    requiredPermissions: [],
    sorted: true,
    children: [ /*root*/
      {
        id: "Job Title",
        name: <IntlMessages id={"Job Title"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/job-title",
        requiredPermissions: []
      },
      {
        id: "Skill",
        name: <IntlMessages id={"Skill"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/skill",
        requiredPermissions: []
      },
      {
        id: "Label",
        name: <IntlMessages id={"Label"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/label",
        requiredPermissions: []
      },
      {
        id: "Contact",
        name: <IntlMessages id={"Contact"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/contact",
        requiredPermissions: []
      },
      {
        id: "Device",
        name: <IntlMessages id={"Device"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/device",
        requiredPermissions: []
      },
      {
        id: "Gender",
        name: <IntlMessages id={"Gender"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/gender",
        requiredPermissions: []
      },
      {
        id: "Plan",
        name: <IntlMessages id={"Plan"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/plan",
        requiredPermissions: []
      },

//  DO NOT REMOVE THIS COMMENT NOR CHANGE IT
    ]
  },
  {
    name: <IntlMessages id={"Organization"} />,
    icon: <Apartment />,
    type: "collapse",
    requiredPermissions: [],
    sorted: true,
    children: [
      {
        id: "Organization",
        name: <IntlMessages id={"Organization"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/organization",
        requiredPermissions: []
      },
      {
        id: "Organization Type",
        name: <IntlMessages id={"Organization Type"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/organization-type",
        requiredPermissions: []
      },
      {
        id: "Organization Category",
        name: <IntlMessages id={"Organization Category"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/organization-category",
        requiredPermissions: []
      },
      {
        id: "Industry",
        name: <IntlMessages id={"Industry"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/industry",
        requiredPermissions: []
      }
    ]
  },
  {
    name: <IntlMessages id={"Product"} />,
    icon: <Store />,
    type: "collapse",
    requiredPermissions: [],
    sorted: true,
    children: [
      {
        id: "Product",
        name: <IntlMessages id={"Product"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/product",
        requiredPermissions: []
      },
      {
        id: "Product Type",
        name: <IntlMessages id={"Product Type"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/product-type",
        requiredPermissions: []
      },
      {
        id: "Product Category",
        name: <IntlMessages id={"Product Category"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/product-category",
        requiredPermissions: []
      },
      {
        id: "Brand",
        name: <IntlMessages id={"Brand"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/brand",
        requiredPermissions: []
      },
      {
        id: "Unit Of Measurement",
        name: <IntlMessages id={"Unit Of Measurement"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/unit-measurement",
        requiredPermissions: []
      },
      {
        id: "Sku",
        name: <IntlMessages id={"Sku"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/sku",
        requiredPermissions: []
      },
      {
        id: "Style",
        name: <IntlMessages id={"Style"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/style",
        requiredPermissions: []
      }
    ]
  },
  {
    name: <IntlMessages id={"Time Entry"} />,
    icon: <CalendarToday />,
    type: "collapse",
    requiredPermissions: [],
    sorted: true,
    children: [
      {
        id: "Project",
        name: <IntlMessages id={"Project"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/project",
        requiredPermissions: []
      },
      {
        id: "Client",
        name: <IntlMessages id={"Client"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/client",
        requiredPermissions: []
      },
      {
        id: "Task Service",
        name: <IntlMessages id={"Task Service"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/task-service",
        requiredPermissions: []
      },
      {
        id: 'Timesheet',
        name: <IntlMessages id={'Timesheet'} />,
        type: 'item',
        icon: <ArrowRight />,
        link: '/apps/timesheet',
        requiredPermissions: [],
      },
      {
        id: 'TimesheetEntry',
        name: <IntlMessages id={'Timesheet Entry'} />,
        type: 'item',
        icon: <ArrowRight />,
        link: '/apps/timesheet-entry',
        requiredPermissions: [],
      }

    ]
  },
  {
    name: <IntlMessages id={"User"} />,
    icon: <Person />,
    type: "collapse",
    requiredPermissions: [],
    sorted: true,
    children: [
      {
        id: "User Type",
        name: <IntlMessages id={"User Type"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/user-type",
        requiredPermissions: []
      },
      {
        id: "User Category",
        name: <IntlMessages id={"User Category"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/user-category",
        requiredPermissions: []
      },
      {
        id: "Role",
        name: <IntlMessages id={"Role"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/role",
        requiredPermissions: []
      },
      {
        id: "User",
        name: <IntlMessages id={"User"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/user",
        requiredPermissions: []
      },
      {
        id: "User Status",
        name: <IntlMessages id={"User Status"} />,
        type: "item",
        icon: <ArrowRight />,
        link: "/apps/user-status",
        requiredPermissions: []
      }
    ]
  }
];

for (let i = 0; i < sidebarNavsUnsorted.length; i++) {

  if (sidebarNavsUnsorted[i].sorted) {
    let sorted = sidebarNavsUnsorted[i].children.sort((a, b) => {
      let nameA = a.id.toUpperCase(); // ignore upper and lowercase
      let nameB = b.id.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    sidebarNavsUnsorted[i].children = sorted;
  }

}

export const sidebarNavs = sidebarNavsUnsorted;

export const userMenus = [
  {}
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        id: "pages.samplePage",
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        id: "pages.samplePage",
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];

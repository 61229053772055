import { call, takeLatest } from "redux-saga/effects";
import AlertActions from "./actions/AlertActions";
import { SYSTEM_SETTING_SEARCH, SYSTEM_SETTING_STORE } from "../action-types/SystemSettingTypes";
import SystemSettingActions from "./actions/SystemSettingActions";
import GenericAppActions from "./actions/GenericAppActions";
import { SYSTEM_SETTING_APP_PAGINATE_URL } from "../../routes/Apps/SystemSettingApp";

function* fetchSystemSettingStore(action) {
  try{
    yield call(SystemSettingActions.storeSystemSetting, action.payload);
    yield GenericAppActions.refreshListCurrentGenericApp(SYSTEM_SETTING_APP_PAGINATE_URL) ;
    yield AlertActions.showDialogSuccess('Success','');
  }catch (e) {
    yield AlertActions.showDialogError('Error', e);
  }
}

function* fetchSystemSettingSearch(action) {
  try{
    yield GenericAppActions.searchGenericAppPage(SystemSettingActions.searchSystemSetting, action);
  }catch (e) {
    yield AlertActions.showDialogError('Error Searching', e);
  }
}

export const SystemSettingSaga = [
  takeLatest(SYSTEM_SETTING_STORE, fetchSystemSettingStore),
  takeLatest(SYSTEM_SETTING_SEARCH, fetchSystemSettingSearch),
];
